import { faClose, faEye, faFileLines, faFilter, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import './CGMPurchase.scss'
import { Chip, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { getCGMExpiryUserAction, getCGMExpiryUserActionList } from "../../../utils/preTokenApi";
import MainContext from "../../../context/MainContext";
import TokenContext from "../../../context/TokenContext";
import CGMExpiryAction from "./CGMExpiryAction";
import { ClipboardCopy, formatTimestamp } from "../../../common/commonFunctions";
import { Button } from "react-bootstrap";

const CGMPurchase = () => {

  const { setIsPreLoading, ShowNotification } = useContext(MainContext);

  const {
    initRenderCGMAction,
    setInitRenderCGMAction,
    cgmUserActionList,
    setCgmUserActionList,
    cgmUserActionParam,
    setCgmUserActionParam,
    cgmUserActionListCount, 
    setCgmUserActionListCount
  } = useContext(TokenContext);

  const [initComp, setInitComp] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  /* Based on Param */
  useEffect(() => {
    if (!initRenderCGMAction && !initComp) {
      getCGMExpiryUserActionListData();
    }
  }, [cgmUserActionParam]);

  useEffect(()=>{
    if (initRenderCGMAction) {
      getCGMExpiryUserActionListData();
      setInitRenderCGMAction(false);
    }

    setTimeout(() => {
      setInitComp(false)
    }, 1000);
  },[])

  const cgmPurchaseColumns = [
    {
      name: "User Id",
      selector: (row) => row?.user_profiles[0]?.user_id,
      width: "10%",
    },
    {
      name: "Profile Id",
      selector: (row) => row?.user_profiles[0]?.profile?.id,
      width: "10%",
    },
    {
      name: "Customer Id",
      selector: (row) => row?.user_id,
      width: "10%",
      cell: (row) => (
        <div className="text-start">
          {row?.user_id ? (
            <p className="mb-0 white-space-no-wrap">
              <b>
                {row?.user_id?.slice(0, 8)}{"..."}
                <ClipboardCopy value={row?.user_id} />
              </b>
            </p>
          ): <p className="text-center">-</p>}
        </div>
      ),
    },
    {
      name: <>CGM Expiry</>,
      selector: (row) =>
        row?.user_profiles[0]?.profile?.preference?.cgm_expiry_user_action,
      minWidth: "15%",
      cell: (row) => {
        // let expiryDate =
        //   row?.user_profiles[0]?.profile?.profile_devices?.[0]?.device
        //     ?.expiry_date;

        let latestDeviceObject = row?.user_profiles[0]?.profile?.profile_devices?.reduce((maxObj, current) => {
          return parseInt(current.device_id) > parseInt(maxObj.device_id) ? current : maxObj;
        }, row.user_profiles[0].profile.profile_devices[0]);

        let expiryDate = latestDeviceObject?.device?.expiry_date;          

        const currentDate = new Date();
        const notificationDate = new Date(expiryDate);

        const threeDaysFromNow = new Date();
        threeDaysFromNow.setDate(currentDate.getDate() + 3);

        const isToday =
          notificationDate.getFullYear() === currentDate.getFullYear() &&
          notificationDate.getMonth() === currentDate.getMonth() &&
          notificationDate.getDate() === currentDate.getDate();

        const color = isToday
          ? "#ffeb3b" // Yellow for today
          : notificationDate < currentDate
          ? "red"     // Red for past dates
          : notificationDate <= threeDaysFromNow
          ? "#ffeb3b" // Yellow for dates within the next 3 days
          : "#33b469";


        return (
          <div
            className="text-center cgm-expiry-action-status"
            style={expiryDate ? { color } : {}}
          >
            {expiryDate ? formatTimestamp(expiryDate) : <b>{"--"}</b>}
          </div>
        );
      },
    },
    {
      name: (
        <>
          CGM Expiry
          <br />
          user action
        </>
      ),
      selector: (row) =>
        row?.user_profiles[0]?.profile?.preference?.cgm_expiry_user_action,
      minWidth: "15%",
      cell: (row) => {
        let resultValue =
          row?.user_profiles[0]?.profile?.preference?.cgm_expiry_user_action;
        return (
          <div className="text-center cgm-expiry-action-status">
            {resultValue ? (
              <Chip
                variant="filled"
                style={{ textTransform: "capitalize" }}
                label={resultValue?.replace(/[_\s]/g, " ")}
                className={resultValue}
              />
            ) : (
              <b>{"--"}</b>
            )}
          </div>
        );
      },
    },
    {
      name: (
        <>
          Is Food
          <br />
          Criteria Met
        </>
      ),
      selector: (row) => row?.is_notification_send,
      width: "15%",
      cell: (row) => (
        <div className="text-center food-criteria-status">
          <Chip
            variant="filled"
            style={{ textTransform: "capitalize" }}
            label={
              row?.cc_profile?.is_food_criteria_met === "true" ? "Yes" : "No"
            }
            className={
              row?.cc_profile?.is_food_criteria_met === "true" ? "yes" : "no"
            }
          />
        </div>
      ),
    },
    {
      name: (
        <>
          Is Notification
          <br /> Send
        </>
      ),
      selector: (row) => row?.is_notification_send,
      width: "15%",
      cell: (row) => (
        <div className="text-center notification-send-status">
          <Chip
            variant="filled"
            style={{ textTransform: "capitalize" }}
            label={row?.is_notification_send === "true" ? "Sent" : "Not Yet"}
            className={
              row?.is_notification_send === "true" ? "sent" : "not-yet"
            }
          />
        </div>
      ),
    },
    {
      name: "View",
      width: "10%",
      cell: (row) => (
        <div className="text-center">
          <Button
            variant="primary"
            color="primary"
            size="sm"
            style={{
              textTransform: "none",
              borderRadius: "5px",
            }}
            onClick={() => {
              hanldeViewUserActionData(row?.user_profiles[0]?.user_id);
            }}
          >
            <FontAwesomeIcon icon={faEye} className="mx-1" />
            View
          </Button>
        </div>
      ),
    },
  ];

  const getCGMExpiryUserActionListData = async () => {

    let requestParam = cgmUserActionParam;
    setIsPreLoading(true);
    try {
      const getCGMExpiryUserActionListResponse = await getCGMExpiryUserActionList(
        requestParam
      );
      setCgmUserActionList(getCGMExpiryUserActionListResponse?.data?.user_action)
      setCgmUserActionListCount(getCGMExpiryUserActionListResponse?.data?.total_count)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const hanldeViewUserActionData = async (requestID) => {
    setIsPreLoading(true);
    try {
      const getCGMExpiryUserActionResponse = await getCGMExpiryUserAction(
        requestID
      );
      setSelectedRow(getCGMExpiryUserActionResponse?.data?.user_action)
      setShow(true);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const [show, setShow] = useState(false);
  /* Filter  */
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const [filterUserAction, setFilterUserAction] = useState(['cst_purchase', 'cst_continue'])

  const handleUserActionFilter = (event, value) => {
    const action = value.includes("all") ? [] : value;
   
    setFilterUserAction(action);    
    setCgmUserActionParam((prevCgmUserActionParam) => ({
      ...prevCgmUserActionParam,
      user_action: action?.length ? action.join(',') : null,
    }));
  };

  const handleClearFilter = () => {
    if (isExpanded) {
      setFilterUserAction("");
      setCgmUserActionParam((prevCgmUserActionParam) => ({
        ...prevCgmUserActionParam,
        user_action: "",
      }));
    }
  };

    //Pagination Change
  const handlePageChange = (page) => {    
    const newOffset = (page - 1) * cgmUserActionParam?.limit;
    setCgmUserActionParam((prevCgmActionListParam) => ({
      ...prevCgmActionListParam,
      offset: newOffset,
    }));
  };

  const handleRowsPerPageChange = (newPerPage) => {
    if(!initRenderCGMAction) {
      if(newPerPage !== cgmUserActionParam?.limit){
        setCgmUserActionParam((prevCgmActionListParam) => ({
          ...prevCgmActionListParam,
          limit: newPerPage,
        }));
      }
    }
  };


  return (
    <div className="cgm-purchase-section">
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" /> CGM Purchase
      </section>

      <Grid container spaing={2} className="mb-2">
        <Grid item md={4} xs={6} className="text-end search-section"></Grid>
        <Grid item md={8} xs={6} className="text-end top-action-button-group">
          <div className="ml-auto">
            <Button
              variant="outline-secondary"
              color="secondary"
              className=" mx-1"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {
                getCGMExpiryUserActionListData();
              }}
            >
              <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
              <span>Refresh</span>
            </Button>
            <Button
              variant="outline-primary"
              color="secondary"
              className="mx-1"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={toggleExpandCollapse}
            >
              <FontAwesomeIcon icon={faFilter} className="mx-1" />{" "}
              <span>Filter</span>
            </Button>
          </div>
        </Grid>
      </Grid>

      <div className="cgm-expiry-filter-container">
        <div className={`content ${isExpanded ? "expanded" : "collapsed"}`}>
          <div className="filter-section">
            <section className="filter-item">
              <div className="title">User Action</div>
              <ToggleButtonGroup
                color="secondary"
                // exclusive
                value={filterUserAction}
                onChange={handleUserActionFilter}
                aria-label="text alignment"
                size="small"
                // className="mx-2"
              >
                <ToggleButton
                  value={"all"}
                  aria-label="all"
                  className={
                    filterUserAction.length !== 0 ? "" : "all-select-toogle"
                  }
                >
                  All
                </ToggleButton>
                <ToggleButton value={"cst_purchase"} aria-label="cst_purchase">
                  CST Purchase
                </ToggleButton>
                <ToggleButton value={"cst_continue"} aria-label="cst_continue">
                  CST Continue
                </ToggleButton>
                <ToggleButton value={"purchase"} aria-label="purchase">
                  Purchase
                </ToggleButton>
                <ToggleButton value={"continue"} aria-label="continue">
                  Continue
                </ToggleButton>
              </ToggleButtonGroup>
            </section>
          </div>

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClearFilter}
            className="clear-filter-button"
          >
            <FontAwesomeIcon icon={faClose} className="mx-1" /> Clear Filter
          </Button>
        </div>
      </div>

      <div className={`dt-table`}>
        <DataTable
          columns={cgmPurchaseColumns}
          striped
          data={cgmUserActionList}
          className="dt-user-list dh-table-basic"
          pagination
          paginationPerPage={cgmUserActionParam?.limit}
          paginationServer
          paginationTotalRows={cgmUserActionListCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          searchable
        />
      </div>

      <CGMExpiryAction
        show={show}
        setShow={setShow}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        getCGMExpiryUserActionListData={getCGMExpiryUserActionListData}
        hanldeViewUserActionData={hanldeViewUserActionData}
      />
    </div>
  );
};

export default CGMPurchase;
