// Timeline.js
import React, { useContext, useEffect, useRef } from "react";
import "./TokenTimeline.scss"; // Import the CSS file for styling
import { Card, Offcanvas } from "react-bootstrap";
import { useState } from "react";
import { formatDateFormat, formatTimestamp } from "../../../common/commonFunctions";
import {
  getClaimHumanTokenUsersStatus,
  updateClaimHumanTokenUsersStatus
} from "../../../utils/preTokenApi";
import { Avatar, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCaretRight, faCircleCheck, faRefresh, faRightLong, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import MainContext from "../../../context/MainContext";
import lottie from 'lottie-web';
import animationData from '../../../json/lottie_balloon.json';
// import TokenTimeline from "./TokenTimeline";

const ClaimTokenStatus = ({
  show,
  setShow,
  selectedTokenData,
  setSelectedTokenData,
  selectedBloodTestData, 
  setSelectedBloodTestData,
  selectedUserRow
}) => {
  
  const handleClose = () => {
    setShow(false);
    setSelectedTokenData([]);
  };

  const handleClearState = () =>{
    setChangeStatus("")
    setChangeStatusReason("")
    setChangeStatusTrackingLink("")
    setChangeStatusTrackingId("")
  }

  const { setIsPreLoading, ShowNotification } = useContext(MainContext);

  const [userDeliveryStatusObj, setUserDeliveryStatusObj] = useState([])
  const [deliveryStatus, setDeliveryStatus] = useState([])
  const [userCurrentStatus, setUserCurrentStatus] = useState([])
  const [userTargetStatus, setUserTargetStatus] = useState([])

  useEffect(() => {
    if (selectedTokenData.length > 0) {
      setUserDeliveryStatusObj(transformData(selectedTokenData));
    }
  }, [selectedTokenData]);

  useEffect(() => {
    if (userDeliveryStatusObj.length > 0) {
      const ongoingStatusObj = userDeliveryStatusObj.find(
        (obj) => obj.deliveryStatus?.status === "ongoing" || obj.deliveryStatus?.status === "delayed"
      );

      if (ongoingStatusObj) {        
        setUserCurrentStatus(ongoingStatusObj);
      } else {
        const completedStatusObjs = userDeliveryStatusObj.filter(
          (obj) => obj.deliveryStatus?.status === "completed"
        );
        if (completedStatusObjs.length > 0) {
          completedStatusObjs.sort(
            (a, b) =>
              parseInt(b.deliveryStatus?.id) - parseInt(a.deliveryStatus?.id)
          );
          setUserCurrentStatus(completedStatusObjs[0]);
        } else {
          console.info("not Found");
        }
      }
    }
  }, [userDeliveryStatusObj]);

  useEffect(() => {
    if (userCurrentStatus) {
      let targetStatusID = (parseInt(userCurrentStatus?.id)+1);
      const targetStatusObj = userDeliveryStatusObj.find(
        (obj) => parseInt(obj.id) === targetStatusID
      );
      setUserTargetStatus(targetStatusObj)
    }
  }, [userCurrentStatus]);

  const transformData = (data) => {
    return data.map((item) => {
      const deliveryStatus =
        item.ht_user_delivery_statuses.length > 0
          ? item.ht_user_delivery_statuses[0]
          : null;
      return {
        id: item.id,
        type: item.type,
        name: item.name,
        display_name_incomplete: item.display_name_incomplete,
        display_name_complete: item.display_name_complete,
        deliveryStatus: deliveryStatus,
      };
    });
  };

  const handleRefresh = async () => {
    setIsPreLoading(true);
    try {
      let requestData = {
        user_id: selectedUserRow?.user_id,
      };
      const getClaimHumanTokenUsersStatusResponse =
        await getClaimHumanTokenUsersStatus(requestData);
      setSelectedTokenData(
        getClaimHumanTokenUsersStatusResponse?.data?.ht_delivery_status
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  } 

  /* Variable */
  const [changeStatus, setChangeStatus] = useState("")
  const [changeStatusReason, setChangeStatusReason] = useState("")                    
  const [changeStatusTrackingLink, setChangeStatusTrackingLink] = useState("")                    
  const [changeStatusTrackingId, setChangeStatusTrackingId] = useState("")                    

  const handleUpdateDeliveryStatus = async (targetStatusID) => {
    setIsPreLoading(true);
    try {

      let requestData = {
        user_id: selectedUserRow?.user_id,
        target_delivery_status_id: targetStatusID,
      };
      if (changeStatus === "completed") {
        requestData.status = changeStatus;
      } else if (changeStatus === "delayed") {
        requestData.status = changeStatus;
        requestData.reason = changeStatusReason;
      } else if (targetStatusID === "6") {
        requestData.status = "ongoing";
        requestData.meta = {
          tracking_link: changeStatusTrackingLink,
          tracking_id: changeStatusTrackingId,
        };
      } else {
        requestData.status = "ongoing";
      }
      
      const updateClaimHumanTokenUsersStatusResponse =
        await updateClaimHumanTokenUsersStatus(requestData);
      handleClearState()
      
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
      handleRefresh()
      
      if (targetStatusID === "7") {
        setTimeout(() => {
          setIsCompleted(false);
        }, 500);        
      }
    }
  };

  /* Lottie */
  const [isCompleted, setIsCompleted] = useState(true);

  const LottieAnimation = ({ animationData, isCompleted, setIsCompleted }) => {
    const containerRef = useRef(null);    
  
    useEffect(() => {
      const anim = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: false, // Set to false to play the animation once
        autoplay: true,
        animationData: animationData,
      });
  
      anim.addEventListener('complete', () => {
        setIsCompleted(true);
      });
  
      return () => {
        anim.destroy(); // Cleanup the animation on component unmount
      };
    }, [animationData]);
  
    return (
      <>
        {!isCompleted && <div ref={containerRef} className="lottie-animation"/>}
      </>
    );
  };

  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    const findCurrentObject = () => {
      let currentData = [...userDeliveryStatusObj];
      let currentObject = currentData.find(
        (item) =>
          item.deliveryStatus?.status === "ongoing" ||
          item.deliveryStatus?.status === "delayed"
      );

      if (!currentObject) {        
        currentObject = currentData
          .filter((item) => item.deliveryStatus?.status === "completed")
          .reduce((max, item) => (item.id > max.id ? item : max), {
            id: -Infinity,
          });
      }

      // Mark the found object as current
      if (currentObject) {
        currentData = currentData.map((item) =>
          item.id === currentObject.id ? { ...item, current: true } : item
        );
      }

      const filteredData = currentData.filter(item => {
          if (item.id === "2") {
              return item.deliveryStatus && item.deliveryStatus?.start_time !== null;
          }
          return true;
      });

      setTimelineData(filteredData);
    };

    findCurrentObject();
  }, [userDeliveryStatusObj]);

  return (
    <Offcanvas
      className="human-token-delivery-action-modal"
      show={show}
      onHide={handleClose}
      placement={"end"}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Claim Token Delivery Status</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="claim-Token-delivery-section">
          <Card className="action-view">
            <Grid container spacing={2}>
              <Grid item xs={8} md={8}>
                <div className="info mb-4">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <Avatar
                      alt="Login User"
                      src={require("../../../assets/img/avatar-1.png")}
                      className="user-avatar mx-2"
                    />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">Human Token</p>
                    <p className="value">
                      User ID: {selectedUserRow?.user_id}
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="mx-1"
                        style={{ color: "#4fa3cd" }}
                      />
                    </p>
                  </div>
                </div>
                <div className="info">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <FontAwesomeIcon icon={faTruckFast} />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">Estimated Delivery</p>
                    <p className="value">
                      {formatDateFormat(
                        selectedUserRow?.estimated_token_delivery_at
                      )}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4} md={4}>
                <div className="info justify-content-end align-content-center">
                  <img
                    className="token-book-image"
                    src={require("../../../assets/img/token-book.png")}
                  />
                </div>
              </Grid>
            </Grid>
          </Card>
          <Card className="level-update">
            <Card.Header>
              <p className="m-0">
                Current User Delivery status{" "}
                <IconButton className="icon-button">
                  <FontAwesomeIcon
                    icon={faRefresh}
                    style={{
                      color: "blue",
                    }}
                    onClick={() => {
                      handleRefresh();
                    }}
                  />
                </IconButton>
              </p>
              {userCurrentStatus?.id && (
                <img
                  src={require(`../../../assets/img/icon/status/ds-${userCurrentStatus?.id}.png`)}
                  alt="status-image"
                  className="status-image"
                />
              )}
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="m-0">
                  Current Status:{" "}
                  <b>
                    {userCurrentStatus?.deliveryStatus?.status === "completed"
                      ? userCurrentStatus?.display_name_complete
                      : userCurrentStatus?.display_name_incomplete}
                  </b>
                </p>

                <span
                  className={`status-badge ${userCurrentStatus?.deliveryStatus?.status}`}
                >
                  {userCurrentStatus?.deliveryStatus?.status}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <p>
                  Start Date:{" "}
                  <b>
                    {formatDateFormat(
                      userCurrentStatus?.deliveryStatus?.start_time
                    )}
                  </b>
                </p>
                <p>
                  End Date:{" "}
                  <b>
                    {userCurrentStatus?.deliveryStatus?.end_time
                      ? formatDateFormat(
                          userCurrentStatus?.deliveryStatus?.end_time
                        )
                      : "--/--/----"}
                  </b>
                </p>
              </div>

              {userCurrentStatus?.id === "6" && (
                <div className="d-flex justify-content-between">
                  <p>
                    Tracking Link:{" "}
                    <b>
                      {userCurrentStatus?.deliveryStatus?.meta?.tracking_link}
                    </b>
                  </p>
                  <p>
                    Tracking ID:{" "}
                    <b>
                      {userCurrentStatus?.deliveryStatus?.meta?.tracking_id}
                    </b>
                  </p>
                </div>
              )}

              {userCurrentStatus?.deliveryStatus?.status === "delayed" && (
                <div>
                  <p>
                    Delayed Reason:{" "}
                    <b>{userCurrentStatus?.deliveryStatus?.reason}</b>
                  </p>
                </div>
              )}

              <div className="my-2">
                {userCurrentStatus?.deliveryStatus?.status === "completed" &&
                  userTargetStatus?.id === "6" && (
                    <>
                      <p className="mb-0">
                        <FontAwesomeIcon icon={faCaretRight} className="me-2" />
                        In transit:
                      </p>
                      <TextField
                        fullWidth
                        size="small"
                        className="my-2"
                        name="tracking_link"
                        label="Tracking Link"
                        value={changeStatusTrackingLink}
                        onChange={(event) => {
                          setChangeStatusTrackingLink(event?.target?.value);
                        }}
                      />

                      <TextField
                        fullWidth
                        size="small"
                        className="my-2"
                        name="tracking_id"
                        label="Tracking ID"
                        value={changeStatusTrackingId}
                        onChange={(event) => {
                          setChangeStatusTrackingId(event?.target?.value);
                        }}
                      />
                    </>
                  )}

                {userCurrentStatus?.deliveryStatus?.status !== "completed" && (
                  <FormControl fullWidth size="small" className="my-2">
                    <InputLabel id="demo-simple-select-helper-label">
                      Change status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={changeStatus}
                      label="Change status"
                      onChange={(event) => {
                        setChangeStatus(event?.target?.value);
                      }}
                    >
                      <MenuItem value={"completed"}>Completed</MenuItem>
                      {userCurrentStatus?.deliveryStatus?.status !==
                        "delayed" && (
                        <MenuItem value={"delayed"}>Delayed</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}

                {changeStatus === "delayed" && (
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    size="small"
                    className="my-3"
                    label="Delayed Reason"
                    value={changeStatusReason}
                    onChange={(event) => {
                      setChangeStatusReason(event?.target?.value);
                    }}
                  />
                )}

                <div className="my-3 button-section">
                  {userCurrentStatus?.deliveryStatus?.status === "completed" ? (
                    userTargetStatus && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          disabled={
                            userCurrentStatus?.deliveryStatus?.status ===
                              "completed" &&
                            userTargetStatus?.id === "6" &&
                            (!changeStatusTrackingId ||
                              !changeStatusTrackingLink)
                          }
                          onClick={() => {
                            handleUpdateDeliveryStatus(userTargetStatus?.id);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faRightLong}
                            className="me-2"
                          />
                          Next: {userTargetStatus?.name}
                        </Button>

                        {userCurrentStatus.id === "1" && (
                          <Button
                            variant="contained"
                            color="warning"
                            className="mt-2"
                            onClick={() => {
                              handleUpdateDeliveryStatus(
                                userDeliveryStatusObj[userTargetStatus?.id]?.id
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faRightLong}
                              className="me-2"
                            />
                            Skip:{" "}
                            {userDeliveryStatusObj[userTargetStatus?.id]?.name}
                          </Button>
                        )}
                      </>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      disabled={
                        !changeStatus ||
                        (changeStatus === "delayed" && !changeStatusReason)
                      }
                      onClick={() => {
                        handleUpdateDeliveryStatus(userCurrentStatus?.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faRightLong} className="me-2" />
                      Change: {userCurrentStatus?.name}
                    </Button>
                  )}

                  {userCurrentStatus?.deliveryStatus?.status === "completed" &&
                  !userTargetStatus ? (
                    <Chip
                      label={"Token Book is Delivered"}
                      color="success"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
          <LottieAnimation
            animationData={animationData}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
          />
        </div>
        {selectedBloodTestData && (
          <div className="mt-3">
            <Card className="thyrocare-view">
              <Card.Header>
                <p className="m-0">
                  Thyrocare Blood Test Status
                  <IconButton className="icon-button">
                    <FontAwesomeIcon
                      icon={faRefresh}
                      style={{
                        color: "blue",
                      }}
                      onClick={() => {
                        handleRefresh();
                      }}
                    />
                  </IconButton>
                </p>
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="mb-0">
                    <b>Thyrocare Status</b>
                  </p>
                  <span
                    className={`my-2 status-badge ${selectedBloodTestData?.status?.toLowerCase()}`}
                  >
                    {selectedBloodTestData?.status?.toLowerCase()}
                  </span>
                </div>
                <div className="mt-2">
                  <p>
                    Order ID: <b>{selectedBloodTestData?.order_id}</b>
                  </p>
                  <p>
                    Product ID: <b>{selectedBloodTestData?.product_id}</b>
                  </p>
                  {selectedBloodTestData?.tracking_link && (
                    <p>
                      Tracking Link:{" "}
                      <b>
                        <a>{selectedBloodTestData?.tracking_link}</a>
                      </b>
                    </p>
                  )}

                  {selectedBloodTestData?.whatsapp_msg_sent_at && (
                    <p>
                      Whatsapp Message Sent at:{" "}
                      <b>
                        {formatTimestamp(
                          selectedBloodTestData?.whatsapp_msg_sent_at
                        )}
                      </b>
                    </p>
                  )}

                  {selectedBloodTestData?.appointment_date && (
                    <p>
                      Appointment Date:{" "}
                      <b>
                        {formatDateFormat(
                          selectedBloodTestData?.appointment_date
                        )}
                      </b>
                    </p>
                  )}
                  {selectedBloodTestData?.rescheduled_at && (
                    <p>
                      Rescheduled at:{" "}
                      <b>
                        {formatDateFormat(
                          selectedBloodTestData?.rescheduled_at
                        )}
                      </b>
                    </p>
                  )}
                  {selectedBloodTestData?.cancelled_at && (
                    <p>
                      Cancelled at:{" "}
                      <b>
                        {formatDateFormat(selectedBloodTestData?.cancelled_at)}
                      </b>
                    </p>
                  )}
                 
                  {selectedBloodTestData?.cancellation_reason && (
                    <p>
                      Cancellation Reason:{" "}
                      <b>{selectedBloodTestData?.cancellation_reason}</b>
                    </p>
                  )}
                 
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
        {/* <TokenTimeline timelineData = {timelineData}/> */}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ClaimTokenStatus;
