import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const PositiveBarChart = ({positiveBarChartData}) => {
  const chartRef = useRef(null);
 
  useEffect(() => {
 
    const chartInstance = echarts.init(chartRef.current, null, {
      renderer: 'svg',
      devicePixelRatio: window.devicePixelRatio
    });

    var emphasisStyle = {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0,0,0,0.3)'
      }
    };

    let seriesData = positiveBarChartData;

    var option = {
      grid: {
        top: "5%",
        left: "2%",
        right: "2%",
        bottom: "5%",
        containLabel: true,
      },
      toolbox: {
        show: false
      },
      tooltip: {},
      xAxis: {
        data: seriesData,
        axisLabel: {
          fontSize: 10,
          fontWeight: 600,
          color: "#000000",
          formatter: function (value) {
            var hours = Math.floor(Math.abs(value) / 60);
            var minutes = Math.abs(value) % 60;
            var formattedValue = "";

            if (value < 0) {
              formattedValue += "-";
            }

            if (hours > 0) {
              formattedValue += hours + "h";
            }

            if (minutes > 0) {
              formattedValue += (hours > 0 ? " " : "") + minutes + "m";
            }

            return (formattedValue || "0m") + "\n\n{Text|Week}";
          },
          rich: {
            Text: {
              fontSize: 8,
              fontWeight: 500,
              color: '#868698',
            }
          }
        },
        axisLine: { show: false },
        splitLine: { show: false },
        splitArea: { show: false },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        interval: 300,
        onZero: true,
        onZeroAxisIndex: 0,
        
        min: function (value) {
          return Math.floor(value.min / 60) * 300;
        },
        max: function (value) {
          return Math.ceil(value.max / 60) * 60;
        },
        axisLabel: {
          fontWeight: 500,
          fontSize: 8,
          formatter: function (value) {
            var hours = Math.floor(Math.abs(value) / 60);
            var minutes = Math.abs(value) % 60;
            var formattedValue = "";

            if (value < 0) {
              formattedValue += "-";
            }

            if (hours > 0) {
              formattedValue += hours + "h";
            }

            if (minutes > 0) {
              formattedValue += (hours > 0 ? " " : "") + minutes + "m";
            }

            return (formattedValue || "0m");
          },
        },

      },
      
      legend:{
        show: false
      },
      series: [
        {
          name: "bar",
          type: "bar",
          stack: 'one',
          barWidth: 35,
          emphasis: emphasisStyle,
          data: seriesData,
          itemStyle: {
            color: function (params) {
              if (params.value < 0) {
                return "#90d7e4";
              } else if (params.value <= 180) {
                return "#86df9c";
              } else if (params.value >= 480) {
                return "#ff564c";              
              } else {
                return "#ffca80";
              }
            },
          },
        },
      ],
    };
    

    chartInstance.setOption(option);

    return () => {
      chartInstance.dispose();
    };
  }, [positiveBarChartData]);

  return (
    <>
      <h4 className="graph-header">Positive Bar - Chart</h4>
      <div className="sleep-positive-chart" ref={chartRef} style={{ width: "740px", height: "150px" }}></div>
    </>
  );
};

export default PositiveBarChart;
