export const foodsToLimitData = [
  {
    "Milk & Milk Products": [
      { name: "Paneer", count: 3, advice: "It would be best to avoid it." },
      { name: "Buttermilk", count: 0, advice: "" },
      { name: "Kefir", count: 0, advice: "" },
      {
        name: "Milk",
        count: 11,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Legumes: [
      {
        name: "Kidney Beans",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Pigeon Pea", count: 0, advice: "" },
    ],
    Sugars: [
      {
        name: "Table Sugar",
        count: 3,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fructose Corn Syrup", count: 0, advice: "" },
      { name: "Glucose Syrup", count: 0, advice: "" },
      { name: "Brown Sugar", count: 0, advice: "" },
    ],
    Beverages: [
      { name: "Kombucha", count: 0, advice: "" },
      { name: "Energy Drinks", count: 0, advice: "" },
      { name: "Packaged Fruit Drinks", count: 0, advice: "" },
      { name: "Carbonated Beverages", count: 0, advice: "" },
      { name: "Sugarcane Juice", count: 0, advice: "" },
    ],
    Poultry: [
      { name: "Chicken", count: 4, advice: "You're doing great! Keep it up." },
      { name: "Goose", count: 0, advice: "" },
      { name: "Guinea", count: 0, advice: "" },
      { name: "Turkey", count: 0, advice: "" },
    ],
    "Cereals & Millets": [
      {
        name: "Wheat",
        count: 16,
        advice: "This is above the limit, so please consider decreasing it.",
      },
      { name: "Crude Wheat Bran", count: 0, advice: "" },
      { name: "Wheat Germ Crude", count: 0, advice: "" },
      { name: "Whole Wheat Bread", count: 0, advice: "" },
      { name: "Barley", count: 0, advice: "" },
    ],
    Miscellaneous: [
      {
        name: "Baked Foods",
        count: 4,
        advice: "It would be best to avoid it.",
      },
      { name: "Chocolate", count: 2, advice: "It would be best to avoid it." },
      {
        name: "Sauces And Seasonings With High Sodium Content",
        count: 1,
        advice: "It would be best to avoid it.",
      },
      { name: "Ice-Cream", count: 0, advice: "" },
      { name: "Cookies", count: 0, advice: "" },
      { name: "Crackers", count: 0, advice: "" },
    ],
    "Sweets & Desserts": [
      {
        name: "Processed And Packaged Foods",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Garlic Bread", count: 0, advice: "" },
      { name: "Candies", count: 0, advice: "" },
    ],
    "Edible Oil & Fat": [
      {
        name: "Fried Fast Food",
        count: 1,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fat Dressings", count: 0, advice: "" },
      { name: "Butter", count: 0, advice: "" },
      { name: "Margarine", count: 0, advice: "" },
      { name: "Milk Cream", count: 0, advice: "" },
      { name: "Hydrogenated Oils", count: 0, advice: "" },
      { name: "Coconut Oil", count: 0, advice: "" },
      { name: "Palm Oil", count: 0, advice: "" },
      { name: "Vegetable Shortening", count: 0, advice: "" },
      { name: "Full Fat Dairy Products", count: 0, advice: "" },
    ],
    "Egg & Egg products": [
      {
        name: "Poultry Egg Cooked",
        count: 8,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Fruits: [{ name: "Cherries", count: 0, advice: "" }],
    "Animal Meat": [{ name: "Liver", count: 0, advice: "" }],
    "Nuts & Oilseeds": [{ name: "Peanuts", count: 0, advice: "" }],
    Miscellaneous: [
      { name: "Fermented Foods", count: 0, advice: "" },
      { name: "Sauerkraut", count: 0, advice: "" },
    ],
  },
  {
    "Milk & Milk Products": [
      { name: "Paneer", count: 3, advice: "It would be best to avoid it." },
      { name: "Buttermilk", count: 0, advice: "" },
      { name: "Kefir", count: 0, advice: "" },
    ],
    Legumes: [
      {
        name: "Kidney Beans",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Pigeon Pea", count: 0, advice: "" },
    ],
    Sugars: [
      {
        name: "Table Sugar",
        count: 3,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fructose Corn Syrup", count: 0, advice: "" },
      { name: "Glucose Syrup", count: 0, advice: "" },
      { name: "Brown Sugar", count: 0, advice: "" },
    ],
    Beverages: [
      { name: "Kombucha", count: 0, advice: "" },
      { name: "Energy Drinks", count: 0, advice: "" },
    ],
    Poultry: [
      { name: "Chicken", count: 4, advice: "You're doing great! Keep it up." },
      { name: "Goose", count: 0, advice: "" },
      { name: "Guinea", count: 0, advice: "" },
      { name: "Turkey", count: 0, advice: "" },
    ],
    "Cereals & Millets": [
      {
        name: "Wheat",
        count: 16,
        advice: "This is above the limit, so please consider decreasing it.",
      },
      { name: "Crude Wheat Bran", count: 0, advice: "" },
      { name: "Wheat Germ Crude", count: 0, advice: "" },
      { name: "Whole Wheat Bread", count: 0, advice: "" },
      { name: "Barley", count: 0, advice: "" },
    ],
    Miscellaneous: [
      {
        name: "Baked Foods",
        count: 4,
        advice: "It would be best to avoid it.",
      },
      { name: "Chocolate", count: 2, advice: "It would be best to avoid it." },
      {
        name: "Sauces And Seasonings With High Sodium Content",
        count: 1,
        advice: "It would be best to avoid it.",
      },
    ],
    "Sweets & Desserts": [
      {
        name: "Processed And Packaged Foods",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Garlic Bread", count: 0, advice: "" },
      { name: "Candies", count: 0, advice: "" },
    ],
    "Edible Oil & Fat": [
      {
        name: "Fried Fast Food",
        count: 1,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fat Dressings", count: 0, advice: "" },
      { name: "Butter", count: 0, advice: "" },
      { name: "Margarine", count: 0, advice: "" },
      { name: "Milk Cream", count: 0, advice: "" },
    ],
    "Egg & Egg products": [
      {
        name: "Poultry Egg Cooked",
        count: 8,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Fruits: [{ name: "Cherries", count: 0, advice: "" }],
    "Animal Meat": [{ name: "Liver", count: 0, advice: "" }],
    "Nuts & Oilseeds": [{ name: "Peanuts", count: 0, advice: "" }],
    Miscellaneous: [
      { name: "Fermented Foods", count: 0, advice: "" },
      { name: "Sauerkraut", count: 0, advice: "" },
    ],
  },
  {
    "Milk & Milk Products": [
      { name: "Paneer", count: 3, advice: "It would be best to avoid it." },
      { name: "Buttermilk", count: 0, advice: "" },
      { name: "Kefir", count: 0, advice: "" },
      {
        name: "Milk",
        count: 11,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Legumes: [
      {
        name: "Kidney Beans",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Pigeon Pea", count: 0, advice: "" },
    ],
    Sugars: [
      {
        name: "Table Sugar",
        count: 3,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fructose Corn Syrup", count: 0, advice: "" },
      { name: "Glucose Syrup", count: 0, advice: "" },
      { name: "Brown Sugar", count: 0, advice: "" },
    ],
    Beverages: [
      { name: "Kombucha", count: 0, advice: "" },
      { name: "Energy Drinks", count: 0, advice: "" },
      { name: "Packaged Fruit Drinks", count: 0, advice: "" },
      { name: "Carbonated Beverages", count: 0, advice: "" },
      { name: "Sugarcane Juice", count: 0, advice: "" },
    ],
    Poultry: [
      { name: "Chicken", count: 4, advice: "You're doing great! Keep it up." },
      { name: "Goose", count: 0, advice: "" },
      { name: "Guinea", count: 0, advice: "" },
      { name: "Turkey", count: 0, advice: "" },
    ],
    "Cereals & Millets": [
      {
        name: "Wheat",
        count: 16,
        advice: "This is above the limit, so please consider decreasing it.",
      },
      { name: "Crude Wheat Bran", count: 0, advice: "" },
      { name: "Wheat Germ Crude", count: 0, advice: "" },
      { name: "Whole Wheat Bread", count: 0, advice: "" },
      { name: "Barley", count: 0, advice: "" },
    ],
    Miscellaneous: [
     
      { name: "Ice-Cream", count: 0, advice: "" },
      { name: "Cookies", count: 0, advice: "" },
      { name: "Crackers", count: 0, advice: "" },
    ],
    "Sweets & Desserts": [
      {
        name: "Processed And Packaged Foods",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Garlic Bread", count: 0, advice: "" },
    ],
    "Edible Oil & Fat": [
      {
        name: "Fried Fast Food",
        count: 1,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fat Dressings", count: 0, advice: "" },
      { name: "Butter", count: 0, advice: "" },
      { name: "Margarine", count: 0, advice: "" },
      { name: "Milk Cream", count: 0, advice: "" },
      { name: "Hydrogenated Oils", count: 0, advice: "" },
      { name: "Coconut Oil", count: 0, advice: "" },
      { name: "Palm Oil", count: 0, advice: "" },
      { name: "Vegetable Shortening", count: 0, advice: "" },
      { name: "Full Fat Dairy Products", count: 0, advice: "" },
    ],
    "Egg & Egg products": [
      {
        name: "Poultry Egg Cooked",
        count: 8,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Fruits: [{ name: "Cherries", count: 0, advice: "" }],
    "Animal Meat": [{ name: "Liver", count: 0, advice: "" }],
    "Nuts & Oilseeds": [{ name: "Peanuts", count: 0, advice: "" }],
    Miscellaneous: [
      { name: "Fermented Foods", count: 0, advice: "" },
      { name: "Sauerkraut", count: 0, advice: "" },
    ],
  },
];
