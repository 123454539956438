import { createContext } from "react";
/* Font Awesome */
import {
  faBell,
  faBowlFood,
  faBowlRice,
  faComments,
  faCubesStacked,
  faUser,
  faHomeAlt,
  faFingerprint,
  faUserShield,
  faUserTag,
  faUsers,
  faWheatAlt,
  faDiagramProject,
  faWheatAwnCircleExclamation,
  faWheatAwn,
  faStore,
  faCircleDot,
  faEnvelope,
  faBookMedical,
  faCode,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
//Components
import Home from "../components/home/Home";
import UserVerification from "../components/human-token/token-verification/UserVerification";
// import KitListView from "../components/human-token/tracking/KitListView";
// import OrderListView from "../components/human-token/tracking/OrderListView";
import WhatsappQuery from "../components/whatsapp-query/WhatsappQuery";
import Food from "../components/nutrition/food/Food";
import Meal from "../components/nutrition/meal/Meal";
import MealTracker from "../components/nutrition/meal-tracker/MealTracker";
import Profile from "../components/profile/Profile";
import RolePermissions from "../components/roles/RolePermissions";
import PremiumUsers from "../components/human-token/premium-users/PremiumUsers";
import Notifications from "../components/notifications/Notifications";
import QuestionnaireFlow from "../components/question-flow/QuestionnaireFlow";
import UnclassifiedFood from "../components/nutrition/meal-tracker/UnclassifiedFood";
import CGMPurchase from "../components/human-token/purchase-cgm/CGMPurchase";
import ManualNotificationsView from "../components/notifications/ManualNotificationsView";
import EmailViewer from "../components/email-template/EmailViewer";
import HumanTokenList from "../components/human-token/claim-token/HumanTokenList";
// import ManualOperations from "../components/manual-operations/ManualOperations";
// import PDFGenerate from "../components/pdf-generate/PDFGenerate";
// import EmailEjsViewer from "../components/email-template/EmailEjsViewer";
import BookComponent from "../components/pdf-generate/BookComponent";

const DataContext = createContext({});

export const DataProvider = ({ children }) => { 

  const RoutesList = [
    {
      label: "Home",
      key: "",
      heading_name: "Dashboard",
      link: "/",
      icon: faHomeAlt,
      element: Home,
      accessRoles: "all",
    },
    {
      label: "Token User",
      heading_name: "Human Token",
      key: "token-user",
      icon: faUserTag,
      subMenu: [
        {
          label: "User Verification",
          key: "user-verify",
          link: "/user-verify",
          icon: faUsers,
          element: UserVerification,
          accessRoles: [4],
        },
        {
          label: "Paid Users",
          key: "paid-users",
          link: "/paid-users",
          icon: faUserShield,
          element: PremiumUsers,
          accessRoles: [4],
        },
        {
          label: "CGM Purchase",
          key: "cgm-purchase",
          link: "/cgm-purchase",
          icon: faStore,
          element: CGMPurchase,
          accessRoles: [4],
        },
        {
          label: "Human Token Users",
          key: "human-token",
          link: "/human-token",
          icon: faBookMedical,          
          element: HumanTokenList,
          accessRoles: [4],
        },
      ],
    },
    // {
    //   label: "Tracking",
    //   key: "tracking",
    //   icon: faCompassDrafting,
    //   subMenu: [
    //     {
    //       label: "Kit",
    //       key: "kit",
    //       link: "/kit",
    //       icon: faKitMedical,
    //       element: KitListView,
    //       accessRoles: "all",
    //     },
    //     {
    //       label: "Pickup",
    //       key: "order",
    //       link: "/order",
    //       icon: faTruckDroplet,
    //       element: OrderListView,
    //       accessRoles: "all",
    //     },
    //   ],
    // },
    {
      label: "Notifications",
      heading_name: "Notifications",
      key: "manual-notifications",
      icon: faBell,
      subMenu: [
        {
          label: "Manual Notifications",
          key: "manual-notifications",
          link: "/manual-notifications",
          icon: faCircleDot,
          element: ManualNotificationsView,
          accessRoles: [4],
        },
        {
          label: "App Notifications",
          key: "notifications",
          link: "/notifications",
          icon: faBell,
          element: Notifications,
          accessRoles: [4],
        },
      ]
    },
    {
      label: "Email Template",
      key: "email-viewer",
      link: "/email-viewer",
      icon: faEnvelope,
      element: EmailViewer,
      accessRoles: "all",
      isNoSubNav: true,
    },
    {
      label: "Whatsapp Queries",
      key: "queries",
      link: "/queries",
      icon: faComments,
      element: WhatsappQuery,
      accessRoles: [4],
    },
    {
      label: "Question Visual",
      key: "questionnaire",
      link: "/questionnaire",
      icon: faDiagramProject,
      element: QuestionnaireFlow,
      accessRoles: [5],
    },
    {
      label: "Nutrition",
      heading_name: "Nutrition",
      key: "nutrition",
      icon: faBowlFood,
      subMenu: [
        {
          label: "Food",
          key: "food",
          link: "/food",
          icon: faWheatAlt,
          element: Food,
          accessRoles: [5],
        },
        {
          label: "Meal",
          key: "meal",
          link: "/meal",
          icon: faBowlRice,
          element: Meal,
          accessRoles: [5],
        },
      ],
    },
    {
      label: "Food Intake Tracker",
      // heading_name: "Food Intake Tracker",
      key: "food-intake",
      icon: faCubesStacked,
      subMenu: [
        {
          label: "Unclassified food",
          key: "unclassified-food",
          link: "/unclassified-food",
          icon: faWheatAwnCircleExclamation,
          element: UnclassifiedFood,
          accessRoles: [5],
        },
        {
          label: "Data verified food",
          key: "verified-food",
          link: "/verified-food",
          icon: faWheatAwn,
          element: MealTracker,
          accessRoles: [5],
        },
        // {
        //   label: "Unidentified food",
        //   key: "unidentified-food",
        //   link: "/unidentified-food",
        //   icon: faEyeSlash,
        //   element: UnidentifiedFood,
        //   accessRoles: [5],
        // },
      ],
    },
    // {
    //   label: "Food Intake Tracker",
    //   key: "food-intake",
    //   link: "/food-intake",
    //   icon: faCubesStacked,
    //   element: MealTracker,
    //   accessRoles: [5],
    // },
    // {
    //   label: "Book Automation",
    //   key: "book-automation",
    //   heading_name: "book-automation",
    //   link: "/book-automation",
    //   icon: faFilePdf,
    //   element: BookComponent,
    //   accessRoles: "all",
    // },
    {
      label: "Profile",
      key: "profile",
      heading_name: "Profile",
      link: "/profile",
      icon: faUser,
      element: Profile,
      accessRoles: "all",
      isNoSubNav: true,
    },
    // {
    //   label: "Roles & Permissions",
    //   key: "role-permisions",
    //   link: "/role-permisions",
    //   icon: faFingerprint,
    //   element: RolePermissions,
    //   accessRoles: "all",
    //   isNoSubNav: true,
    // },
  ];

  return (
    <DataContext.Provider value={{ RoutesList }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
