import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import MainContext from "../../../context/MainContext";
import { updateGBClientUsername } from "../../../utils/TrackingAPI";

const GeneBoxRegister = ({
  show,
  setShow,
  userVerificationId,
  getPremiumUserListData,
  setSelectedHTUserID,
  selectedGeneboxClientName,
}) => {
  const handleClose = () => {
    setSelectedHTUserID("");
    setGeneboxUserName("");
    setShow(false);
  };

  const { ShowNotification, setIsPreLoading } = useContext(MainContext);

  const [geneboxUserName, setGeneboxUserName] = useState("");

  useEffect(() => {
    if (show) {
      setGeneboxUserName(selectedGeneboxClientName);
    } else {
      setGeneboxUserName("");
    }
  }, [show]);

  const handleRegisterGBUsername = async () => {
    setIsPreLoading(true);

    try {
      let requestData = {
        ht_user_verification_id: userVerificationId.toString(),
        genebox_client_username: geneboxUserName,
      };
      const registerGBUserNameResponse = await updateGBClientUsername(
        requestData
      );
      getPremiumUserListData();
      handleClose();
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  return (
    <div>
      <Offcanvas
        className="genebox-client-username-view"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Register Genebox</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <TextField
              className="my-0"
              label="Genebox User Name"
              size={"small"}
              fullWidth
              placeholder="Genebox User Name"
              variant="outlined"
              value={geneboxUserName}
              onChange={(e) => {
                setGeneboxUserName(e.target.value);
              }}
            />
          </div>
          <div className="my-2 text-end">
            <Button variant="secondary" onClick={handleClose} className="mx-2">
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleRegisterGBUsername();
              }}
            >
              Register
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default GeneBoxRegister;
