import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { Calendar, DateRangePicker } from 'react-date-range';
import { faClose, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import NutritionContext from "../../../context/NutritionContext";
import dayjs from "dayjs";
import "./MealTracker.scss";

const FoodIntakeFilterUC = ({
  show,
  setShow,
  setCurrentPage,
  filterMealTypes,
  setFilterMealTypes,
  setResetPaginationToggle,
  resetPaginationToggle,
  filterUserID,
  setFilterUserID,
  filterIsUnidentified, 
  setFilterIsUnidentified
}) => {
  const handleClose = () => {
    setShow(false);
  };

  const { setFoodIntakeUCParam } = useContext(NutritionContext);

  const [isClearFilter, setIsClearFilter] = useState(false);

  const [filterParam, setFilterParam] = useState({
    meal_type: "",
    is_validated: false,
    is_unidentified: "",
    start_time: "",
    end_time: "",
    user_id:"",
    // start_time:new Date().toISOString(),
    // end_time:new Date().toISOString(),
  });

  const handleMealTypeChange = (event, value) => {
    const newMealType = value.includes("all") ? [] : value;

    setFilterMealTypes(newMealType);

    setFilterParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      meal_type: newMealType?.length ? newMealType.join(",") : null,
    }));

    // const { name, checked } = event.target;
    // if (checked) {
    //   setFilterParam(prevState => ({
    //     ...prevState,
    //     meal_type: [...prevState.meal_type, name]
    //   }));
    // } else {
    //   setFilterParam(prevState => ({
    //     ...prevState,
    //     meal_type: prevState.meal_type.filter(item => item !== name)
    //   }));
    // }
  };

  const handleValidationChange = (event, value) => {
    setFilterParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      is_validated: value !== "" ? value : "",
    }));
  };

  const handleIsIdentitifedFoodChange = (event, value) => {
    setFilterParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      is_unidentified: value !== "" ? value : "",
    }));
    setFilterIsUnidentified(value !== "" ? value : "")
  };

  const handleFilterSubmit = () => {
    setFoodIntakeUCParam((prevState) => ({
      ...prevState,
      ...filterParam,
      offset: 0,
    }));
    setCurrentPage(1);
    setResetPaginationToggle(!resetPaginationToggle);
    handleClose();
  };

  useEffect(() => {
    if (isClearFilter) {
      setFoodIntakeUCParam((prevState) => ({
        ...prevState,
        meal_type: "",
        is_validated: false,
        is_unidentified: "",
        start_time: "",
        end_time: "",
        offset: 0,
        user_id: "",
      }));
      setIsClearFilter(false);
      setFilterMealTypes([]);
      setFilterUserID("")
      setFilterIsUnidentified(null)
      setCurrentPage(1);
      setResetPaginationToggle(!resetPaginationToggle);
      handleClose();
    }
  }, [isClearFilter]);

  return (
    <div>
      <Offcanvas
        className="filter-modal"
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FontAwesomeIcon icon={faFilter} /> Filter Intake
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <Chip
              label="Meal Type"
              variant="outlined"
              className="header-chip mb-2"
              color="secondary"
            />

            <ToggleButtonGroup
              color="secondary"
              // exclusive
              value={filterMealTypes}
              onChange={handleMealTypeChange}
              aria-label="text alignment"
              size="small"
              // className="mx-2"
            >
              <ToggleButton
                value={"all"}
                aria-label="all"
                className={
                  filterMealTypes.length !== 0 ? "" : "all-select-toogle"
                }
              >
                All
              </ToggleButton>
              <ToggleButton value={"breakfast"} aria-label="black">
                Breakfast
              </ToggleButton>
              <ToggleButton value={"lunch"} aria-label="lunch">
                Lunch
              </ToggleButton>
              <ToggleButton value={"dinner"} aria-label="dinner">
                Dinner
              </ToggleButton>
              <ToggleButton value={"snack"} aria-label="snack">
                Snack
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {/* <div>
            <Chip
              label="Validated"
              variant="outlined"
              className="header-chip my-2"
              color="warning"
            />
            <ToggleButtonGroup
              color="secondary"
              exclusive
              value={filterParam.is_validated}
              onChange={handleValidationChange}
              aria-label="eligible filter"
              size="small"
              // className="mx-2"
            >
              <ToggleButton value={"" || null} aria-label="All">
                All
              </ToggleButton>
              <ToggleButton value={true} aria-label="completed">
                Completed
              </ToggleButton>
              <ToggleButton value={false} aria-label="pending">
                Pending
              </ToggleButton>
            </ToggleButtonGroup>
          </div> */}
          <div className="my-3">
            <Chip
              label="Unidentified Food"
              variant="outlined"
              className="header-chip my-2"
              color="warning"
            />
            <ToggleButtonGroup
              color="secondary"
              exclusive
              value={filterIsUnidentified}
              onChange={handleIsIdentitifedFoodChange}
              aria-label="Unidentified Food filter"
              size="small"
              // className="mx-2"
            >
              <ToggleButton value={"" || null} aria-label="All">
                All
              </ToggleButton>
              <ToggleButton value={true} aria-label="True">
                True
              </ToggleButton>
              <ToggleButton value={false} aria-label="False">
                False
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="my-3">
            <Chip
              label="Filter Date Range"
              variant="outlined"
              className="header-chip my-2"
              color="primary"
            />

            <TextField
              // id="remark-cgm-user-action"
              className="my-2"
              label="Search User ID"
              fullWidth
              size="small"
              type="number"
              value={filterUserID}
              onChange={(e) => {
                setFilterUserID(e.target?.value);

                setFilterParam((prevVerificationListParam) => ({
                  ...prevVerificationListParam,
                  user_id: e.target?.value,
                }));
              }}
            />
          </div>
          <div className="my-3">
            <Chip
              label="Filter Date Range"
              variant="outlined"
              className="header-chip my-2"
              color="primary"
            />
            <Row className="date-range-picker my-1">
              <Col md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      defaultValue={dayjs(new Date())}
                      label="Start Date"
                      format="DD-MM-YYYY"
                      value={dayjs(new Date(filterParam?.start_time)) || ""}
                      className="mb-2"
                      onChange={(newDate) => {
                        const startOfDay = dayjs(newDate).startOf("day");
                        const endOfDay = dayjs(newDate).endOf("day");

                        const formattedStartDate = startOfDay.format(
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        );
                        const formattedEndDate = endOfDay.format(
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        );

                        const fromDate = dayjs(formattedStartDate);
                        const toDate = dayjs(filterParam?.end_time);

                        if (fromDate.isBefore(toDate)) {
                          setFilterParam((prevState) => ({
                            ...prevState,
                            start_time: formattedStartDate,
                            end_time: filterParam?.end_time
                              ? filterParam?.end_time
                              : formattedEndDate,
                          }));
                        } else {
                          setFilterParam((prevState) => ({
                            ...prevState,
                            start_time: formattedStartDate,
                            end_time: formattedEndDate,
                          }));
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Col>
              <Col md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="To Date"
                      format="DD-MM-YYYY"
                      value={dayjs(new Date(filterParam?.end_time)) || ""}
                      className="mb-2"
                      onChange={(newDate) => {
                        const endOfDay = dayjs(newDate).endOf("day");
                        const startOfDay = dayjs(newDate).startOf("day");
                        const formattedStartDate = startOfDay.format(
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        );
                        const formattedEndDate = endOfDay.format(
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        );

                        const fromDate = dayjs(filterParam?.start_time);
                        const toDate = dayjs(formattedEndDate);

                        if (fromDate.isBefore(toDate)) {
                          setFilterParam((prevState) => ({
                            ...prevState,
                            end_time: formattedEndDate,
                          }));
                        } else {
                          setFilterParam((prevState) => ({
                            ...prevState,
                            start_time: formattedStartDate,
                            end_time: formattedEndDate,
                          }));
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Col>
            </Row>
          </div>
        </Offcanvas.Body>
        <Offcanvas.Header className="footer">
          <div>
            <Button
              variant="outline-danger"
              size="sm"
              className="my-2"
              onClick={() => {
                setIsClearFilter(true);
                setFilterParam({
                  meal_type: "",
                  is_validated: false,
                  is_unidentified: "",
                  start_time: "",
                  end_time: "",
                  user_id: "",
                });
                setFilterUserID("");
                setFilterIsUnidentified(null)
              }}
            >
              <FontAwesomeIcon icon={faClose} /> Clear Filter
            </Button>
          </div>
          <div>
            <Button
              variant="outline-primary"
              size="sm"
              className="my-2"
              onClick={() => {
                handleFilterSubmit();
              }}
            >
              <FontAwesomeIcon icon={faFilter} /> Filter List
            </Button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </div>
  );
};

export default FoodIntakeFilterUC;
