import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Dropdown, Form, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBowlFood, faCaretDown, faCaretUp, faClose, faEye, faFileLines, faPlusCircle, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import preloaderGif from "../../../assets/img/preloader-1.gif";
import { getCCFoodList, getCCFoodNutirentsData, suggestFoodSearch } from '../../../utils/nutritionApi';
import DataTable from "react-data-table-component";
import FoodView from './FoodView';
import CreateFood from './CreateFood';
import { Box, Divider, Grid, IconButton, InputBase, Paper, TextField } from '@mui/material';
import "./NutritionHome.scss"
import MainContext from '../../../context/MainContext';
import NutritionContext from '../../../context/NutritionContext';

const Food = () => {

  const {
    foodListData,
    setFoodListData,
    setSelectedFoodDetails,
    setSelectedFoodServing,
    setSelectedFoodMicros,
    totalFoodRows, setTotalFoodRows,
    initialRenderFood, setInitialRenderFood,
    foodListParam, setFoodListParam,
    /*  */
  } = useContext(NutritionContext);

  const [initComp, setInitComp] = useState(true);

  const { foodProfileId, accessToken, isPreLoading, setIsPreLoading } = useContext(MainContext);

  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloaderGif} alt="PreLoading..." />
      </div>
    );
  };
  
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsShown, setSuggestionsShown] = useState(false);

  const [selectedInput, setSelectedInput] = useState("");
  const [isSearchParam, setIsSearchParam] = useState(false);

  /* API */

  const divRef = useRef()

  /* Food */
  const [foodSearch, setFoodSearch] = useState("")

  //Fatsecret Api search
  const handleInputChange = async (e) => {
    setIsPreLoading(true);
    const inputValue = e.target.value;
    setSelectedInput(inputValue);
    
    const requestData = {
      search_expression: inputValue,
      food_profile_id: foodProfileId, //null,
      is_admin:true
    };

    try {
      if (inputValue?.length > 0) {
        const CCFoodSearchResponse = await suggestFoodSearch(requestData);
        let responseData =
          CCFoodSearchResponse?.data?.search_result?.food?.foods;
        setSuggestions(responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsPreLoading(false);
    } finally {
      setIsPreLoading(false);
    }

    if (!suggestionsShown && inputValue.trim() !== "") {
      setSuggestionsShown(true);
    }
  };

  //Fatsecret get api
  const handleSuggestionClick = async (suggestion) => {

    setIsPreLoading(true)
    
    setSelectedInput(suggestion?.food_name);
    setSuggestionsShown(false);

    try {
      const CCFoodNutrientsResponse = await getCCFoodNutirentsData(suggestion?.food_id, foodProfileId);

      let responseData = CCFoodNutrientsResponse?.data;

      setSelectedFoodDetails(responseData?.cc_food);
      setSelectedFoodServing(
        responseData?.cc_food?.cc_servings?.find((item) => item.is_default)
      );
      setSelectedFoodMicros(responseData?.micros);

      setShowNutrientView(true); //Open Nutients Modal
      setIsPreLoading(false);

      fetchFoodListData(); //Get Food List Updated
      setSelectedInput("");
      
    } catch (error) {
      console.error('Error fetching data:', error.response ? error.response.data : error.message);
      setIsPreLoading(false);
    } finally {
      setIsPreLoading(false);
    }

  };

  //Outside Canvas Click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setSuggestionsShown(false)
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {

      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  /* List */
  const [showNutrientView, setShowNutrientView] = useState(false);
  const [showAddFoodView, setShowAddFoodView] = useState(false);
  
  const [isManualFoodEdit, setIsManualFoodEdit] = useState(false)

  //Food List Columns
  const foodListColumns = [
    {
      // name: "ID",
      name: (
        <div
          className="tHead justify-content-center"
          onClick={() => handleHeaderRowClick("id")}
        >
          {"ID"}
          <span className="mx-1 id">
            <FontAwesomeIcon icon={faCaretUp} className="ASC" />
            <FontAwesomeIcon icon={faCaretDown} className="DESC" />
          </span>
        </div>
      ),
      selector: (row) => row.id,
      key: "id",
      width: "10%",
      // sortable: true,
    },
    {
      // name: "Name",
      name: (
        <div className="tHead" onClick={() => handleHeaderRowClick("name")}>
          {"Name"}
          <span className="mx-1 name">
            <FontAwesomeIcon icon={faCaretUp} className="ASC" />
            <FontAwesomeIcon icon={faCaretDown} className="DESC" />
          </span>
        </div>
      ),
      selector: (row) => row.name,
      key: "name",
      width: "25%",
    },
    {
      // name: "GI",
      name: (
        <div
          className="tHead"
          onClick={() => handleHeaderRowClick("glycemic_index")}
        >
          {"GI"}
          <span className="mx-1 glycemic_index">
            <FontAwesomeIcon icon={faCaretUp} className="ASC" />
            <FontAwesomeIcon icon={faCaretDown} className="DESC" />
          </span>
        </div>
      ),
      selector: (row) => row.glycemic_index || "-",
      key: "glycemic_index",
      width: "10%",
    },
    {
      name: (
        <div
          className="tHead"
          onClick={() => handleHeaderRowClick("glycemic_index_multiplier")}
        >
          {"GI"} <br /> {"Multiplier"}
          <span className="mx-1 glycemic_index_multiplier">
            <FontAwesomeIcon icon={faCaretUp} className="ASC" />
            <FontAwesomeIcon icon={faCaretDown} className="DESC" />
          </span>
        </div>
      ),
      selector: (row) => row.glycemic_index_multiplier || "-",
      key: "glycemic_index_multiplier",
      width: "10%",
    },
    {
      // name: "GI AI",
      name: (
        <div
          className="tHead"
          onClick={() => handleHeaderRowClick("glycemic_index_ai")}
        >
          {"GI AI"}
          <span className="mx-1 glycemic_index_ai">
            <FontAwesomeIcon icon={faCaretUp} className="ASC" />
            <FontAwesomeIcon icon={faCaretDown} className="DESC" />
          </span>
        </div>
      ),
      selector: (row) => row.glycemic_index_ai || "-",
      key: "glycemic_index_ai",
      width: "10%",
    },
    {
      name: "Cals",
      selector: (row) => row.cc_servings,
      width: "10%",
      cell: (row) => {
        const { calories } =
          row?.cc_servings?.find((item) => item.is_default) ||
          row?.cc_servings[0];
        return `${calories} Cals`;
      },
    },
    {
      name: "Serving",
      selector: (row) => row.cc_servings,
      cell: (row) => {
        const { number_of_units, measurement_description } =
          row?.cc_servings?.find((item) => item.is_default) ||
          row?.cc_servings[0];
        return `${parseFloat(number_of_units).toFixed(
          1
        )} ${measurement_description.replace(/\s*\([^)]*\)\s*/g, '')}`;
      },
    },

    {
      name: "Edit",
      cell: (row) => (
        <Button
          variant="outline-success"
          size="sm"
          className="mx-2 viewBtn"
          onClick={async () => {
            setIsPreLoading(true);
            setSelectedFoodDetails([]);
            setSelectedFoodServing([]);
            setSelectedFoodMicros([]);

            try {
              const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
                row?.food_id,
                foodProfileId
              );

              let responseData = CCFoodNutrientsResponse?.data;

              setSelectedFoodDetails(responseData?.cc_food);
              setSelectedFoodServing(
                responseData?.cc_food?.cc_servings?.find(
                  (item) => item.is_default
                ) || responseData?.cc_food?.cc_servings[0]
              );
              setSelectedFoodMicros(responseData?.micros);
              setIsPreLoading(false);
              setShowNutrientView(true);
            } catch (error) {
              console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
              );
              setIsPreLoading(false);
            } finally {
              setIsPreLoading(false);
            }
          }}
        >
          <FontAwesomeIcon icon={faEye} /> View
        </Button>
      ),
    },
  ];

  //Table Header Click for Sorting
  const handleHeaderRowClick = (keyValue) => {
    setFoodListParam((prevFoodListParam) => ({
      ...prevFoodListParam,
      orderBy:keyValue,
      order: prevFoodListParam.order === "ASC" ? "DESC" : "ASC"
    }));
  };

  //Food List Data using Param
  const fetchFoodListData = async () => {
    setIsPreLoading(true);
    try {

      const params = [];

      for (const key in foodListParam) {
        // if (foodListParam[key]) {
          params.push(`${key}=${encodeURIComponent(foodListParam[key])}`);
        // }
      }
      const UrlParam = `?${params.join("&")}`;

      const CCFoodListResponse = await getCCFoodList(
        `${UrlParam}`,
        accessToken
      );
      setFoodListData(CCFoodListResponse?.data?.cc_food)
      setTotalFoodRows(CCFoodListResponse?.data?.food_count);
      setIsPreLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsPreLoading(false);
    } finally {
      setIsPreLoading(false);
    }
  };

  //Pagination Change
  const handleFoodPageChange = (page) => {
    const newOffset = (page - 1) * foodListParam?.limit;
    setFoodListParam((prevFoodListParam) => ({
      ...prevFoodListParam,
      offset: newOffset,
    }));
  };

  //get Foodlist based on Param
  useEffect(() => {
    if (!initialRenderFood && !initComp) {
      fetchFoodListData();
    }
  }, [foodListParam]);

  useEffect(()=>{
    if (initialRenderFood) {
      fetchFoodListData();
      setInitialRenderFood(false);
    }

    setTimeout(() => {
      setInitComp(false)
    }, 1000);
  },[])

  useEffect(() => {
    if (isSearchParam) {
      setFoodListParam((prevState)=>({
        ...prevState,
        search: foodSearch
      }))
      setIsSearchParam(false);
    }
  }, [isSearchParam]);

  return (
    <div className="Nutrition-Home">
      <div className="FoodList-Section">
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" /> Food List
      </section>

        
        {/* <Box sx={{ flexGrow: 1 }} className="m-0 mt-3"> */}
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <Form.Group
                className="mb-0 text"
                controlId="exampleForm.ControlInput1"
              >
                <div className="" ref={divRef}>
                  <TextField
                    label="Add food from FATSECRET"
                    className=""
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="name"
                    value={selectedInput}
                    onChange={handleInputChange}
                  />
                  <Dropdown
                    show={
                      suggestionsShown &&
                      selectedInput?.length > 0 &&
                      suggestions?.length > 0
                    }
                  >
                    <Dropdown.Menu className="ScrollDesign">
                      {suggestions?.map((suggestion, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          <div className="search-dropdown">
                            <span className="food-name">
                              {suggestion?.food_name} <br />{" "}
                              <small>
                                {suggestion?.servings[0]?.number_of_units}{" "}
                                {
                                  suggestion?.servings[0]
                                    ?.measurement_description
                                }
                              </small>
                            </span>
                            <span>
                              {suggestion?.servings[0]?.calories} Cals
                            </span>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className="text-end food-list search-section"
            >
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="food-search-section"
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Food"
                  inputProps={{ "aria-label": "Search Food" }}
                  value={foodSearch} //{foodListParam?.search}
                  onChange={(e) => {
                    // setFoodListParam((prevFoodListParam) => ({
                    //   ...prevFoodListParam,
                    //   search: e.target.value,
                    // }));
                    setFoodSearch(e.target.value);
                  }}
                />

                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="py-2 food-search-clear"
                  onClick={() => {
                    setFoodSearch("");
                    setFoodListParam((prevFoodListParam) => ({
                      ...prevFoodListParam,
                      search: "",
                    }));
                  }}
                >
                  <FontAwesomeIcon icon={faClose} />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="py-2 food-search-clear"
                  onClick={() => {
                    setIsSearchParam(true);
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </IconButton>
              </Paper>

              <Button
                variant="outline-success"
                color="secondary"
                className="mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setShowAddFoodView(true);
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add Food
              </Button>
              <Button
                variant="outline-secondary"
                color="secondary"
                className=" mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setFoodListParam((prevFoodListParam) => ({
                    ...prevFoodListParam,
                    offset: 0,
                  }));
                }}
              >
                <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
                <span>Refresh</span>
              </Button>
            </Grid>
          </Grid>
        {/* </Box> */}
        <div
          className={`dt-section food-dt ${foodListParam?.orderBy} ${foodListParam?.order} my-2`}
        >
          <DataTable
            columns={foodListColumns}
            striped
            data={foodListData}
            pagination
            paginationPerPage={foodListParam?.limit}
            paginationServer
            paginationTotalRows={totalFoodRows}
            onChangePage={handleFoodPageChange}
            // onSort={handleSort}
            searchable
            dense
            className="dt-user-list dh-table-basic"
          />
        </div>
        
      </div>

      {/* {isPreLoading && <Preloader />} */}

      <CreateFood
        show={showAddFoodView}
        setAddFoodShow={setShowAddFoodView}
        setNutrientShow={setShowNutrientView}
        isManualFoodEdit={isManualFoodEdit}
        setIsManualFoodEdit={setIsManualFoodEdit}
        fetchFoodListData={fetchFoodListData} //get FoodList
      />

      <FoodView
        show={showNutrientView}
        setNutrientShow={setShowNutrientView}
        setShowAddFoodView={setShowAddFoodView}
        setIsManualFoodEdit={setIsManualFoodEdit}
      />
    </div>
  );
}

export default Food