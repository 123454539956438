import {
  faCircleCheck,
  faClock,
  faClose,
  faCloudMoon,
  faCookieBite,
  faEye,
  faFileLines,
  faFilter,
  faMountainSun,
  faRefresh,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "./MealTracker.scss";
import FoodIntakeReview from "./FoodIntakeReview";
import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import FoodIntakeFilterUC from "./FoodIntakeFilterUC";
import MainContext from "../../../context/MainContext";
import NutritionContext from "../../../context/NutritionContext";
import { getListFoodIntake } from "../../../utils/nutritionApi";

const UnclassifiedFood = () => {
  const [initComp, setInitComp] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();

    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");

    return (
      <div>
        {`${dd}-${mm}-${yyyy}`}
        <br />
        <small>
          <FontAwesomeIcon icon={faClock} style={{ color: "darkblue" }} />{" "}
          {`${hh}:${min}:${ss}`}
        </small>
      </div>
    );
  }

  const foodIntakeDTColumns = [
    {
      name: "ID",
      selector: (row) => parseInt(row.id),
      key: "id",
      minWidth: "10%",
      // sortable: true,
    },
    {
      name: "User",
      selector: (row) => row?.food_profile_id,
      key: "user",
      minWidth: "20%",
      // sortable: true,
      cell: (row) => (
        <div className="user-section">
          <span className="name">
            <b>Food Profile ID: </b>
            {row?.food_profile_id}
          </span>
          <span className="id">
            {"[User ID: "}
            {row.cc_profile?.user?.id}
            {"]"}
          </span>
        </div>
      ),
    },
    {
      name: "Meal Type",
      selector: (row) => row.meal_type,
      key: "name",
      minWidth: "15%",
      // sortable: true,
      cell: (row) => {
        const mealIcons = {
          breakfast: faMountainSun,
          lunch: faSun,
          dinner: faCloudMoon,
          snack: faCookieBite,
        };

        return (
          <div className="meal-type">
            <FontAwesomeIcon
              icon={mealIcons[row.meal_type]}
              className={row.meal_type}
            />
            {row.meal_type}
          </div>
        );
      },
    },
    {
      name: "Intake at",
      selector: (row) => formatTimestamp(row.intake_at),
      key: "name",
      minWidth: "15%",
      // sortable: true,
    },
    {
      name: "Intake Image",
      key: "name",
      minWidth: "20%",
      // sortable: true,
      cell: (row) => (
        <div className="food-intake-images">
          <AvatarGroup total={Math.min(row?.n_image, 5)}>
            {row?.cc_intake_images?.slice(0, 5)?.map((image, index) => (
              <Avatar key={index} alt="food" src={image?.thumbnail_url} />
            ))}
          </AvatarGroup>
        </div>
      ),
    },
    {
      name: "Is Skipped",
      selector: (row) => row.is_skipped,
      key: "name",
      minWidth: "15%",
      cell: (row) => (
        <div className="food-intake-skipped-badge">
          <span
            className={`badge ${row.is_skipped ? "skipped" : "not-skipped"}`}
          >
            {row.is_skipped ? "Skipped" : "No"}
          </span>
        </div>
      ),
      // sortable: true,
    },
    {
      name: "Status",
      key: "name",
      minWidth: "10%",
      // sortable: true,
      cell: (row) => (
        <>
          <div className="text-center verified-tick">
            {row.validated_by_user_id ? (
              <Tooltip title={"Verified"}>
                <FontAwesomeIcon icon={faCircleCheck} className="verified" />
              </Tooltip>
            ) : (
              <Tooltip title={"Processing"}>
                <FontAwesomeIcon icon={faClock} className="not-verified" />
              </Tooltip>
            )}
          </div>
        </>
      ),
    },
    {
      name: "View",
      minWidth: "10%",
      cell: (row) => (
        <div className="text-center">
          <Button
            variant="outline-primary"
            size="sm"
            className="mx-2 viewBtn action-button-responsive"
            onClick={() => {
              setSelectedFoodIntake(row);
              setShow(true);
            }}
          >
            <FontAwesomeIcon icon={faEye} /> <span>View</span>
          </Button>
        </div>
      ),
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    // noRowsPerPage: true,
  };

  const { headers, setIsPreLoading, ShowNotification } =
    useContext(MainContext);

  const {
    foodIntakeUCList,
    setFoodIntakeUCList,
    foodIntakeUCCount,
    setFoodIntakeUCCount,
    foodIntakeUCParam,
    setFoodIntakeUCParam,
    initialRenderUCFood,
    setInitialRenderUCFood,
  } = useContext(NutritionContext);

  const { REACT_APP_BASE__URL } = process.env;
  // const [loading, setLoading] = useState(true);

  /* Meal Tracker */

  const [selectedFoodIntake, setSelectedFoodIntake] = useState([]);

  const getFoodIntakeList = async () => {
    setIsPreLoading(true);

    const params = [];

    for (const key in foodIntakeUCParam) {
      // if (foodIntakeUCParam[key]) {
      params.push(`${key}=${encodeURIComponent(foodIntakeUCParam[key])}`);
      // }
    }
    const UrlParam = `?${params.join("&")}`;

    let requestData = foodIntakeUCParam;

    try {
      const getListFoodintakeResponse = await getListFoodIntake(requestData);

      setFoodIntakeUCList(getListFoodintakeResponse.data?.food_intake_summary);
      setFoodIntakeUCCount({
        total_count: getListFoodintakeResponse.data?.total_count,
        validated_count: getListFoodintakeResponse?.data?.validated_count,
        non_validated_count:
          getListFoodintakeResponse?.data?.non_validated_count,
      });
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message);
    } finally {
      setIsPreLoading(false);
    }

    //old one
    // try {
    //   const response = await axios.get(
    //     `${REACT_APP_BASE__URL}/v3/int/human-token/nutrition/food-intake/list${UrlParam}`,
    //     { headers }
    //   );
    //   setFoodIntakeList(response?.data?.data?.food_intake_summary);
    //   setFoodIntakeCount({
    //     total_count: response?.data?.data?.total_count,
    //     validated_count: response?.data?.data?.validated_count,
    //     non_validated_count: response?.data?.data?.non_validated_count,
    //   });
    //   setIsPreLoading(false);
    // } catch (error) {
    //   console.error("Error:", error);
    //   setIsPreLoading(false);
    // } finally {
    //   setIsPreLoading(false);
    // }
  };

  //Initial Rendering
  useEffect(() => {
    if (initialRenderUCFood) {
      getFoodIntakeList();
      setInitialRenderUCFood(false);
    }

    setTimeout(() => {
      setInitComp(false);
    }, 1000);
  }, []);

  //Param Dependency
  useEffect(() => {
    if (!initialRenderUCFood && !initComp) {
      getFoodIntakeList();
    }
  }, [foodIntakeUCParam]);

  //Offset Change
  const handleFoodIntakePageChange = (page) => {
    if (!initialRenderUCFood) {
      const newOffset = (page - 1) * foodIntakeUCParam?.limit;
      setFoodIntakeUCParam((prevFoodIntakeParam) => ({
        ...prevFoodIntakeParam,
        offset: newOffset,
      }));
      setCurrentPage(page);
    }
  };

  //Rows Count Change
  const handleFoodIntakeRowsPerPageChange = (newPerPage) => {
    if (!initialRenderUCFood) {
      if (newPerPage !== foodIntakeUCParam?.limit) {
        setFoodIntakeUCParam((prevFoodIntakeParam) => ({
          ...prevFoodIntakeParam,
          limit: newPerPage,
        }));
      }
    }
  };

  const [show, setShow] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [filterMealTypes, setFilterMealTypes] = useState([]);
  const [filterUserID, setFilterUserID] = useState([]);
  const [filterIsUnidentified, setFilterIsUnidentified] = useState(null);

  return (
    <div>
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" /> Unclassified Food List
      </section>

      <div>
        <Box sx={{ flexGrow: 1 }} className="m-0 nutrition-home-card">
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Card className="primary">
                <Typography variant="h6" className="heading">
                  Total Food Intake
                </Typography>
                <Typography variant="body1" className="count">
                  {foodIntakeUCCount?.validated_count +
                    foodIntakeUCCount?.non_validated_count || 0}
                </Typography>
              </Card>
            </Grid>
            <Grid item md={4} xs={6}>
              <Card className="success">
                <Typography variant="h6" className="heading">
                  Validated Count
                </Typography>
                <Typography variant="body1" className="count">
                  {foodIntakeUCCount?.validated_count || 0}
                </Typography>
              </Card>
            </Grid>
            <Grid item md={4} xs={6}>
              <Card className="danger">
                <Typography variant="h6" className="heading">
                  Pending Validation
                </Typography>
                <Typography variant="body1" className="count">
                  {foodIntakeUCCount?.non_validated_count || 0}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="action-button-group">
        <Button
          variant="outline-secondary"
          size="sm"
          className="my-2"
          onClick={() => {
            // getFoodIntakeList();
            setFoodIntakeUCParam((prevFoodIntakeParam) => ({
              ...prevFoodIntakeParam,
              offset: 0,
            }));
            setCurrentPage(1);
            setResetPaginationToggle(!resetPaginationToggle);
          }}
        >
          <FontAwesomeIcon icon={faRefresh} /> <span>Refresh</span>
        </Button>

        <Button
          variant="outline-primary"
          size="sm"
          className="my-2 mx-2"
          onClick={() => {
            setFilterShow(true);
          }}
        >
          <FontAwesomeIcon icon={faFilter} /> <span>Filter</span>
        </Button>

        <Button
          variant="outline-danger"
          size="sm"
          className="my-2"
          onClick={() => {
            setFoodIntakeUCParam((prevState) => ({
              ...prevState,
              meal_type: "",
              is_validated: false,
              is_unidentified: "",
              start_time: "",
              end_time: "",
              offset: 0,
              user_id:""
            }));
            setFilterUserID("")
            setFilterIsUnidentified(null)
            setFilterMealTypes([]);
            setResetPaginationToggle(!resetPaginationToggle);
          }}
        >
          <FontAwesomeIcon icon={faClose} /> <span>Clear Filter</span>
        </Button>
      </div>

      <div className="dt-section meal-dt p-0 mt-1">
        <DataTable
          columns={foodIntakeDTColumns}
          striped
          data={foodIntakeUCList}
          pagination
          searchable
          // progressPending={loading}
          responsive={true}
          paginationPerPage={foodIntakeUCParam?.limit || 10}
          paginationComponentOptions={paginationOptions}
          paginationServer
          paginationTotalRows={foodIntakeUCCount?.total_count}
          onChangePage={handleFoodIntakePageChange}
          onChangeRowsPerPage={handleFoodIntakeRowsPerPageChange}
          dense
          paginationDefaultPage={1}
          paginationResetDefaultPage={!resetPaginationToggle}
          className="dt-user-list dh-table-basic"
        />
      </div>

      <FoodIntakeReview
        show={show}
        setShow={setShow}
        selectedFoodIntake={selectedFoodIntake}
        getFoodIntakeList={getFoodIntakeList}
      />
      <FoodIntakeFilterUC
        show={filterShow}
        setShow={setFilterShow}
        setCurrentPage={setCurrentPage}
        filterMealTypes={filterMealTypes}
        setFilterMealTypes={setFilterMealTypes}
        filterUserID={filterUserID} 
        setFilterUserID={setFilterUserID}
        filterIsUnidentified={filterIsUnidentified}
        setFilterIsUnidentified={setFilterIsUnidentified}
        setResetPaginationToggle={setResetPaginationToggle}
        resetPaginationToggle={resetPaginationToggle}
      />
    </div>
  );
};

export default UnclassifiedFood;
