import React, { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import "./Query.scss";
import {
  // faClock,
  faPaperPlane,
  faSearch,
  faChevronCircleLeft,
  faRefresh,
  faEllipsisVertical,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MenuItem from "@mui/material/MenuItem";
// import RefreshIcon from "@mui/icons-material/Refresh";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import { ReactMediaRecorder } from "react-media-recorder-2";

import {
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  // Send as SendIcon,
  Attachment as AttachmentIcon,
} from "@mui/icons-material";

import {
  changeUserQueryStatus,
  getUserMessages,
  getUsers,
  sendMessageToUser,
  sendMediaMessageToUser,
} from "../../utils/whatsappQueryApi";
import WhatsappQueryContext from "../../context/WhatsappQueryContext";
import { useContext } from "react";
import { capitalized } from "../../common/commonFunctions";
const WhatsappQuery = () => {

  const { users, setUsers, messages, setMessages } =
    useContext(WhatsappQueryContext);

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [active, setActive] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [searchedUsers, setSearchedUsers] = useState([]);
  const scrollBottomRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const chatWindowRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [messagesOffset, setMessagesOffset] = useState(0);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [recording, setRecording] = useState(false);
  const [reinitiateInterval, setReinitiateInterval] = useState(false);
  const [scrolledDown, setScrolledDown] = useState(false);
  const { REACT_APP_REFRESH_WAIT_TIME } = process.env || 2 * 1000;
  const [uploadingAudio, setUploadingAudio] = useState(false);

  useEffect(() => {
    return async () => {
      setPhone(users?.[0]?.mobile);
      setName(users?.[0]?.name);
    };
  }, []);

  const filterMenuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterMenuRef.current &&
        !filterMenuRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (currentUser._id) {
      intervalId = setInterval(() => {
        getUserMessages({ user_id: currentUser._id, offset: 0 })
          .then((response) => {
            setHasMore(true);
            setMessagesOffset(0);
            setScrolledDown(false);
            setMessages(() => response.data.messages);
            setMessagesOffset(response.data.messages.length);
            getUsersArray().then(() => {
              setCurrentUser(
                users.find((user) => user._id === currentUser._id)
              );
            });
          })
          .catch((error) => {
            console.log(error.message);
          });
      }, REACT_APP_REFRESH_WAIT_TIME);
    }
    return () => clearInterval(intervalId);
  }, [reinitiateInterval, currentUser._id]);
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const getUsersArray = async () => {
    try {
      const response = await getUsers();
      setUsers(response.data.users);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getUsersArray();
  }, []);
  useEffect(() => {
    handleFilterUsersBasedOnStatus();
  }, [filterValue]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [typedMessage, setTypedMessage] = useState("");

  const scrollChatToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    setTimeout(() => {
      setScrolledDown(true);
    }, 1000);
  };

  useEffect(() => {
    const scrollDelay = setTimeout(() => {
      scrollChatToBottom();
    }, 0);

    return () => clearTimeout(scrollDelay);
  }, [messages]);

  useEffect(() => {
    setMessages([]);
    setMessagesOffset(0);
    setHasMore(true);
    setTimeout(() => {
      scrollBottomRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 2000);
  }, [currentUser._id]);

  const handleMessageChange = (event) => {
    setTypedMessage(event.target.value);
  };
  const handleSendMessageOnEnter = (event, user_id) => {
    if (event.shiftKey && event.key === "Enter") {
      const newMessage = typedMessage + "\n";
      setTypedMessage(newMessage);
    } else if (event.key === "Enter") {
      handleSendMessage(user_id);
      setTypedMessage("");
    }
    setTypedMessage(event.target.value);
  };
  const handleSearchUsers = (event) => {
    const searchValue = event.target.value.toLowerCase();
    let filteredUser;
    if (filterValue && filteredUsers.length > 0) {
      handleFilterUsersBasedOnStatus();
      filteredUser = filteredUsers.filter((user) => {
        return (
          user.name.toLowerCase().includes(searchValue) ||
          user.mobile.includes(searchValue)
        );
      });
    } else {
      filteredUser = users.filter((user) => {
        return (
          user.name.toLowerCase().includes(searchValue) ||
          user.mobile.includes(searchValue)
        );
      });
      setFilterValue(null);
    }

    setSearchedUsers(filteredUser);
  };

  const handleFilterUsersBasedOnStatus = () => {
    if (filterValue) {
      setSearchedUsers([]);
      const filteredUsers = users.filter((user) => {
        return user.status === filterValue;
      });
      if (filteredUsers.length > 0) {
        setFilteredUsers(filteredUsers);
      } else {
        setFilteredUsers([]);
        setFilterValue(null);
      }
    }
  };
  const handleSendMessage = (user_id) => {
    sendMessageToUser({ from: phone, message: typedMessage, type: "text" })
      .then(() => {
        setTypedMessage("");
        getUsersArray();
      })
      .then(() => {
        setHasMore(true);
        setMessagesOffset(0);
        setScrolledDown(false);
        getUserMessages({ ...user_id, offset: 0 })
          .then((response) => {
            setMessages(response.data.messages);
          })
          .catch((error) => {
            console.log(error.message);
          });
      });
  };
  const handleGetUserMessages = (user_id, messagesOffset) => {
    getUserMessages(user_id, messagesOffset)
      .then((response) => {
        setMessages(response.data.messages);
        setMessagesOffset(response.data.messages.length);
        setIsChatOpen(true);
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };
  const handleGetPreviousMessages = (user_id, offset) => {
    if (hasMore) {
      getUserMessages(user_id, offset)
        .then((response) => {
          if (response.data.messages.length > 0) {
            setMessages([...response.data.messages, ...messages]);
            setMessagesOffset(messages.length + response.data.messages.length);
          } else if (response.data.messages.length === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          console.log(error?.message);
        });
    }
  };
  function isToday(timestamp) {
    const now = new Date();
    const date = new Date(timestamp);
    return (
      now.getDate() === date.getDate() &&
      now.getMonth() === date.getMonth() &&
      now.getFullYear() === date.getFullYear()
    );
  }

  // Function to check if a timestamp represents yesterday's date
  function isYesterday(timestamp) {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const date = new Date(timestamp);
    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  const fetchNewMessages = (user_id, offset) => {
    setIsLoading(true);
    handleGetPreviousMessages(user_id, offset);
    setIsLoading(false);
  };

  const handleScroll = (user_id, offset) => {
    const chatWindow = chatWindowRef.current;
    const scrollTop = chatWindow.scrollTop;
    // const totalHeight = chatWindow.scrollHeight;
    if (scrollTop === 0 && !isLoading && hasMore) {
      fetchNewMessages(user_id, offset);
      chatWindow.scrollTop = 400;
    }
  };

  const handleAttachMedia = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      event.target.value = null;
    }
  };

  useEffect(() => {
    if (selectedFile) {
      uploadFile();
    }
  }, [selectedFile]);

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("message", selectedFile);
    formData.append("from", phone);

    let fileType = selectedFile.type.split("/");
    if (fileType[0] === "application") {
      fileType = fileType[1];
    } else {
      fileType = fileType[0];
    }
    formData.append("type", fileType);
    const fileName = selectedFile?.name || "media";
    formData.append("name", fileName);
    sendMediaMessageToUser(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setHasMore(true);
        setMessagesOffset(0);
        setScrolledDown(false);
        getUserMessages({ user_id: currentUser._id, offset: 0 })
          .then((response) => {
            setMessages(response.data.messages);
          })
          .catch((error) => {
            console.log(error.message);
          });
        setSelectedFile(null);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  // const activeUser = (optionId) => {
  //   setActive(optionId);
  // };

  const changeStatus = async (data) => {
    return await changeUserQueryStatus(data);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorFilterEl, setAnchorFilterEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSub = () => {
    setAnchorEl(null);
  };
  const handleCloseFilterSub = () => {
    setAnchorFilterEl(null);
  };

  const [lightboxShow, setLightboxShow] = useState(false);
  const [lightboxImgUrl, setLightboxImgUrl] = useState("");


  return (
    <section className="whatsapp-query-section">
      <div className={`lightbox ${lightboxShow ? "show" : ""} `}>
        <FontAwesomeIcon
          icon={faClose}
          className="close"
          onClick={() => {
            setLightboxShow(false);
          }}
        />
        <img src={lightboxImgUrl} alt="whatsapp" loading="lazy" />
      </div>

      <div className="user-message-list">
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={4}
            className={`left-side ${
              !isChatOpen ? "responsive-open" : "responsive-close"
            }`}
          >
            <Card>
              <Card.Header>
                <div>
                  <Avatar
                    alt="Login User"
                    src={require("../../assets/img/dh-logo-dark.png")}
                    className="user-avatar mx-2"
                  />
                  <h4>Chats</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <section className="whatsapp-search-section">
                  <TextField
                    className="my-2"
                    //  label="Search"
                    size={"small"}
                    onChange={handleSearchUsers}
                    fullWidth
                    placeholder="Search chat"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faSearch} />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                  <div
                    style={{
                      position: "relative",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={toggleFilters}
                  >
                    <Tooltip title="Filter Status">
                      <IconButton
                        onClick={(event) => {
                          setAnchorFilterEl(event.currentTarget);
                        }}
                      >
                        <FilterAltIcon style={{ color: "green" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorFilterEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorFilterEl)}
                      onClose={handleCloseFilterSub}
                    >
                      <MenuItem
                        onClick={() => {
                          setFilterValue(null);
                          setFilteredUsers([]);
                          handleCloseFilterSub();
                        }}
                      >
                        ALL
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setFilterValue("OPEN");
                          handleCloseFilterSub();
                        }}
                      >
                        OPEN
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setFilterValue("PROGRESS");
                          handleCloseFilterSub();
                        }}
                      >
                        PROGRESS
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setFilterValue("DONE");
                          handleCloseFilterSub();
                        }}
                      >
                        DONE
                      </MenuItem>
                    </Menu>

                    {showFilters && (
                      <div
                        ref={filterMenuRef} // remove
                        className="hide"
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: 0,
                          backgroundColor: "#ffffff",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          borderRadius: "4px",
                          padding: "8px",
                          zIndex: 100,
                        }}
                      ></div>
                    )}
                  </div>
                </section>
                <section className="message-short ScrollDesign">
                  {searchedUsers.length > 0
                    ? searchedUsers &&
                      searchedUsers?.map((user) => {
                        const currentDate = new Date();

                        const messageDate = new Date(user?.message?.createdAt);
                        let messageTime;

                        const timeDifference = Math.floor(
                          (currentDate - messageDate) / (1000 * 60 * 60 * 24)
                        );

                        if (
                          messageDate.toDateString() ===
                          currentDate.toDateString()
                        ) {
                          const options = {
                            hour: "2-digit",
                            minute: "2-digit",
                          };
                          messageTime = messageDate.toLocaleTimeString(
                            undefined,
                            options
                          );
                        } else if (timeDifference === 1) {
                          messageTime = "Yesterday";
                        } else {
                          const options = {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          };
                          messageTime = messageDate.toLocaleDateString(
                            undefined,
                            options
                          );
                        }
                        const key = user._id;

                        return (
                          <div
                            key={key}
                            onClick={() => {
                              handleGetUserMessages({
                                user_id: user._id,
                                offset: 0,
                              });
                              setPhone(user?.mobile);
                              setName(user?.name);
                              setActive(key);
                              setTypedMessage("");
                              setCurrentUser(user);
                              setMessagesOffset(0);
                              setScrolledDown(false);
                              setHasMore(true);

                              if (user?.status === "OPEN") {
                                changeStatus({
                                  user_id: user._id,
                                  status: "PROGRESS",
                                })
                                  .then(() => console.log("success"))
                                  .catch((error) =>
                                    console.error(
                                      "Error occurred while changing status:",
                                      error
                                    )
                                  );

                                setUsers((prevUsers) => {
                                  const userIndex = prevUsers.findIndex(
                                    (u) => u._id === user._id
                                  );

                                  if (userIndex !== -1) {
                                    const updatedUsers = [...prevUsers];
                                    updatedUsers[userIndex].status = "PROGRESS";
                                    return updatedUsers;
                                  }
                                  return prevUsers;
                                });
                              }
                            }}
                            className={
                              key === active && user?.status === "OPEN"
                                ? "active message-item todo-status"
                                : key === active
                                ? "active message-item"
                                : user?.status === "OPEN"
                                ? "message-item todo-status"
                                : "message-item"
                            }
                          >
                            <Grid container spacing={1}>
                              {" "}
                              <Grid
                                item
                                md={2}
                                sm={2}
                                xs={2}
                                className="profile-left"
                              >
                                <Avatar
                                  alt="Login User"
                                  src={require("../../assets/img/avatar-1.png")}
                                  className="user-avatar mx-2"
                                />
                              </Grid>
                              <Grid
                                item
                                md={10}
                                sm={10}
                                xs={10}
                                className="profile-right"
                              >
                                <div className="content">
                                  <h5 className="phone">
                                    {capitalized(user?.name)}
                                    <small className="time">
                                      {messageTime}
                                    </small>
                                  </h5>
                                  <p className="message">
                                    {user?.message.message}
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                            <div className="status to-do">{user?.status}</div>
                          </div>
                        );
                      })
                    : filteredUsers.length > 0
                    ? filteredUsers &&
                      filteredUsers?.map((user) => {
                        const currentDate = new Date();
                        const messageDate = new Date(user?.message?.createdAt);
                        let messageTime;

                        const timeDifference = Math.floor(
                          (currentDate - messageDate) / (1000 * 60 * 60 * 24)
                        );

                        if (
                          messageDate.toDateString() ===
                          currentDate.toDateString()
                        ) {
                          const options = {
                            hour: "2-digit",
                            minute: "2-digit",
                          };
                          messageTime = messageDate.toLocaleTimeString(
                            undefined,
                            options
                          );
                        } else if (timeDifference === 1) {
                          messageTime = "Yesterday";
                        } else {
                          const options = {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          };
                          messageTime = messageDate.toLocaleDateString(
                            undefined,
                            options
                          );
                        }
                        const key = user._id;

                        return (
                          <div
                            key={key}
                            onClick={() => {
                              handleGetUserMessages({
                                user_id: user._id,
                                offset: 0,
                              });
                              setPhone(user?.mobile);
                              setName(user?.name);
                              setTypedMessage("");
                              setActive(key);
                              setCurrentUser(user);
                              setMessagesOffset(0);
                              setHasMore(true);
                              setScrolledDown(false);

                              if (user?.status === "OPEN") {
                                changeStatus({
                                  user_id: user._id,
                                  status: "PROGRESS",
                                })
                                  .then(() => console.log("changes status"))
                                  .catch((error) =>
                                    console.error(
                                      "Error occurred while changing status:",
                                      error
                                    )
                                  );

                                setUsers((prevUsers) => {
                                  const userIndex = prevUsers.findIndex(
                                    (u) => u._id === user._id
                                  );

                                  if (userIndex !== -1) {
                                    const updatedUsers = [...prevUsers];
                                    updatedUsers[userIndex].status = "PROGRESS";
                                    return updatedUsers;
                                  }
                                  return prevUsers;
                                });
                              }
                            }}
                            className={
                              key === active && user?.status === "OPEN"
                                ? "active message-item todo-status"
                                : key === active
                                ? "active message-item"
                                : user?.status === "OPEN"
                                ? "message-item todo-status"
                                : "message-item"
                            }
                          >
                            <Grid container spacing={1}>
                              {" "}
                              <Grid
                                item
                                md={2}
                                sm={2}
                                xs={2}
                                className="profile-left"
                              >
                                <Avatar
                                  alt="Login User"
                                  src={require("../../assets/img/avatar-1.png")}
                                  className="user-avatar mx-2"
                                />
                              </Grid>
                              <Grid
                                item
                                md={10}
                                sm={10}
                                xs={10}
                                className="profile-right"
                              >
                                <div className="content">
                                  <h5 className="phone">
                                    {capitalized(user?.name)}
                                    <small className="time">
                                      {messageTime}
                                    </small>
                                  </h5>
                                  {user?.message?.type === "text" && (
                                    <p className="message">
                                      {user?.message.message}
                                    </p>
                                  )}
                                  {user?.message?.type === "image" && (
                                    <p className="message">Image</p>
                                  )}
                                  {user?.message?.type === "video" && (
                                    <p className="message">Video</p>
                                  )}
                                  {user?.message?.type === "document" && (
                                    <p className="message">Document</p>
                                  )}
                                  {user?.message?.type === "audio" && (
                                    <p className="message">Audio</p>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                            <div className="status to-do">{user?.status}</div>
                          </div>
                        );
                      })
                    : users?.map((user) => {
                        const currentDate = new Date();

                        const messageDate = new Date(user?.message?.createdAt);
                        let messageTime;

                        const timeDifference = Math.floor(
                          (currentDate - messageDate) / (1000 * 60 * 60 * 24)
                        );

                        if (
                          messageDate.toDateString() ===
                          currentDate.toDateString()
                        ) {
                          const options = {
                            hour: "2-digit",
                            minute: "2-digit",
                          };
                          messageTime = messageDate.toLocaleTimeString(
                            undefined,
                            options
                          );
                        } else if (timeDifference === 1) {
                          messageTime = "Yesterday";
                        } else {
                          const options = {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          };
                          messageTime = messageDate.toLocaleDateString(
                            undefined,
                            options
                          );
                        }
                        const key = user._id;

                        return (
                          <div
                            key={key}
                            onClick={() => {
                              handleGetUserMessages({
                                user_id: user._id,
                                offset: 0,
                              });
                              setPhone(user?.mobile);
                              setName(user?.name);
                              setTypedMessage("");
                              setActive(key);
                              setCurrentUser(user);
                              setMessagesOffset(0);
                              setHasMore(true);
                              setScrolledDown(false);

                              if (user?.status === "OPEN") {
                                changeStatus({
                                  user_id: user._id,
                                  status: "PROGRESS",
                                })
                                  .then(() => console.log("changes status"))
                                  .catch((error) =>
                                    console.error(
                                      "Error occurred while changing status:",
                                      error
                                    )
                                  );

                                setUsers((prevUsers) => {
                                  const userIndex = prevUsers.findIndex(
                                    (u) => u._id === user._id
                                  );

                                  if (userIndex !== -1) {
                                    const updatedUsers = [...prevUsers];
                                    updatedUsers[userIndex].status = "PROGRESS";
                                    return updatedUsers;
                                  }
                                  return prevUsers;
                                });
                              }
                            }}
                            className={
                              key === active && user?.status === "OPEN"
                                ? "active message-item todo-status"
                                : key === active
                                ? "active message-item"
                                : user?.status === "OPEN"
                                ? "message-item todo-status"
                                : "message-item"
                            }
                          >
                            <Grid container spacing={1}>
                              <Grid
                                item
                                md={2}
                                sm={2}
                                xs={2}
                                className="profile-left"
                              >
                                <Avatar
                                  alt="Login User"
                                  src={require("../../assets/img/avatar-1.png")}
                                  className="user-avatar mx-2"
                                />
                              </Grid>
                              <Grid
                                item
                                md={10}
                                sm={10}
                                xs={10}
                                className="profile-right"
                              >
                                <div className="content">
                                  <h5 className="phone">
                                    {capitalized(user?.name)}
                                    <small className="time">
                                      {messageTime}
                                    </small>
                                  </h5>
                                  {user?.message?.type === "text" && (
                                    <p className="message">
                                      {user?.message.message}
                                    </p>
                                  )}
                                  {user?.message?.type === "image" && (
                                    <p className="message">Image</p>
                                  )}
                                  {user?.message?.type === "video" && (
                                    <p className="message">Video</p>
                                  )}
                                  {user?.message?.type === "document" && (
                                    <p className="message">Document</p>
                                  )}
                                  {user?.message?.type === "audio" && (
                                    <p className="message">Audio</p>
                                  )}
                                  {user?.message?.type === "sticker" && (
                                    <p className="message">Sticker</p>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                            <div className="status to-do">{user?.status}</div>
                          </div>
                        );
                      })}
                </section>
              </Card.Body>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            className={`right-side ${
              isChatOpen ? "responsive-open" : "responsive-close"
            }`}
          >
            <Card className="user-full-message-view">
              <Card.Header className="text-start">
                <div className="contact-number">
                  <IconButton
                    className="back-to-list"
                    onClick={() => {
                      setIsChatOpen(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                  </IconButton>
                  {currentUser._id && (
                    <>
                      <Avatar
                        alt="Login User"
                        src={require("../../assets/img/avatar-1.png")}
                        className="user-avatar mx-2"
                      />
                      <h4>
                        {phone}
                        <br /> <small>{name && capitalized(name)}</small>
                      </h4>
                    </>
                  )}
                </div>

                <div className="d-flex justify-content-between">
                  {currentUser?.status === "DONE" ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          alt="resolved"
                          src={require("../../assets/img/resolved.png")}
                          className="user-avatar mx-2 progress-avatar"
                        />
                        <small className="progress-text">Resolved</small>
                      </div>
                    </div>
                  ) : (
                    currentUser._id && (
                      <>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              alt="in-progress"
                              src={require("../../assets/img/in-progress.png")}
                              className="user-avatar mx-2 progress-avatar"
                            />
                            <small className="progress-text">In Progress</small>
                          </div>
                        </div>
                      </>
                    )
                  )}

                  {currentUser._id && (
                    <div className="d-flex justify-content-between">
                      <div>
                        <Tooltip title="Refresh Chat">
                          <IconButton
                            onClick={() => {
                              setReinitiateInterval(!reinitiateInterval);
                              getUserMessages({
                                user_id: currentUser._id,
                                offset: 0,
                              })
                                .then((response) => {
                                  setHasMore(true);
                                  setScrolledDown(false);
                                  setMessages(response.data.messages);
                                  setMessagesOffset(
                                    response.data.messages.length
                                  );
                                  getUsersArray().then(() => {
                                    setCurrentUser(
                                      users.find(
                                        (user) => user._id === currentUser._id
                                      )
                                    );
                                  });
                                })
                                .catch((error) => {
                                  console.log(error.message);
                                });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faRefresh}
                              style={{ color: "green" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="More">
                          <IconButton
                            onClick={handleMenu}
                            style={{ width: "40px" }}
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseSub}
                        >
                          <MenuItem
                            disabled={currentUser.status === "DONE"}
                            onClick={handleClickOpen}
                          >
                            Change Status
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  )}
                </div>
              </Card.Header>
              <ResolveWhatsappPopup />
              <Card.Body
                className="ScrollDesign"
                ref={chatWindowRef}
                onScroll={() => {
                  handleScroll({
                    user_id: currentUser._id,
                    offset: messagesOffset,
                  });
                }}
              >
                <section className="messages">
                  {!isLoading && !hasMore && (
                    <div className="no-more-messages">No more messages</div>
                  )}
                  {messages?.map((message, index) => {
                    const currentTimestamp = new Date(
                      message.createdAt
                    ).getTime();
                    const previousMessage = messages[index - 1];
                    const previousTimestamp = previousMessage
                      ? new Date(previousMessage.createdAt).getTime()
                      : null;
                    const currentDate = new Date(
                      currentTimestamp
                    ).toLocaleDateString();
                    const previousDate = previousTimestamp
                      ? new Date(previousTimestamp).toLocaleDateString()
                      : null;

                    let dateDifference = "";
                    if (currentDate !== previousDate) {
                      if (isToday(currentTimestamp)) {
                        dateDifference = "Today";
                      } else if (isYesterday(currentTimestamp)) {
                        dateDifference = "Yesterday";
                      } else {
                        dateDifference = currentDate;
                      }
                    }
                    return (
                      <div key={message._id}>
                        {dateDifference && (
                          <div className="date-difference">
                            {dateDifference}
                          </div>
                        )}
                        {message && message.type === "text" && (
                          <div
                            className={
                              currentUser._id === message.whatsapp_user_id
                                ? "msg-container user-msg"
                                : "msg-container team-msg"
                            }
                          >
                            <p className="msg line-break">
                              {message.message}
                              <span className="msg-timestamp">
                                {" "}
                                {new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  { hour: "2-digit", minute: "2-digit" }
                                )}
                                {currentUser._id !== message.whatsapp_user_id &&
                                  message.status === "sent" && (
                                    <DoneIcon
                                      style={{
                                        marginLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    />
                                  )}
                                {currentUser._id !== message.whatsapp_user_id &&
                                  message.status === "delivered" && (
                                    <DoneAllIcon
                                      style={{
                                        marginLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    />
                                  )}
                                {currentUser._id !== message.whatsapp_user_id &&
                                  message.status === "read" && (
                                    <DoneAllIcon
                                      style={{
                                        color: "blue",
                                        marginLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    />
                                  )}
                              </span>
                            </p>
                          </div>
                        )}
                        {message && message.type === "image" && (
                          <div
                            className={
                              currentUser._id === message.whatsapp_user_id
                                ? "msg-container user-msg"
                                : "msg-container team-msg"
                            }
                          >
                            <p className="msg">
                              <div
                                onClick={() => {
                                  setLightboxImgUrl(message.message);
                                  setLightboxShow(true);
                                }}
                              >
                                <img
                                  className="msg-img"
                                  src={message.message}
                                  alt="whatsapp"
                                />
                              </div>
                              <span className="msg-timestamp">
                                {" "}
                                {new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  { hour: "2-digit", minute: "2-digit" }
                                )}
                              </span>
                            </p>
                          </div>
                        )}
                        {message && message.type === "video" && (
                          <div
                            key={message._id}
                            className={
                              currentUser._id === message.whatsapp_user_id
                                ? "msg-container user-msg"
                                : "msg-container team-msg"
                            }
                          >
                            <p className="msg">
                              <a
                                href={message.message}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <video className="msg-img" controls>
                                  <source
                                    src={message.message}
                                    type="video/mp4"
                                  />
                                  Your browser does not support this video
                                </video>
                              </a>
                              <span className="msg-timestamp">
                                {" "}
                                {new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  { hour: "2-digit", minute: "2-digit" }
                                )}
                              </span>
                            </p>
                          </div>
                        )}
                        {message && message.type === "sticker" && (
                          <div
                            key={message._id}
                            className={
                              currentUser._id === message.whatsapp_user_id
                                ? "msg-container user-msg"
                                : "msg-container team-msg"
                            }
                          >
                            <p className="msg">
                              {/* Display sticker as an image */}
                              <img
                                className="msg-img"
                                src={message.message}
                                alt="Sticker"
                              />
                              <span className="msg-timestamp">
                                {new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </span>
                            </p>
                          </div>
                        )}
                        {message && message.type === "document" && (
                          <div
                            key={message._id}
                            className={
                              currentUser._id === message.whatsapp_user_id
                                ? "msg-container user-msg"
                                : "msg-container team-msg"
                            }
                          >
                            <p className="msg">
                              {/* Extract file extension from the link */}
                              {message.message && (
                                <a
                                  href={message.message}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View {message.message.slice(-3).toUpperCase()}{" "}
                                  {/* Display file extension in uppercase */}
                                </a>
                              )}
                              <span className="msg-timestamp">
                                {new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </span>
                            </p>
                          </div>
                        )}
                        {message && message.type === "audio" && (
                          <div
                            key={message._id}
                            className={
                              currentUser._id === message.whatsapp_user_id
                                ? "msg-container user-msg"
                                : "msg-container team-msg"
                            }
                          >
                            <p className="msg">
                              {/* Display audio player */}
                              {message.message && (
                                <audio controls>
                                  <source
                                    src={message.message}
                                    type="audio/mpeg"
                                  />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              )}
                              <span className="msg-timestamp">
                                {new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div ref={!scrolledDown ? messagesEndRef : null} />
                </section>

                {!currentUser._id && (
                  <div className="initial-msg-screen">
                    <img
                      alt="chat-screen"
                      src={require("../../assets/img/chat.png")}
                      className="chat-image mx-2"
                    />
                    <h4>No chat Selected</h4>
                  </div>
                )}
              </Card.Body>

              <Card.Footer className="">
                <section className="whatsapp-send-message-section">
                  <TextField
                    className="my-0"
                    // label="Search"
                    size="small"
                    multiline
                    rows={3}
                    placeholder="Send message"
                    fullWidth
                    variant="outlined"
                    disabled={!currentUser._id}
                    value={typedMessage}
                    onChange={handleMessageChange}
                    onKeyDown={(e) =>
                      handleSendMessageOnEnter(e, { user_id: currentUser._id })
                    }
                  />
                  <IconButton
                    onClick={() => {
                      handleSendMessage({ user_id: currentUser._id });
                    }}
                    disabled={!currentUser._id || !typedMessage.length > 0}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </IconButton>

                  <ReactMediaRecorder
                    audio
                    type="audio/wav"
                    render={({
                      status,
                      startRecording,
                      stopRecording,
                      mediaBlobUrl,
                    }) => (
                      <div>
                        {status === "stopped" &&
                          uploadingAudio &&
                          setTimeout(() => {
                            const xhr = new XMLHttpRequest();
                            xhr.open("GET", mediaBlobUrl, true);
                            xhr.responseType = "blob";
                            xhr.onload = function () {
                              if (xhr.status === 200) {
                                const blob = xhr.response;
                                const formData = new FormData();
                                formData.append("message", blob, "audio.wav");
                                formData.append("from", phone);
                                formData.append("type", "audio");

                                sendMediaMessageToUser(formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                })
                                  .then((response) => {
                                    setHasMore(true);
                                    setScrolledDown(false);
                                    getUserMessages({
                                      user_id: currentUser._id,
                                      offset: 0,
                                    })
                                      .then((response) => {
                                        setMessages(response.data.messages);
                                      })
                                      .catch((error) => {
                                        console.log(error.message);
                                      });
                                    setSelectedFile(null);
                                  })
                                  .catch((error) => {
                                    console.error(
                                      "Error uploading file:",
                                      error
                                    );
                                  });
                              }
                            };

                            xhr.send();
                            setUploadingAudio(false);
                          }, 100)}
                        {!recording ? (
                          <IconButton
                            style={{ marginLeft: "10px" }}
                            disabled={!currentUser._id}
                            onClick={() => {
                              const startAudio = new Audio(
                                require("../../assets/audio/start.mp3")
                              );
                              startAudio.play();
                              setRecording(true);
                              startRecording();
                            }}
                          >
                            <KeyboardVoiceIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{ marginLeft: "10px", color: "red" }}
                            disabled={!currentUser._id}
                            onClick={() => {
                              const stopAudio = new Audio(
                                require("../../assets/audio/stop.mp3")
                              );
                              stopAudio.play();
                              stopRecording();
                              setRecording(false);
                              setUploadingAudio(true);
                            }}
                          >
                            <GraphicEqIcon />
                          </IconButton>
                        )}
                        {/* <video src={mediaBlobUrl} controls /> */}
                      </div>
                    )}
                  />
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: "none" }}
                    disabled={!currentUser._id}
                    accept="*"
                    onChange={handleFileChange}
                  />
                  <IconButton
                    style={{ marginLeft: "10px" }}
                    onClick={handleAttachMedia}
                  >
                    <AttachmentIcon />
                  </IconButton>
                </section>
              </Card.Footer>
            </Card>
          </Grid>
        </Grid>
      </div>
    </section>
  );

  function ResolveWhatsappPopup() {
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to mark this query as <b>Resolved</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="primary"
              onClick={() => {
                changeStatus({
                  user_id: currentUser?._id,
                  status: "DONE",
                }).then(() => {
                  getUsers().then((response) => {
                    setUsers(response?.data?.users);

                    const refreshedCurrentUser = response?.data?.users.find(
                      (e) => e._id === currentUser._id
                    );
                    setCurrentUser(refreshedCurrentUser);
                    handleClose();
                  });
                });
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default WhatsappQuery;
