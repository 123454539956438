import React, { useCallback, useContext, useEffect, useState } from "react";
import { getAssessmentList, getQuestions } from "../../utils/QuestionnaireAPI";
import MainContext from "../../context/MainContext";
import ReactFlow, {
  Controls,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType,
  Background,
  // ConnectionMode,
  // useReactFlow,
  // useStoreApi,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import preloader from "../../assets/img/preloader-5.gif";
import "./QuestionnaireFlow.scss";
import CustomEdgeLabel from "./CustomEdgeLabel";
import {
  faCaretRight,
  faCircleQuestion,
  faClipboard,
  faClose,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButtons from "./CustomButtons";
import CustomNode from "./CustomNode";
import QuestionnaireContext from "../../context/QuestionnaireContext";

const position = { x: 0, y: 100 };

const edgeTypes = {
  custom: CustomEdgeLabel,
};

const nodeTypes = {
  customNode: CustomNode,
};

const QuestionnaireFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [assessmentID, setAssessmentID] = useState("");
  const [currentViewID, setCurrentViewID] = useState("");

  const { ShowNotification, setIsPreLoading } = useContext(MainContext);

  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [assessmentList, setAssessmentList] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  //   useEffect(() => { fetchData(62); }, []);

  const fetchAssessmentList = async () => {
    try {
      setIsPreLoading(true);
      const getAssessmentResponse = await getAssessmentList();
      const responseData = getAssessmentResponse?.data?.ha_assessments || [];
      setAssessmentList(responseData);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.data?.errorMessage);
    } finally {
      setIsPreLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessmentList();
  }, []);

  const hanldleAssessmentChange = (event, newValue) => {
    setAssessmentID(newValue?.id);
    setSelectedAssessment(newValue);
  };

  const fetchData = async (assessmentId) => {
    setIsLoading(true);
    try {
      let requestData = {
        assessment_id: assessmentId,
      };
      const getQuestionsResponse = await getQuestions(requestData);

      const data = getQuestionsResponse?.data?.ha_questions || [];
      setAssessmentQuestions(data);

      if (data?.length) {
        const initialNodes = data
          // ?.filter((question) => !question.is_first_question)
          .map((question) => ({
            id: question.id,
            position,
            question: question.value,
            type: "customNode",
            data: {
              label: question.value,
              question: question,
            },
          }));

        initialNodes.push({
          id: "99999",
          position,
          type: "customNode",
          question: "end",
          data: {
            label: "End",
          },
        });

        // setNodes(initialNodes)

        const initialEdges = [];
        data.forEach((question) => {
          if (
            question?.default_next_question_id &&
            (question?.tag === null ||
              (question?.tag &&
                question?.answers?.[0].next_question_id === null))
          ) {
            initialEdges.push({
              id: `e${question.id}-${question?.default_next_question_id}`,
              source: question.id,
              target: question?.default_next_question_id,
              // type: "straight",
              type: "custom",
              animated: true,
            });
          } else {
            question?.answers?.forEach((obj) => {
              if (obj?.next_question_id) {
                const index = initialEdges.findIndex(
                  (edge) =>
                    edge.source === question?.id &&
                    edge.target === obj?.next_question_id
                );

                if (index !== -1) {
                  initialEdges[index].label += `, ${obj?.value}`;
                } else {
                  initialEdges.push({
                    id: `e${obj.id}-${obj?.next_question_id}`,
                    source: question?.id,
                    target: obj?.next_question_id,
                    // type: "straight",
                    type: "custom",
                    label: obj?.value,
                    animated: true,
                  });
                }
              } else {
                const index = initialEdges.findIndex(
                  (edge) =>
                    edge.source === question?.id && edge.target === "99999"
                );

                if (index !== -1) {
                  initialEdges[index].label += `; ${obj?.value}`;
                } else {
                  initialEdges.push({
                    id: `e${obj.id}-${obj?.next_question_id}`,
                    source: question?.id,
                    target: "99999",
                    animated: true,
                    label: question?.type !== "text" ? obj?.value : null,
                    type: "custom",
                    // type: "straight",
                    markerEnd: {
                      type: MarkerType.ArrowClosed,
                      width: 20,
                      height: 20,
                      color: "#FF0072",
                    },
                    style: {
                      strokeWidth: 2,
                      stroke: "#FF0072",
                    },
                  });
                }
              }
            });
          }
        });
        // setEdges(initialEdges);

        getLayoutedElements(initialNodes, initialEdges);
        setIsQuestionsFound(false);
      } else {
        setIsQuestionsFound(true);
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.data?.errorMessage);
      setIsQuestionsFound(true);
    } finally {
      setIsLoading(false);
      setCurrentViewID(assessmentId);
    }
  };

  /*  */
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 250;
  const nodeHeight = 350;

  const getLayoutedElements = (nodes, edges) => {
    const direction = "TB";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, {
        width: nodeWidth + 200,
        height: nodeHeight,
      });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);
    // const edgeHeight = 0;

    nodes.forEach((node, index) => {
      if (node.id === "99999") {
        node.targetPosition = "top";
        node.sourcePosition = "top";
      } else {
        node.targetPosition = index === 0 ? "bottom" : "top";
        node.sourcePosition = "bottom";
      }

      const nodeWithPosition = dagreGraph.node(node.id);
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      // const filterCount = edges.filter((edge) => edge.source === node?.id);
      return node;
    });
    setNodes(nodes);
    setEdges(edges);

    return { nodes, edges };
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          [eds]
        )
      ),
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isQuestionsFound, setIsQuestionsFound] = useState(false);

  const Flowloader = () => {
    return (
      <div className="Flowloader-section">
        <img src={preloader} alt="Loading..." />
      </div>
    );
  };
  const { isQuestionnaireShow, setIsQuestionnaireShow, selectedQuestion } =
    useContext(QuestionnaireContext);

  const NextQuestionText = ({ next_question_id }) => {
    const nextQuestion = assessmentQuestions?.find(
      (question) => question?.id === next_question_id
    );

    return (
      <>{nextQuestion && `[${nextQuestion.id}] - ${nextQuestion.value} `}</>
    );
  };

  return (
    <div className="questionnaire-flow">
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" />
        Questionnaire Visual Flow
      </section>

      <div className="my-3">
        <Grid container>
          <Grid item md={6}>
            <div className="chart-deatils text-start">
              {currentViewID && (
                <div className="assessment-info-section">
                  <h4>
                    <FontAwesomeIcon icon={faClipboard} className="mx-1" />
                    Assessment Info:
                  </h4>
                  <div>
                    <p>
                      {selectedAssessment?.name} [ID:{selectedAssessment?.id}]
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="d-flex">
              <Autocomplete
                options={assessmentList}
                getOptionLabel={(option) => `${option.name} (${option.id})`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={selectedAssessment}
                onChange={hanldleAssessmentChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Assessment ID"
                    variant="outlined"
                    size="small"
                  />
                )}
                className="assessment-search"
              />
              <Button
                variant="contained"
                disabled={!assessmentID}
                className="assessment-button"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  fetchData(assessmentID);
                  setIsQuestionnaireShow(false);
                }}
              >
                Get Flow
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={2}>
        {/* <Grid item md={12}></Grid> */}
        <Grid item md={12} sm={12} xs={12}>
          <div className="d-flex">
            <div
              className={`flow-diagram-section ${
                isQuestionnaireShow ? "expanded" : ""
              }`}
            >
              {isLoading && <Flowloader />}

              {!isLoading && isQuestionsFound && (
                <div>
                  <img
                    src={require("../../assets/img/no-data-found.png")}
                    alt=""
                    className="access-denied-banner"
                  />
                </div>
              )}

              {!isLoading && !isQuestionsFound && (
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  zoomOnScroll={false}
                  connectionLineType={ConnectionLineType.SmoothStep}
                  nodesConnectable={false}
                  nodesFocusable={false}
                  snapToGrid={true}
                  panOnScroll={true}
                  panOnScrollMode={"free"}
                  elevateNodesOnSelect={true}
                  zoomOnDoubleClick={true}
                  // fitView
                  attributionPosition="top-right"
                  connectionMode={"strict"}
                  edgeTypes={edgeTypes}
                  nodeTypes={nodeTypes}
                  nodesDraggable={true}
                >
                  <Controls showInteractive={false} />
                  <CustomButtons />
                  <Background />
                </ReactFlow>
              )}
            </div>
            <section
              className={`question-view ${
                isQuestionnaireShow ? "expanded ScrollDesign" : ""
              }`}
            >
              <div
                className={`node-item ${
                  selectedQuestion?.required
                    ? "required-node"
                    : "non-required-node"
                } `}
                style={{ position: "relative" }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  style={{
                    position: "absolute",
                    right: "10",
                    top: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsQuestionnaireShow(false);
                  }}
                />
                <p className="question-header">
                  <FontAwesomeIcon icon={faCircleQuestion} />
                  Question
                </p>
                <span className="question">{selectedQuestion?.value}</span>
                <div className="d-flex justify-content-between my-2">
                  <p className="m-0">
                    {"Input Type: "}{" "}
                    <span>{selectedQuestion?.input_element}</span>
                  </p>
                  <p className="m-0">
                    {"Type: "}
                    <span>{selectedQuestion?.type}</span>
                  </p>
                </div>

                {selectedQuestion?.tag && (
                  <div className="text-start questionnaire-tags">
                    <p className="mb-1">{"Tags: "}</p>
                    <div className="tag-list">
                      {selectedQuestion?.tag?.map((tag, index) => (
                        <span key={index} className="tag-item">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-between my-2">
                  <p
                    className={`m-0 ${
                      selectedQuestion?.required ? "required" : "not-required"
                    }`}
                  >
                    {selectedQuestion?.required ? "Required *" : "Not Required"}
                  </p>
                </div>
                {selectedQuestion?.default_next_question_id && (
                  <div className="text-start my-2">
                    <b>Next Question: </b>
                    <NextQuestionText
                      next_question_id={
                        selectedQuestion?.default_next_question_id
                      }
                    />
                  </div>
                )}
                <div className="options">
                  <span>Options:</span>
                  <div className="ScrollDesign listoptions">
                    {selectedQuestion?.answers?.map((answer, index) => (
                      <React.Fragment key={index}>
                        {answer?.value.includes("http") ? (
                          <div className="image-container">
                            <img
                              src={answer?.value}
                              alt="image"
                              style={{ maxHeight: "65px" }}
                            />
                          </div>
                        ) : (
                          <>
                            <p>
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                className="option-icon"
                              />
                              {answer?.value}
                            </p>
                            {answer?.next_question_id && (
                              <>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "2rem",
                                  }}
                                >
                                  {"Next Qn: "}
                                  <NextQuestionText
                                    next_question_id={answer?.next_question_id}
                                  />
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuestionnaireFlow;
