import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;
const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      client_id: REACT_APP_CLIENT_ID,
      access_token: cookies.get("access_token"),
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};

const notificationApi = createAxiosInstance(REACT_APP_BASE_URL);

const applyAccessTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (req) => {
      const token = cookies.get("access_token");
      if (token) {
        req.headers["access_token"] = token; // Set Access Token
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  apiInstance.interceptors.response.use(
    (req) => {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

applyAccessTokenInterceptor(notificationApi);

export const sendManualNotifications = (requestData) =>
  new Promise((resolve, reject) => {
    notificationApi
      .post(`/v3/int/human-token/manual/notification`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.log(error);
        reject(error.response?.data);
      });
  });

export const getManualNotificationList = (requestData) =>
  new Promise((resolve, reject) => {
    notificationApi
      .get(`/v3/int/human-token/manual/notification`)
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const addManualNotificationList = (requestData) =>
  new Promise((resolve, reject) => {
    notificationApi
      .post(`/v3/int/human-token/manual/notification/add`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });

export const updateManualNotificationList = (requestData) =>
  new Promise((resolve, reject) => {
    notificationApi
      .put(`/v3/int/human-token/manual/notification`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });

export const gettManualNotificationListUsers = (requestData) =>
  new Promise((resolve, reject) => {
    notificationApi
      .get(`/v3/int/human-token/manual/notification/list-users`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });

export const gettManualNotificationRecent = (requestData) =>
  new Promise((resolve, reject) => {
    notificationApi
      .get(`/v3/int/human-token/manual/notification/recent`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });

export const gettManualNotificationsByUser = (requestData) =>
  new Promise((resolve, reject) => {
    notificationApi
      .post(`/v3/int/human-token/manual/notification/user-notifications`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });

export const sendManualEmail = (requestData) =>
    new Promise((resolve, reject) => {
      notificationApi
        .post(`/v3/int/human-token/manual/notification`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data?.data });
        })
        .catch((error) => {
          console.log(error);
          reject(error.response?.data);
        });
    });


export { notificationApi };
