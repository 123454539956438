import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faClose,
  faFileLines,
  faRefresh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TablePagination,
} from "@mui/material";
import { Card, Button } from "react-bootstrap";

import "./Notifications.scss";
import SendManualNotification from "./SendManualNotification";
import {
  gettManualNotificationListUsers,
  gettManualNotificationRecent,
  gettManualNotificationsByUser,
} from "../../utils/NotificationAPI";
import MainContext from "../../context/MainContext";
import NotificationContext from "../../context/NotificationContext";
import { ClipboardCopy, formatTimestamp } from "../../common/commonFunctions";

const Notifications = () => {
  const [show, setShow] = useState(false);
  const { setIsPreLoading, ShowNotification } = useContext(MainContext);
  const [initComp, setInitComp] = useState(true);
  const [isUserView, setIsUserView] = useState(false);
  const recentChatRef = useRef(null);

  const {
    initRenderManualNotificationList,
    setInitRenderManualNotificationList,
    recentSendNotficationList,
    setRecentSendNotificationList,
    recentSendNotficationUsersList,
    setRecentSendNotficationUsersList,
    sendNotficationByUser,
    setSendNotficationByUser,
    selectedNotificationUser,
    setSelectedNotificationUser,
    manualNotficationRecentParam,
    setManualNotficationRecentParam,
    manualNotficationUserParam,
    setManualNotficationUserParam,
    sendNotificationListCount,
    setSendNotificationListCount,
    manualNotficationUserListParam, setManualNotficationUserListParam
  } = useContext(NotificationContext);

  /*  */
  const prevState = useRef();
  useEffect(() => {
    if (prevState.current !== undefined && prevState.current === selectedNotificationUser) {
      console.log('State is the same as previous state');
    } else {
      setManualNotficationUserParam((prevManualNotficationUserParam) => {
        return {
          ...prevManualNotficationUserParam,
          offset: 0,
          currentPage: 1,
          isChanged: true,
        };
      });
      scrollChatToTop();
    }

    prevState.current = selectedNotificationUser;
  }, [selectedNotificationUser]);
  /*  */

  /* Based on Param */
  useEffect(() => {
    if (!initRenderManualNotificationList && !initComp) {
      getManualNotificationsListUsersData();
      setIsUserView(false)
    }
  }, [manualNotficationUserListParam]);

  useEffect(() => {
    if (!initRenderManualNotificationList && !initComp) {
      gettManualNotificationRecentData();
    }
  }, [manualNotficationRecentParam]);

  useEffect(() => {
    if (!initRenderManualNotificationList && !initComp) {
      if(selectedNotificationUser){
        gettManualNotificationsByUserData(selectedNotificationUser);
      }
    }
  }, [manualNotficationUserParam]);

  const scrollChatToTop = () => {
    recentChatRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    if (initRenderManualNotificationList) {
      getManualNotificationsListUsersData();
      gettManualNotificationRecentData();
      setInitRenderManualNotificationList(false);
    }

    setTimeout(() => {
      setInitComp(false);
    }, 1000);
  }, []);

  const getManualNotificationsListUsersData = async () => {
    setIsPreLoading(true);
    let requestData = {
      search: notificationUserSearch,
    };
    try {
      const gettManualNotificationListUsersResponse =
        await gettManualNotificationListUsers(requestData);
      setRecentSendNotficationUsersList(
        gettManualNotificationListUsersResponse?.data?.ch_notifications
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.errorMessage);
    } finally {
      setIsPreLoading(false);
    }
  };

  const gettManualNotificationRecentData = async () => {
    setIsPreLoading(true);

    let requestData = {
      offset: manualNotficationRecentParam?.offset,
      limit: manualNotficationRecentParam?.limit,
    };

    try {
      const getManualNotificationRecentResponse =
        await gettManualNotificationRecent(requestData);      
      setRecentSendNotificationList(
        getManualNotificationRecentResponse?.data?.ch_notifications
      );

      setSendNotificationListCount(
        getManualNotificationRecentResponse?.data?.total_count
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.errorMessage);
    } finally {
      setIsPreLoading(false);
    }
  };

  const gettManualNotificationsByUserData = async (userId) => {
    setIsPreLoading(true);

    let requestData = {
      offset: manualNotficationUserParam?.offset,
      limit: manualNotficationUserParam?.limit,
      user_id: userId,
    };


    try {
      const getManualNotificationsByUserResponse =
        await gettManualNotificationsByUser(requestData);
      setSendNotficationByUser(
        getManualNotificationsByUserResponse?.data?.ch_notifications
      );

      setSendNotificationListCount(
        getManualNotificationsByUserResponse?.data?.total_count
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.errorMessage);
    } finally {
      setIsPreLoading(false);
    }
  };

  const NotificationItem = ({ notificationObj }) => {
    return (
      <>
        {notificationObj?.map((notification, index) => (
          <div className="sent-item-card" key={index}>
            <Card>
              <div className="sent-message-item">
                <div className="image">
                  <Avatar
                    alt="Login User"
                    src={require("../../assets/img/notification-icon.png")}
                    className="user-avatar"
                  />
                </div>
                <div className="content-block">
                  <div className="title-block">
                    <h4 className="title">{notification?.title}</h4>
                    <small>                     
                      {formatTimestamp(notification?.created_at)}
                    </small>
                  </div>
                  <p className="message">{notification?.body}</p>
                </div>
              </div>
              <span className="sent-info">
                <div>
                  <p>
                    User ID: <b>{notification?.user_id}</b>
                  </p>
                  <span className="notification-id">#{notification?.id}</span>
                  {notification?.is_read && <span className="notification-read-status">Read</span> }
                </div>
                <span className="category">{notification?.sub_type}</span>
              </span>
            </Card>
          </div>
        ))}
      </>
    );
  };

  const scrollEndRef = useRef(null);

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {    
    setPage(newPage);
    const newOffset = (newPage) * manualNotficationUserParam?.limit;

    if (isUserView) {
      setManualNotficationUserParam((prevManualNotficationUserParam) => {
        return {
          ...prevManualNotficationUserParam,
          offset: newOffset,
          isChanged: false,
        };
      });
    } else {
      setManualNotficationRecentParam((prevManualNotficationRecentParam) => {
        return {
          ...prevManualNotficationRecentParam,
          offset: newOffset,
          isChanged: false,
        };
      });
    }
  };

  

  const [notificationUserSearch, setNotificationUserSearch] = useState("")
  const [isSearchParam, setIsSearchParam] = useState(false);

  useEffect(() => {
    if (isSearchParam) {
      setManualNotficationUserListParam((prevState)=>({
        ...prevState,
        search: notificationUserSearch.trim(),
      }))
      setIsSearchParam(false);
    }
  }, [isSearchParam]);

  return (
    <div className="in-app-notifications">
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" /> App
        Notifications
      </section>
      <Grid container spaing={0} className="mb-2">
        <Grid item md={4} xs={6} className="text-end search-section">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            className="kit-search-section"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search User UUID"
              inputProps={{ "aria-label": "Search User" }}
              value={notificationUserSearch}
              onChange={(e) => {
                setNotificationUserSearch(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setIsSearchParam(true);
                }
              }}
            />

            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="py-2 search-clear"
              onClick={() => {
                setNotificationUserSearch("");
                setManualNotficationUserListParam(
                  (prevManualNotficationUserListParam) => ({
                    ...prevManualNotficationUserListParam,
                    search: "",
                  })
                );
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="py-2 food-search-clear"
              onClick={() => {
                setIsSearchParam(true);
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item md={8} xs={6} className="text-end top-action-button-group">
          <div className="ml-auto">
            <Button
              variant="outline-secondary"
              color="secondary"
              className=" mx-1"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {
                setIsUserView(false);
                scrollChatToTop();

                setTimeout(() => {
                  getManualNotificationsListUsersData();

                  setManualNotficationRecentParam(
                    (prevManualNotficationRecentParam) => {
                      return {
                        ...prevManualNotficationRecentParam,
                        offset: 0,
                        currentPage: 1,
                      };
                    }
                  );
                }, 0);
              }}
            >
              <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
              <span>Refresh</span>
            </Button>
            <Button
              variant="outline-primary"
              color="secondary"
              className=" mx-1"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {
                setShow(true);
              }}
            >
              <FontAwesomeIcon icon={faBell} className="mx-1" />
              <span>Send Notification</span>
            </Button>
          </div>
        </Grid>
      </Grid>

      <section>
        <div className="notification-view-block">
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Card>
                <Card.Header className="text-start">UserList</Card.Header>
                <section className="notification-users-list ScrollDesign">
                  {recentSendNotficationUsersList?.map((user, index) => (
                    <div
                      className={`chat-card ${
                        isUserView && selectedNotificationUser === user?.user_id
                          ? "active-chat"
                          : ""
                      }`}
                      key={index}
                      onClick={() => {
                        setIsUserView(true);
                        setPage(0);
                        setSelectedNotificationUser(user?.user_id);
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item md={2} xs={2} className="image">
                          <Avatar
                            alt="Login User"
                            src={require("../../assets/img/avatar-1.png")}
                            className="user-avatar"
                          />
                        </Grid>
                        <Grid item md={10} xs={10}>
                          <div className="chat-content">
                            <p className="user-id">
                              User ID:{" "}
                              <b>
                                {user?.user_id}{" "}
                                <small style={{ float: "right" }}>
                                  ({formatTimestamp(user?.created_at)})
                                </small>
                              </b>
                            </p>
                            {user?.user?.user_id && (
                              <p className="user-id">
                                <small>
                                  [{user?.user?.user_id}]{" "}
                                  <ClipboardCopy value={user?.user?.user_id} />
                                </small>
                              </p>
                            )}
                            <p className="message">{user?.title}</p>
                            <p className="message">{user?.body}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </section>
              </Card>
            </Grid>
            <Grid item md={8}>
              <Card>
                <Card.Header className="text-start d-flex justify-content-between">
                  <div>
                    Recent Sent Notifications{" "}
                    {isUserView &&
                      "[User ID: " + selectedNotificationUser + "]"}
                  </div>

                  <div>
                    <TablePagination
                      component="div"
                      count={sendNotificationListCount} 
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={manualNotficationRecentParam?.limit}
                    />
                  </div>
                </Card.Header>
                <Card.Body
                  className="recent-chat-list ScrollDesign"
                  ref={scrollEndRef}
                  onScroll={() => {
                    // handleScroll();
                  }}
                >
                  <div ref={recentChatRef}></div>
                  {isUserView ? (
                    <NotificationItem notificationObj={sendNotficationByUser} />
                  ) : (
                    <NotificationItem
                      notificationObj={recentSendNotficationList}
                    />
                  )}
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
          <div></div>
        </div>
      </section>

      <SendManualNotification show={show} setShow={setShow} />
    </div>
  );
};

export default Notifications;
