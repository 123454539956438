import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCaretDown,
  faCaretUp,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faClose,
  faCopy,
  faEdit,
  faEye,
  faFileLines,
  faFilter,
  faRefresh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "../token-verification/Verification.scss";
import "./Premium.scss";

import {
  getUserVerificationList,
  getVerificationUser,
} from "../../../utils/VerificationAPI";
import TokenContext from "../../../context/TokenContext";
import MainContext from "../../../context/MainContext";
import PremiumUserInfo from "./PremiumUserInfo";
import {
  getHumanTokenUserData,
  getHumanTokenUsersList,
  registerGenebox,
} from "../../../utils/preTokenApi";
import GeneBoxRegister from "./GeneBoxRegister";
import GeneBoxGutCodeUpdate from "./GeneBoxGutCodeUpdate";

const PremiumUsers = () => {
  const {
    premiumUserList,
    setPremiumUserList,
    userVerificationListTotalCount,
    setUserVerificationListTotalCount,
    initRenderPremium,
    setInitRenderPremium,
    premiumUserListParam,
    setPremiumUserListParam,
  } = useContext(TokenContext);

  const { setIsPreLoading, ShowNotification } = useContext(MainContext);

  const [initComp, setInitComp] = useState(true);

  const [premiumUserSearch, PremiumUserSearch] = useState("");

  const [isSearchParam, setIsSearchParam] = useState(false);

  const [selectedUserinfo, setSelectedUserinfo] = useState([]);
  const [selectedHTUserID, setSelectedHTUserID] = useState("");
  const [selectedGeneboxClientName, setSelectedGeneboxClientName] =
    useState("");
  const [selectedGeneboxGutKitCode, setSelectedGeneboxGutKitCode] =
    useState("");

  const getPremiumUserListData = async () => {
    const requestParam = premiumUserListParam;
    setIsPreLoading(true);
    try {
      const getPremiumUserListResponse = await getHumanTokenUsersList(
        requestParam
      );
      let convertUsersList = getPremiumUserListResponse?.data?.premium_user.map(
        (user, index) => {
          let userobj = user?.user_verification;

          let sampleTrackingStatus = "";
          let sampleStatusColor = "";

          let deviceTrackingStatus = "";
          let deviceStatusColor = "";

          const diagnosticsKitStatus = userobj?.kits?.find(
            (kit) => kit.sample_type === "saliva"
          );
          const DiOrderStatus = userobj?.orders?.find(
            (kit) => kit.product_id === "TGB_SALIVA"
          );
          const deviceKitStatus = userobj?.kits?.find(
            (kit) => kit.product === "device_kit"
          );

          if (DiOrderStatus?.status === "DONE") {
            sampleTrackingStatus = "Pickup Done";
            sampleStatusColor = "success";
          } else if (DiOrderStatus?.status === "COLLECTED") {
            sampleTrackingStatus = "Sample is Collected";
            sampleStatusColor = "secondary";
          } else if (DiOrderStatus?.status === "ASSIGNED") {
            sampleTrackingStatus = "Ready to Pickup";
            sampleStatusColor = "info";
          } else if (
            diagnosticsKitStatus?.order_status === "delivered" &&
            !DiOrderStatus
          ) {
            sampleTrackingStatus = "Sample Pickup Is Not Scheduled";
            sampleStatusColor = "error";
          } else if (diagnosticsKitStatus?.order_status === "delivered") {
            sampleTrackingStatus = "Sample kit Delivered";
            sampleStatusColor = "success";
          } else if (diagnosticsKitStatus?.order_status === "shipped") {
            sampleTrackingStatus = "Sample kit shipped";
            sampleStatusColor = "secondary";
          } else if (diagnosticsKitStatus?.order_status === "processing") {
            sampleTrackingStatus = "Sample kit processing shipment";
            sampleStatusColor = "info";
          } else {
            sampleTrackingStatus = "Processing";
            sampleStatusColor = "info";
          }

          if (deviceKitStatus?.order_status === "delivered") {
            deviceTrackingStatus = "Device kit Delivered";
            deviceStatusColor = "success";
          } else if (deviceKitStatus?.order_status === "shipped") {
            deviceTrackingStatus = "Device kit shipped";
            deviceStatusColor = "secondary";
          } else if (deviceKitStatus?.order_status === "processing") {
            deviceTrackingStatus = "Device kit processing shipment";
            deviceStatusColor = "info";
          } else {
            if (
              diagnosticsKitStatus?.order_status === "delivered" &&
              !userobj?.ring?.ring_size
            ) {
              // deviceTrackingStatus = "Waiting for Ring Size"
              // deviceStatusColor="error"
            } else if (
              diagnosticsKitStatus?.order_status === "delivered" &&
              userobj?.ring?.ring_size &&
              !deviceKitStatus
            ) {
              deviceTrackingStatus = "Device kit not Shipped";
              deviceStatusColor = "primary";
            } else if (
              diagnosticsKitStatus?.order_status === "delivered" &&
              userobj?.ring?.ring_size
            ) {
              deviceTrackingStatus = "Device kit not Shipped";
              deviceStatusColor = "primary";
            }
          }

          return {
            ...user,
            user_verification: {
              ...user?.user_verification,
              sample_status: sampleTrackingStatus,
              sample_color: sampleStatusColor,
              device_status: deviceTrackingStatus,
              device_color: deviceStatusColor,
            },
          };
        }
      );

      setPremiumUserList(convertUsersList);
      setUserVerificationListTotalCount(
        getPremiumUserListResponse?.data?.total_count
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const getVerificationUserData = async (verificationId) => {
    setIsPreLoading(true);
    try {
      const getVerificationUserResponse = await getHumanTokenUserData(
        verificationId
      );

      let convertedData = manipulateData(
        getVerificationUserResponse?.data?.premium_user
      );

      setSelectedUserinfo(convertedData);

      setShow(true);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const manipulateData = (data) => {
    const { kits, orders, ...rest } = data;

    const isFirstKitDelivered = kits
      ? kits?.some(
          (kit) =>
            kit.sample_type === "saliva" && kit.order_status === "delivered"
        )
      : false;

    // const isDeviceKitDelivered = kits?.some(kit => kit.sample_type === "saliva" && kit.order_status === "delivered");
    const restructuredData = {
      ...rest,
      is_first_kit_delivered: isFirstKitDelivered,
      address: kits ? kits[0].address : null,
      kits: kits ? [] : null,
      orders: orders ? [] : null,
    };

    kits?.forEach((kit) => {
      if (kit.sample_type) {
        restructuredData.kits[`${kit.sample_type}_kit`] = kit;
      } else {
        restructuredData.kits["device_kit"] = kit;
      }
    });

    orders?.forEach((order) => {
      if (order?.product_id === "TGB_SALIVA") {
        restructuredData.orders["saliva"] = order;
      } else if (order?.product_id === "TGB_STOOL") {
        restructuredData.orders["stool"] = order;
      }
    });

    return restructuredData;
  };

  //Pagination Change
  const handlePageChange = (page) => {
    const newOffset = (page - 1) * premiumUserListParam?.limit;
    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      offset: newOffset,
    }));
  };

  const handleHeaderRowClick = (keyValue) => {
    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      orderBy: keyValue,
      order: prevVerificationListParam.order === "ASC" ? "DESC" : "ASC",
    }));
  };

  //Rows Count Change
  const handleRowsPerPageChange = (newPerPage) => {
    if (!initRenderPremium) {
      if (newPerPage !== premiumUserListParam?.limit) {
        setPremiumUserListParam((prevVerificationListParam) => ({
          ...prevVerificationListParam,
          limit: newPerPage,
        }));
      }
    }
  };

  /* Based on Param */
  useEffect(() => {
    if (!initRenderPremium && !initComp) {
      getPremiumUserListData();
    }
  }, [premiumUserListParam]);

  useEffect(() => {
    if (initRenderPremium) {
      getPremiumUserListData();
      setInitRenderPremium(false);
    }

    setTimeout(() => {
      setInitComp(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isSearchParam) {
      setPremiumUserListParam((prevState) => ({
        ...prevState,
        search: premiumUserSearch.trim(),
        offset: 0,
      }));
      setIsSearchParam(false);
    }
  }, [isSearchParam]);

  const ClipboardCopy = ({ value }) => {
    const [copyText, setCopyText] = useState("Click to copy");

    return (
      <Tooltip title={copyText}>
        <FontAwesomeIcon
          icon={faCopy}
          className="mx-2"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            navigator.clipboard.writeText(value);
            setCopyText("Copied!");
            setTimeout(() => {
              setCopyText("Click to copy!");
            }, 2000);
          }}
        />
      </Tooltip>
    );
  };

  const premiumUserColumns = [
    {
      // name: "ID",
      name: (
        <div
          className="tHead d-flex justify-content-center"
          onClick={() => handleHeaderRowClick("id")}
        >
          {"ID"}
          <span className="mx-1 id">
            <FontAwesomeIcon icon={faCaretUp} className="ASC" />
            <FontAwesomeIcon icon={faCaretDown} className="DESC" />
          </span>
        </div>
      ),
      selector: (row) => parseInt(row?.user_verification?.id),
      // minWidth: "5%",
    },
    {
      name: "Customer Id",
      selector: (row) => row?.user_verification?.customer_id,
      minWidth: "120px",
      cell: (row) => (
        <div className="text-start">
          {row.user_verification?.customer_id ? (
            <p className="mb-0 white-space-no-wrap">
              <b>
                {row.user_verification?.customer_id?.slice(0, 8)}
                {"..."}
                <ClipboardCopy value={row.user_verification?.customer_id} />
              </b>
            </p>
          ) : (
            <p className="text-center">--</p>
          )}
        </div>
      ),
    },
    {
      // name:"Gene test kit barcode",
      name: (
        <>
          Gene test
          <br />
          kit barcode
        </>
      ),
      selector: (row) => row.user_verification?.genebox_client_username,
      minWidth: "150px",
      cell: (row) => (
        <div className="text-start">
          {row?.user_verification?.genebox_client_username && (
            <>
              <p className="mb-0 white-space-no-wrap">
                <b>
                  {row?.user_verification?.genebox_client_username}

                  {!row?.user_verification?.genebox_user_id && (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="mx-1"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => {
                        setSelectedHTUserID(row.user_verification?.id);
                        setSelectedGeneboxClientName(
                          row?.user_verification?.genebox_client_username
                        );
                        setGeneboxShow(true);
                      }}
                    />
                  )}
                </b>
              </p>
            </>
          )}

          {!row.user_verification?.customer_id &&
            !row?.user_verification?.genebox_client_username &&
            "--"}

          {row.user_verification?.customer_id &&
            !row?.user_verification?.genebox_client_username && (
              <Chip
                variant="contained"
                label={
                  <>
                    <FontAwesomeIcon icon={faArrowsRotate} className="mx-1" />
                    Update
                  </>
                }
                className="sync-reg-button"
                onClick={() => {
                  setSelectedHTUserID(row.user_verification?.id);
                  setGeneboxShow(true);
                }}
              />
            )}
        </div>
      ),
    },
    {
      name: (
        <>
          GeneBox
          <br />
          UserId
        </>
      ),
      selector: (row) => row.user_verification?.genebox_user_id,
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-start">
          {row?.user_verification?.genebox_user_id && (
            <>
              <p className="mb-0">
                <b>{row?.user_verification?.genebox_user_id}</b>
              </p>
            </>
          )}

          {!row.user_verification?.customer_id && "--"}

          {row.user_verification?.customer_id &&
            !row?.user_verification?.genebox_user_id && (
              <Chip
                variant="filled"
                label={
                  <>
                    <FontAwesomeIcon icon={faArrowsRotate} className="mx-1" />
                    Register
                  </>
                }
                className="sync-button"
                onClick={() => {
                  handleRegisterGenebox(row?.user_verification?.id);
                }}
              />
            )}
        </div>
      ),
    },
    {
      // name:"Gene test kit barcode",
      name: (
        <>
          GeneBox
          <br />
          Gut kit code
        </>
      ),
      selector: (row) => row.user_verification?.genebox_gut_kit_code,
      minWidth: "150px",
      cell: (row) => (
        <div className="text-start">
          {row?.user_verification?.genebox_gut_kit_code && (
            <>
              <p className="mb-0 white-space-no-wrap">
                <b>
                  {row?.user_verification?.genebox_gut_kit_code}

                  {!row?.user_verification?.genebox_user_id && (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="mx-1"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => {
                        setSelectedHTUserID(row.user_verification?.id);
                        setSelectedGeneboxGutKitCode(
                          row?.user_verification?.genebox_gut_kit_code
                        );
                        setGeneboxGutShow(true);
                      }}
                    />
                  )}
                </b>
              </p>
            </>
          )}

          {!row.user_verification?.customer_id &&
            !row?.user_verification?.genebox_gut_kit_code &&
            "--"}

          {row.user_verification?.customer_id &&
            !row?.user_verification?.genebox_gut_kit_code && (
              <Chip
                variant="contained"
                label={
                  <>
                    <FontAwesomeIcon icon={faArrowsRotate} className="mx-1" />
                    Update
                  </>
                }
                className="sync-reg-button"
                onClick={() => {
                  setSelectedHTUserID(row.user_verification?.id);
                  setGeneboxGutShow(true);
                }}
              />
            )}
        </div>
      ),
    },
    {
      name: (
        <>
          Ring
          <br />
          Info
        </>
      ),
      selector: (row) => row.user_verification?.ring?.ring_size || "-",
      minWidth: "130px",
      cell: (row) => (
        <div className="text-start">
          {row?.user_verification?.ring?.ring_size ? (
            <>
              <p className="mb-2">
                Size: <b>{row.user_verification?.ring?.ring_size}</b>
              </p>
              <p className="mb-2">
                Color: <b>{row.user_verification?.ring?.ring_color}</b>
              </p>
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: <>Is Eligible</>,
      selector: (row) => row.user_verification?.is_eligible,
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-center elegible-status">
          <Chip
            variant="filled"
            label={
              row.user_verification?.is_eligible === null
                ? "Not Yet"
                : row.user_verification?.is_eligible
                ? "Eligible"
                : "Not Eligible"
            }
            className={
              row.user_verification?.is_eligible === null
                ? "Not-Yet"
                : row.user_verification?.is_eligible
                ? "Eligible"
                : "Not-Eligible"
            }
          />
        </div>
      ),
    },
    {
      name: (
        <>
          User
          <br />
          Logged In
        </>
      ),
      selector: (row) => row.user_verification?.user_id || "-",
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-start user-present">
          <Chip
            variant="filled"
            label={row.user_verification?.user_id ? "True" : "False"}
            className={row.user_verification?.user_id ? "True" : "False"}
          />
        </div>
      ),
    },
    {
      name: (
        <>
          Gut
          <br />
          Questionnaire
        </>
      ),
      selector: (row) => row.user_verification?.ring?.ring_size || "-",
      minWidth: "130px",
      cell: (row) => (
        <div className="text-start gut-questionnaire-status">
          <Chip
            variant="filled"
            label={
              row.user_verification?.questionnaire === "complete"
                ? "Complete"
                : row.user_verification?.questionnaire === "incomplete"
                ? "Incomplete"
                : "Not Attended"
            }
            className={
              row.user_verification?.questionnaire === "complete"
                ? "Complete"
                : row.user_verification?.questionnaire === "incomplete"
                ? "Incomplete"
                : "Not-Attended"
            }
          />
        </div>
      ),
    },
    {
      name: (
        <>
          Test Kit
          <br />
          Status
        </>
      ),
      selector: (row) => row.id,
      minWidth: "275px",
      cell: (row) => (
        <div className="kit-status">
          {row?.user_verification?.sample_status && (
            <Chip
              variant="outlined"
              className="mb-2"
              label={row?.user_verification?.sample_status}
              color={row?.user_verification?.sample_color}
              // color="success"
            />
          )}
        </div>
      ),
    },
    {
      name: (
        <>
          Device Kit
          <br />
          Status
        </>
      ),
      selector: (row) => row.id,
      minWidth: "275px",
      cell: (row) => (
        <div className="kit-status">
          {row?.user_verification?.device_status && (
            <Chip
              variant="outlined"
              label={row?.user_verification?.device_status}
              color={row?.user_verification?.device_color}
            />
          )}
        </div>
      ),
    },
    {
      name: "View",
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-center">
          <Button
            variant="outline-success"
            color="primary"
            size="sm"
            style={{
              textTransform: "none",
              borderRadius: "5px",
            }}
            onClick={() => {
              getVerificationUserData(row?.user_verification?.id);
            }}
          >
            <FontAwesomeIcon icon={faEye} className="mx-1" />
            View
          </Button>
        </div>
      ),
    },
  ];

  const handleRegisterGenebox = async (verificationId) => {
    setIsPreLoading(true);
    try {
      const requestData = {
        ht_user_verification_id: verificationId.toString(),
      };

      const registerGeneboxResponse = await registerGenebox(requestData);
      getPremiumUserListData();
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const [show, setShow] = useState(false);

  /* Filter  */
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  //Filter Section
  const [filterRingSize, setFilterRingSize] = useState([]);
  const [filterRingColor, setFilterRingColor] = useState([]);
  const [filterIsUserLogged, setFilterIsUserLogged] = useState(null);
  const [filterIsEligible, setFilterIsEligible] = useState(null);
  const [filterIsQuestionnaireCompleted, setFilterIsQuestionnaireCompleted] =
    useState("");
  const [filterReadyToPickup, setFilterReadyToPickup] = useState(null);

  const handleRingSizeFilter = (event, value) => {
    const newRingSize = value.includes("all") ? [] : value;
    setFilterRingSize(newRingSize);

    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      ring_size: newRingSize?.length ? newRingSize.join(",") : null,
    }));
  };

  const handleRingColorFilter = (event, value) => {
    const newRingColor = value.includes("all") ? [] : value;

    setFilterRingColor(newRingColor);
    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      ring_color: newRingColor?.length ? newRingColor.join(",") : null,
    }));
  };

  const handleUserLoggedFilter = (event, value) => {
    setFilterIsUserLogged(value);
    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      user_logged_in: value !== "" || value !== null ? value : null,
    }));
  };

  const handleEligibleFilter = (event, value) => {
    setFilterIsEligible(value);
    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      is_eligible: value !== "" || value !== null ? value : null,
    }));
  };

  const handleQuestionnaireFilter = (event, value) => {
    setFilterIsQuestionnaireCompleted(value);
    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      questionnaire_status: value !== "" ? value : "",
    }));
  };

  const handleReadyToPickupFilter = (event, value) => {
    setFilterReadyToPickup(value);
    setPremiumUserListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      pickup: value !== "" ? value : null,
    }));
  };

  const handleClearFilter = () => {
    if (isExpanded) {
      setFilterRingSize([]);
      setFilterRingColor([]);
      setFilterIsUserLogged(null);
      setFilterIsEligible(null);
      setFilterIsQuestionnaireCompleted("");
      setFilterReadyToPickup(null);
      setPremiumUserListParam((prevVerificationListParam) => ({
        ...prevVerificationListParam,
        is_paid: "",
        is_verified: "",
        ring_size: null,
        ring_color: null,
        is_eligible: null,
        user_logged_in: null,
        questionnaire_status: null,
      }));
    }
  };

  /*  */

  const [geneboxShow, setGeneboxShow] = useState(false);
  const [geneboxGutShow, setGeneboxGutShow] = useState(false);

  return (
    <div className="user-verification premium-user-section">
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" /> Paid Users
      </section>
      <div>
        <Grid container spaing={2} className="mb-2">
          <Grid item md={4} xs={6} className="text-end search-section">
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              className="kit-search-section"
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search User"
                inputProps={{ "aria-label": "Search User" }}
                value={premiumUserSearch}
                onChange={(e) => {
                  PremiumUserSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setIsSearchParam(true);
                  }
                }}
              />

              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                className="py-2 search-clear"
                onClick={() => {
                  PremiumUserSearch("");
                  setPremiumUserListParam((prevVerificationListParam) => ({
                    ...prevVerificationListParam,
                    search: "",
                    offset: 0,
                  }));
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                className="py-2 food-search-clear"
                onClick={() => {
                  setIsSearchParam(true);
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item md={8} xs={6} className="text-end top-action-button-group">
            <div className="ml-auto">
              <Button
                variant="outline-secondary"
                color="secondary"
                className=" mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  getPremiumUserListData();
                }}
              >
                <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
                <span>Refresh</span>
              </Button>
              <Button
                variant="outline-primary"
                color="secondary"
                className="mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={toggleExpandCollapse}
              >
                <FontAwesomeIcon icon={faFilter} className="mx-1" />{" "}
                <span>Filter</span>
              </Button>
            </div>
          </Grid>
        </Grid>

        <div className="premium-filter-container">
          <div className={`content ${isExpanded ? "expanded" : "collapsed"}`}>
            <div className="filter-section">
              <Grid container>
                <Grid item md={4} sm={4} xs={12}>
                  <section className="filter-item">
                    <div className="title">Gut Questionnaire Status</div>
                    <ToggleButtonGroup
                      color="secondary"
                      exclusive
                      value={filterIsQuestionnaireCompleted}
                      onChange={handleQuestionnaireFilter}
                      aria-label="text isPayed"
                      size="small"
                      // className="mx-2"
                    >
                      <ToggleButton value={""} aria-label="All">
                        All
                      </ToggleButton>
                      <ToggleButton
                        value={"not_attended"}
                        aria-label="not-attended"
                      >
                        Not Attended
                      </ToggleButton>
                      <ToggleButton value={"completed"} aria-label="completed">
                        Completed
                      </ToggleButton>
                      <ToggleButton
                        value={"not_completed"}
                        aria-label="not-completed"
                      >
                        Not Completed
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </section>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <section className="filter-item">
                    <div className="title">Eligible</div>
                    <ToggleButtonGroup
                      color="secondary"
                      exclusive
                      value={filterIsEligible}
                      onChange={handleEligibleFilter}
                      aria-label="eligible filter"
                      size="small"
                      // className="mx-2"
                    >
                      <ToggleButton value={"" || null} aria-label="All">
                        All
                      </ToggleButton>
                      <ToggleButton value={"not_yet"} aria-label="verified">
                        Not Yet
                      </ToggleButton>
                      <ToggleButton value={true} aria-label="verified">
                        Eligible
                      </ToggleButton>
                      <ToggleButton value={false} aria-label="not-verified">
                        Not-Eligible
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </section>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <section className="filter-item">
                    <div className="title">Pickup</div>
                    <ToggleButtonGroup
                      color="secondary"
                      exclusive
                      value={filterReadyToPickup}
                      onChange={handleReadyToPickupFilter}
                      aria-label="text alignment"
                      size="small"
                      // className="mx-2"
                    >
                      <ToggleButton value={"" || null} aria-label="All">
                        All
                      </ToggleButton>
                      <ToggleButton value={true} aria-label="verified">
                        Ready to pickup
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </section>
                </Grid>

                <Grid item md={4} sm={4} xs={12}>
                  <section className="filter-item">
                    <div className="title">Ring Size</div>
                    <ToggleButtonGroup
                      color="secondary"
                      // exclusive
                      value={filterRingSize}
                      onChange={handleRingSizeFilter}
                      aria-label="text alignment"
                      size="small"
                      // className="mx-2"
                    >
                      <ToggleButton
                        value={"all"}
                        aria-label="all"
                        className={
                          filterRingSize.length !== 0 ? "" : "all-select-toogle"
                        }
                      >
                        All
                      </ToggleButton>
                      <ToggleButton value="6" aria-label="6">
                        6
                      </ToggleButton>
                      <ToggleButton value="7" aria-label="7">
                        7
                      </ToggleButton>
                      <ToggleButton value="8" aria-label="8">
                        8
                      </ToggleButton>
                      <ToggleButton value="9" aria-label="9">
                        9
                      </ToggleButton>
                      <ToggleButton value="10" aria-label="10">
                        10
                      </ToggleButton>
                      <ToggleButton value="11" aria-label="11">
                        11
                      </ToggleButton>
                      <ToggleButton value="12" aria-label="12">
                        12
                      </ToggleButton>
                      <ToggleButton value="13" aria-label="13">
                        13
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </section>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <section className="filter-item">
                    <div className="title">Ring Color</div>
                    <ToggleButtonGroup
                      color="secondary"
                      // exclusive
                      value={filterRingColor}
                      onChange={handleRingColorFilter}
                      aria-label="text alignment"
                      size="small"
                      // className="mx-2"
                    >
                      <ToggleButton
                        value={"all"}
                        aria-label="all"
                        className={
                          filterRingColor.length !== 0
                            ? ""
                            : "all-select-toogle"
                        }
                      >
                        All
                      </ToggleButton>
                      <ToggleButton value={"black"} aria-label="black">
                        Black
                      </ToggleButton>
                      <ToggleButton value={"silver"} aria-label="silver">
                        Silver
                      </ToggleButton>
                      <ToggleButton value={"gold"} aria-label="gold">
                        Gold
                      </ToggleButton>
                      <ToggleButton value={"rose_gold"} aria-label="rose_gold">
                        Rose Gold
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </section>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <section className="filter-item">
                    <div className="title">User Logged In</div>
                    <ToggleButtonGroup
                      color="secondary"
                      exclusive
                      value={filterIsUserLogged}
                      onChange={handleUserLoggedFilter}
                      aria-label="text alignment"
                      size="small"
                      // className="mx-2"
                    >
                      <ToggleButton value={"" || null} aria-label="All">
                        All
                      </ToggleButton>
                      <ToggleButton value={true} aria-label="true">
                        True
                      </ToggleButton>
                      <ToggleButton value={false} aria-label="false">
                        False
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </section>
                </Grid>
              </Grid>
            </div>

            <Button
              variant="outline-danger"
              color="primary"
              size="sm"
              onClick={handleClearFilter}
              className="clear-filter-button"
            >
              <FontAwesomeIcon icon={faClose} className="mx-1" /> Clear Filter
            </Button>
          </div>
        </div>

        <div
          className={`dt-table ${premiumUserListParam?.orderBy} ${premiumUserListParam?.order}`}
        >
          <DataTable
            columns={premiumUserColumns}
            striped
            data={premiumUserList}
            className="dt-user-list dh-table-basic"
            pagination
            paginationPerPage={premiumUserListParam?.limit}
            paginationServer
            paginationTotalRows={userVerificationListTotalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            searchable
          />
        </div>
      </div>

      <PremiumUserInfo
        show={show}
        setShow={setShow}
        selectedUserinfo={selectedUserinfo}
        setSelectedUserinfo={setSelectedUserinfo}
        getPremiumUserListData={getPremiumUserListData}
        getVerificationUserData={getVerificationUserData}
      />

      {!geneboxShow ? null : (
        <GeneBoxRegister
          show={geneboxShow}
          setShow={setGeneboxShow}
          userVerificationId={selectedHTUserID}
          getPremiumUserListData={getPremiumUserListData}
          setSelectedHTUserID={setSelectedHTUserID}
          selelectedGeneboxClientName={setSelectedGeneboxClientName}
          selectedGeneboxClientName={selectedGeneboxClientName}
        />
      )}

      {!geneboxGutShow ? null : (
        <GeneBoxGutCodeUpdate
          show={geneboxGutShow}
          setShow={setGeneboxGutShow}
          userVerificationId={selectedHTUserID}
          getPremiumUserListData={getPremiumUserListData}
          setSelectedHTUserID={setSelectedHTUserID}
          setSelectedGeneboxGutKitCode={setSelectedGeneboxGutKitCode}
          selectedGeneboxGutKitCode={selectedGeneboxGutKitCode}
        />
      )}
    </div>
  );
};

export default PremiumUsers;
