import { createContext, useState } from "react";
const NutritionContext = createContext({});

export const NutritionDataProvider = ({ children }) => {

  //Common Values
  const [foodListData, setFoodListData] = useState([]);

  const [selectedFoodDetails, setSelectedFoodDetails] = useState([]);
  const [selectedFoodServing, setSelectedFoodServing] = useState([]);
  const [selectedFoodMicros, setSelectedFoodMicros] = useState([]);
  const [transferCCFoodData, setTransferCCFoodData] = useState([]);

  /* Food Pagination Values */

  /* Food */

  const [initialRenderFood, setInitialRenderFood] = useState(true);
  const [totalFoodRows, setTotalFoodRows] = useState(0);

  const [foodListParam, setFoodListParam] = useState({
    limit: 10,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    search: "",
  });

  /* Meal */
  const [mealListData, setMealListData] = useState([]);
  const [initialRenderMeal, setInitialRenderMeal] = useState(true);

  /* Foodintake */
  //Data Verified
  const [foodIntakeList, setFoodIntakeList] = useState([]);
  const [foodIntakeCount, setFoodIntakeCount] = useState([]);

  //Unclassified
  const [foodIntakeUCList, setFoodIntakeUCList] = useState([]);
  const [foodIntakeUCCount, setFoodIntakeUCCount] = useState([]);

  //Unidentified
  const [foodIntakeUIList, setFoodIntakeUIList] = useState([]); 
  const [foodIntakeUICount, setFoodIntakeUICount] = useState([]);

  const [initialRender, setInitialRender] = useState(true);
  const [initialRenderUCFood, setInitialRenderUCFood] = useState(true);
  const [initialRenderUIFood, setInitialRenderUIFood] = useState(true);

  const [foodIntakeParam, setFoodIntakeParam] = useState({
    limit: 10,
    offset: 0,
    orderBy: "validated_at",
    order: "DESC",
    is_validated: true,   
    is_unidentified: false,
    meal_type: "",
  });

  const [foodIntakeUCParam, setFoodIntakeUCParam] = useState({
    limit: 10,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    is_validated: false,
    is_unidentified: false,
    meal_type: "",
  });

  const [foodIntakeUIParam, setFoodIntakeUIParam] = useState({
    limit: 10,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    // is_validated: null,
    is_unidentified: true,
    meal_type: "",
  });

  /* End - Foodintake */
  const foodServingUnitArray = [
    "0.25",
    "0.5",
    "0.75",
    "1",
    "1.5",
    "2",
    "2.5",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "60",
    "70",
    "80",
    "90",
    "100",
    "150",
    "200",
    "250",
    "300",
    "350",
    "400",
    "450",
    "500",
    "600",
    "700",
    "800",
    "900",
    "1000",
    "1500",
    "2000",
  ];
  
  return (
    <NutritionContext.Provider
      value={{
        /* Food */
        foodListData,
        setFoodListData,
        selectedFoodDetails,
        setSelectedFoodDetails,
        selectedFoodServing,
        setSelectedFoodServing,
        selectedFoodMicros,
        setSelectedFoodMicros,
        transferCCFoodData,
        setTransferCCFoodData,
        foodServingUnitArray,

        //Food
        totalFoodRows,
        setTotalFoodRows,
        foodListParam,
        setFoodListParam,
        initialRenderFood,
        setInitialRenderFood,

        /* Meal */
        initialRenderMeal,
        setInitialRenderMeal,
        mealListData,
        setMealListData,

        //Food Intake
        foodIntakeList,
        setFoodIntakeList,
        foodIntakeCount,
        setFoodIntakeCount,
        foodIntakeParam,
        setFoodIntakeParam,

        //Food Intake Unclassified
        foodIntakeUCList, setFoodIntakeUCList,
        foodIntakeUCCount, setFoodIntakeUCCount,
        foodIntakeUCParam, setFoodIntakeUCParam,  

        //Food Intake Unclassified
        foodIntakeUIList, setFoodIntakeUIList,
        foodIntakeUICount, setFoodIntakeUICount,
        foodIntakeUIParam, setFoodIntakeUIParam,        

        initialRender,
        setInitialRender,
        initialRenderUCFood, 
        setInitialRenderUCFood,
        initialRenderUIFood, 
        setInitialRenderUIFood
      }}
    >
      {children}
    </NutritionContext.Provider>
  );
};

export default NutritionContext;
