import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCircleCheck,
  faClock,
  faClose,
  faCopy,
  faEye,
  faFileLines,
  faFilter,
  faPlane,
  faRefresh,
  faSearch,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import { Chip, Divider, Grid, IconButton, InputBase, Paper, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Button, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "./Verification.scss";
import UserInfo from "./UserInfo";
import { getUserVerificationList, getVerificationUser } from "../../../utils/VerificationAPI";
import TokenContext from "../../../context/TokenContext";
import MainContext from "../../../context/MainContext";
import InviteMessage from "./InviteMessage";
import { formatTimestamp, formatTimestampWithDoubleLine } from "../../../common/commonFunctions";

const UserVerification = () => {

  const {    
    userVerificationList,
    setUserVerificationList,
    userVerificationListTotalCount, setUserVerificationListTotalCount,
    initRenderVerifiy,
    setInitRenderVerifiy,
    verificationListParam, setVerificationListParam
  } = useContext(TokenContext);

  const { setIsPreLoading } = useContext(MainContext);

  const [initComp, setInitComp] = useState(true);
  const [verificationUserSearch, setVerificationUserSearch] = useState("")

  const [isSearchParam, setIsSearchParam] = useState(false);

  const [selectedUserinfo, setSelectedUserinfo] = useState([]);

  const getUserVerificationListData = async () => {
    const requestParam = verificationListParam;
    setIsPreLoading(true)
    try {
      const getUserVerificationListResponse = await getUserVerificationList(
        requestParam
      );
      setUserVerificationList(
        getUserVerificationListResponse?.data?.user_details
      );
      setUserVerificationListTotalCount(
        getUserVerificationListResponse?.data?.total_count
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsPreLoading(false)
    }
  };

  const getVerificationUserData = async (verificationId) => {

    setIsPreLoading(true);    

    const requestParam = {
      verification_id : verificationId
    };

    try {
      const getVerificationUserResponse = await getVerificationUser(
        requestParam
      );
      
      setSelectedUserinfo(getVerificationUserResponse?.data?.user_detail);
      
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsPreLoading(false);
    }

    // 
  };

   //Pagination Change
  const handlePageChange = (page) => {    
    const newOffset = (page - 1) * verificationListParam?.limit;
    setVerificationListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      offset: newOffset,
    }));
  };

  const handleHeaderRowClick = (keyValue) => {
    setVerificationListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      orderBy:keyValue,
      order: prevVerificationListParam.order === "ASC" ? "DESC" : "ASC"
    }));
  };

  //Rows Count Change
  const handleRowsPerPageChange = (newPerPage) => {
    if(!initRenderVerifiy) {
      if(newPerPage !== verificationListParam?.limit){
        setVerificationListParam((prevVerificationListParam) => ({
          ...prevVerificationListParam,
          limit: newPerPage,
        }));
      }
    }
  };

  /* Based on Param */
  useEffect(() => {
    if (!initRenderVerifiy && !initComp) {
      getUserVerificationListData();
    }
  }, [verificationListParam]);

  useEffect(()=>{
    if (initRenderVerifiy) {
      getUserVerificationListData();
      setInitRenderVerifiy(false);
    }

    setTimeout(() => {
      setInitComp(false)
    }, 1000);
  },[])

  useEffect(() => {
    if (isSearchParam) {
      setVerificationListParam((prevState)=>({
        ...prevState,
        search: verificationUserSearch.trim(),
        offset: 0
      }))
      setIsSearchParam(false);
    }
  }, [isSearchParam]);

  /*  */
  const VerificationStatus = ({ verified, className }) => (
    <FontAwesomeIcon icon={verified ? faCircleCheck : faClock} className={className} />
  );

  const ClipboardCopy = ({ value }) => {
    const [copyText, setCopyText] = useState("Click to copy");

    return (
      <Tooltip title={copyText}>
        <FontAwesomeIcon
          icon={faCopy}
          className="mx-2"
          style={{ cursor: "pointer", color: "blue"  }}
          onClick={() => {
            navigator.clipboard.writeText(value);
            setCopyText("Copied!");
            setTimeout(() => {
              setCopyText("Click to copy!");
            }, 2000);
          }}
        />
      </Tooltip>
    );
  };

  const userVerificationListColumns = [
    {
      name: "ID",
      selector: (row) => parseInt(row.id),
      // minWidth: "5%",
    },
    {
      name: "Customer id",
      selector: (row) => row.customer_id?.slice(0, 8),
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-start">
          {row.customer_id ? (
            <p className="mb-0 white-space-no-wrap">
              <b>
                {row.customer_id?.slice(0, 8)}{"..."}
                <ClipboardCopy value={row.customer_id} />
              </b>
            </p>
          ): <p className="text-center">-</p>}
        </div>
      ),
    },
    {
      name: "Lead Id",
      selector: (row) => row?.lead_id?.slice(0, 8),
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-start">
          {row.lead_id ? (
            <p className="mb-0 white-space-no-wrap">
              <b>
                {row.lead_id?.slice(0, 8)}{"..."}
                <ClipboardCopy value={row.lead_id} />
              </b>
            </p>
          ): <p className="text-center">-</p>}
        </div>
      ),
    },
    // {
    //   name: "Contact Info",
    //   selector: (row) => row.email,
    //   width: "25%",
    //   cell: (row) => (
    //     <div className="text-start">
    //       {row.mobile && (
    //         <p className="mb-2">
    //           Mobile: <b>{row.mobile}</b>
    //         </p>
    //       )}
    //       {row.email && (
    //         <p className="mb-2">
    //           Email: <b>{row.email}</b>
    //         </p>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   name: <>Source</>,
    //   selector: (row) => row.source,
    //   cell: (row) => (
    //     <div className="text-center verification-source">
    //       <Chip
    //         variant="filled"
    //         label={row.source === "CST" ? "CST" : "Typeform"}
    //         className={row.source === "CST" ? "cst" : "typeform"}
    //       />
    //     </div>
    //   ),
    // },
    {
      name: (
        <>
          Verify
          <br />
          Status
        </>
      ),
      selector: (row) => row.mobile_verified_at,
      // minWidth: "5%",
      cell: (row) => (
        <div className="text-center verified-tick">
          {row.source === "CST" ? (
            row.mobile_verified_at ? (
              <FontAwesomeIcon icon={faCircleCheck} className="verified" />
            ) : (
              <FontAwesomeIcon icon={faClock} className="not-verified" />
            )
          ) : row.mobile_verified_at ? ( //&& row.email_verified_at
            <FontAwesomeIcon icon={faCircleCheck} className="verified" />
          ) : (
            <FontAwesomeIcon icon={faClock} className="not-verified" />
          )}
        </div>
      ),
      // sortable:true
    },
    {
      name: (
        <>
          Payment
          <br />
          Status
        </>
      ),
      selector: (row) => row.payment_at,
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-center paid-status">
          <Chip
            variant="filled"
            label={
              row.payment_at && row.payment_status === "SUCCESS"
                ? "Paid"
                : "Not Paid"
            }
            className={
              row.payment_at && row.payment_status === "SUCCESS"
                ? "Paid"
                : "Not-Paid"
            }
          />
        </div>
      ),
    },
    {
      name: (
        <>
          Payment
          <br />
          Info
        </>
      ),
      selector: (row) => row.payment_at,
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-center payment-status">
          <Chip
            variant="filled"
            label={
              (row.payment_at && row.payment_status === "SUCCESS" && row?.payment_order_id)
                ? "Success"
                : (!row.payment_status && row?.payment_order_id) ? "Initiated" : "Not Initiated"
            }
            className={
              (row.payment_at && row.payment_status === "SUCCESS" && row?.payment_order_id)
                ? "Success"
                :(!row.payment_status && row?.payment_order_id) ? "Initiated" : "Not-Initiated"
            }
          />
        </div>
      ),
    },
    {
      name: <>Is Eligible</>,
      selector: (row) => row.is_eligible,
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-center elegible-status">
          <Chip
            variant="filled"
            label={
              row.is_eligible === null
                ? "Not Yet"
                : row.is_eligible
                ? "Eligible"
                : "Not Eligible"
            }
            className={
              row.is_eligible === null
                ? "Not-Yet"
                : row.is_eligible
                ? "Eligible"
                : "Not-Eligible"
            }
          />
        </div>
      ),
    },
    {
      name: <>Created at</>,
      selector: (row) => formatTimestampWithDoubleLine(row.created_at),
      // minWidth: "10%",
    },
    {
      name: "View",
      // minWidth: "10%",
      cell: (row) => (
        <div className="text-center">
          <Button
            variant="outline-success"
            color="primary"
            size="sm"
            style={{
              textTransform: "none",
              borderRadius: "5px",
            }}
            onClick={() => {
              getVerificationUserData(row?.verification_id);
              setShow(true);
              setIsUserView(true);
            }}
          >
            <FontAwesomeIcon icon={faEye} className="mx-1" />
            View
          </Button>
        </div>
      ),
    },
  ];

  const [inviteShow, setInviteShow] = useState(false);
  const [show, setShow] = useState(false);
  const [isEditView, setIsEditView] = useState(false);
  const [isUserView, setIsUserView] = useState(false);

  /* Filter  */
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const [isPayed, setIsPayed] = useState('');
  const [isVerified, setIsVerified] = useState('');

  const handlePaymentFilter = (event, value) => {
    setIsPayed(value);    
    setVerificationListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      is_paid: value !== "" ? value : "",
    }));
  };

  const handleVerifiedFilter = (event, value) => {
    setIsVerified(value);
    setVerificationListParam((prevVerificationListParam) => ({
      ...prevVerificationListParam,
      is_verified: value !== "" ? value : "",
    }));
  };

  const handleClearFilter = () => {   
    if(isExpanded){
      setIsPayed("");    
      setIsVerified("");
      setVerificationListParam((prevVerificationListParam) => ({
        ...prevVerificationListParam,
        is_paid: "",
        is_verified:""
      }));
    }    
  };

  return (
    <div className="user-verification ">
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2"/> Registers Users
      </section>
      <div>
        <Grid container>
          <Grid item md={4} xs={8} className="text-end search-section mb-2 px-0">
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              className="kit-search-section"
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search User"
                inputProps={{ "aria-label": "Search User" }}
                value={verificationUserSearch}
                onChange={(e) => {
                  setVerificationUserSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setIsSearchParam(true);
                  }
                }}
              />

              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                className="py-2 search-clear"
                onClick={() => {
                  setVerificationUserSearch("");
                  setVerificationListParam((prevVerificationListParam) => ({
                    ...prevVerificationListParam,
                    search: "",
                    offset: 0
                  }));
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                className="py-2 food-search-clear"
                onClick={() => {
                  setIsSearchParam(true);
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item md={8} xs={4} className="text-end top-action-button-group">
            <div>
              <Button
                variant="outline-secondary"
                color="secondary"
                className=" mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  getUserVerificationListData();
                }}
              >
                <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
                <span>Refresh</span>
              </Button>
              <Button
                variant="outline-primary"
                color="secondary"
                className="mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={toggleExpandCollapse}
              >
                <FontAwesomeIcon icon={faFilter} className="mx-1" />{" "}
                <span>Filter</span>
              </Button>
            </div>
            {/* <div>
              <Button
                variant="outline-success"
                color="success"
                className="mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setShow(true);
                  setIsEditView(true);
                }}
              >
                <FontAwesomeIcon icon={faSquarePlus} className="mx-1" />
                Create User <span></span>
              </Button>
              <Button
                variant="outline-success"
                color="success"
                className=""
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setInviteShow(true)
                }}
              >
                <FontAwesomeIcon icon={faPlane} className="mx-1" />
                Invite <span></span>
              </Button>
            </div> */}
          </Grid>
        </Grid>

        <div className="filter-container">
          <div className={`content ${isExpanded ? "expanded" : "collapsed"}`}>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={isPayed}
              onChange={handlePaymentFilter}
              aria-label="text isPayed"
              size="small"
              className="mx-2"
            >
              <ToggleButton value={""} aria-label="All">
                All
              </ToggleButton>
              <ToggleButton value={true} aria-label="paid">
                Paid
              </ToggleButton>
              <ToggleButton value={false} aria-label="not-paid">
                Not Paid
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              color="secondary"
              exclusive
              value={isVerified}
              onChange={handleVerifiedFilter}
              aria-label="text alignment"
              size="small"
              className="mx-2"
            >
              <ToggleButton value={""} aria-label="All">
                All
              </ToggleButton>
              <ToggleButton value={true} aria-label="verified">
                Verified
              </ToggleButton>
              <ToggleButton value={false} aria-label="not-verified">
                Non verified
              </ToggleButton>
            </ToggleButtonGroup>

            {/* <ToggleButtonGroup
              color="warning"
              exclusive
              value={isVerified}
              onChange={handleVerifiedFilter}
              aria-label="text alignment"
              size="small"
              className="mx-2"
            >
              <ToggleButton value={""} aria-label="All">
                All
              </ToggleButton>
              <ToggleButton value={null} aria-label="All">
                Not-Yet
              </ToggleButton>
              <ToggleButton value={true} aria-label="eligible">
                Eligible
              </ToggleButton>
              <ToggleButton value={false} aria-label="not-eligible">
                Not-Eligible
              </ToggleButton>
            </ToggleButtonGroup> */}

            <Button
              variant="outline-danger"
              color="primary"
              size="sm"
              onClick={handleClearFilter}
              className="clear-filter-button"
            >
              <FontAwesomeIcon icon={faClose} className="mx-1" /> Clear Filter
            </Button>
          </div>
        </div>
        <div
          className={`dt-table ${verificationListParam?.orderBy} ${verificationListParam?.order}`}
        >
          <DataTable
            columns={userVerificationListColumns}
            striped
            data={userVerificationList}
            className="dt-user-list dh-table-basic"
            pagination
            paginationPerPage={verificationListParam?.limit}
            paginationServer
            paginationTotalRows={userVerificationListTotalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            searchable
          />
        </div>
      </div>

      <UserInfo
        show={show}
        setShow={setShow}
        isEditView={isEditView}
        setIsEditView={setIsEditView}
        isUserView={isUserView}
        setIsUserView={setIsUserView}
        selectedUserinfo={selectedUserinfo}
        setSelectedUserinfo={setSelectedUserinfo}
        getUserVerificationListData={getUserVerificationListData}
        getVerificationUserData={getVerificationUserData}
      />

      <InviteMessage show={inviteShow} setShow={setInviteShow}/>
    </div>
  );
};

export default UserVerification;
