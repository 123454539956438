import React, { useContext, useEffect, useState } from "react";
import "./scss/NutritionPage.scss";
import { Grid } from "@mui/material";
import {
  elementToSVG
} from "dom-to-svg";
// import { foodsToEatData } from "./dataJson/foodsToEat";
import JSZip from "jszip";
import { toPng } from "html-to-image";
import MainContext from "../../context/MainContext";

const FoodsToEat = ({foodsToEatData}) => {

  const targetFirstPage = 477;
  const mainTarget = 645;
  const [groups, setGroups] = useState([Object.keys(foodsToEatData)]);

  const splitSmallCategory = (arr) => {
    const midIndex = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, midIndex);
    const secondHalf = arr.slice(midIndex);
    return [firstHalf, secondHalf];
  };

  const splitIntoUnorderedGroups = (nutritionData) => {
    // Get section heights for each category
    const allSectionHeights = getCategoryHeights(nutritionData);

    //First Page Fit
    const findBestFit = (data, target) => {
      // Filter out entries with values <= 150
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value > 150)
      );

      const entries = Object.entries(filteredData);

      // Function to get all combinations
      const getCombinations = (arr) => {
        const result = [];
        const f = (start, combo) => {
          for (let i = start; i < arr.length; i++) {
            const newCombo = [...combo, arr[i]];
            result.push(newCombo);
            f(i + 1, newCombo);
          }
        };
        f(0, []);
        return result;
      };

      const allCombinations = getCombinations(entries);

      let bestCombination = [];
      let bestSum = 0;

      // Find the combination with the highest sum that doesn't exceed the target
      allCombinations.forEach((combo) => {
        const sum = combo.reduce((acc, [_, value]) => acc + value, 0);
        if (sum <= target && sum > bestSum) {
          bestSum = sum;
          bestCombination = combo;
        }
      });

      return { bestCombination: bestCombination.map(([key]) => key), bestSum };
    };

    const { bestCombination } = findBestFit(allSectionHeights, targetFirstPage);

    let sectionHeights = Object.fromEntries(
      Object.entries(allSectionHeights).filter(([key]) => !bestCombination.includes(key))
    );

    // console.log("allSectionHeights", allSectionHeights)
    // console.log("sectionHeights", sectionHeights)
  
    //End - First Page Fit

    // Split categories into large and small based on the length of their items
    const { largeCategories, smallCategories } = splitCategories(
      nutritionData,
      sectionHeights
    );

    // console.log( largeCategories, smallCategories)

    // Sort categories by height in descending order
    const sortedCategories = sortCategoriesByHeight(
      largeCategories,
      sectionHeights
    );

    // Create groups based on the target height
    const resultGroups = createGroups(sortedCategories, sectionHeights);

    // Place small categories into existing or new groups
    const updatedGroups = placeSmallCategories(
      resultGroups,
      smallCategories,
      sectionHeights
    );

    // Add second half elements
    const finalGroups = addSecondHalfElements(
      updatedGroups,
      smallCategories,
      sectionHeights
    );

    let combinedGroups = [bestCombination, ...finalGroups];
    setGroups(combinedGroups);
    // console.log("finalGroups", finalGroups);
    // console.log("bestCombination", bestCombination);

    // console.log("combinedGroups", combinedGroups);
    // console.log("sectionHeights", sectionHeights);

    // setGroups(finalGroups);
    
  };
  
  const getCategoryHeights = (nutritionData) => {
    let sectionHeights = {};
    Object.keys(nutritionData).forEach((category) => {
      const elements = document.getElementsByClassName("section-foods-to-eat-" + category);
      const elementHeight = elements[0]?.clientHeight || 0;
      sectionHeights[category] = elementHeight;
    });
    return sectionHeights;
  };
  
  const splitCategories = (nutritionData, sectionHeights) => {
    let largeCategories = [];
    let smallCategories = [];
    Object.entries(nutritionData).forEach(([category, items]) => {
      if (items.length <= 2) {
        smallCategories.push(category);
      } else {
        if(sectionHeights[category]){
          largeCategories.push([category, sectionHeights[category]]);
        }
      }
    });
    return { largeCategories, smallCategories };
  };
  
  const sortCategoriesByHeight = (categories, sectionHeights) => {
    return categories.sort((a, b) => b[1] - a[1]);
  };
  
  const createGroups = (sortedCategories, sectionHeights) => {
    let remainingCategories = [...sortedCategories];
    let resultGroups = [];
    let isFirstGroup = true;
  
    while (remainingCategories.length > 0) {
      let currentGroup = [];
      let currentSum = 0;
      let target = mainTarget;
  
      for (let i = 0; i < remainingCategories.length; i++) {
        const [category, value] = remainingCategories[i];
        if (currentSum + value <= target || currentGroup.length === 0) {
          currentSum += value;
          currentGroup.push(category);
        }
      }
  
      resultGroups.push(currentGroup);
      isFirstGroup = false;
  
      currentGroup.forEach((cat) => {
        remainingCategories = remainingCategories.filter(([category]) => category !== cat);
      });
    }
  
    return resultGroups;
  };
  
  const placeSmallCategories = (resultGroups, smallCategories, sectionHeights) => {
    let isFirstGroup = true;
    const [firstHalf, secondHalf] = splitSmallCategory(smallCategories);
    //smallCategories  - firstHalf
    firstHalf.forEach((smallCategory) => {
      let placed = false;
      let target = mainTarget;
  
      for (let i = 0; i < resultGroups.length; i++) {
        let groupHeight = resultGroups[i].reduce((sum, cat) => sum + sectionHeights[cat], 0);
        if (groupHeight + sectionHeights[smallCategory] <= target) {
          resultGroups[i].push(smallCategory);
          placed = true;
          break;
        }
      }
  
      if (!placed) {
        resultGroups.push([smallCategory]);
      }
  
      isFirstGroup = false;
    });
    return resultGroups;
  };
  
  const addSecondHalfElements = (groups, smallCategories, sectionHeights) => {
    const [firstHalf, secondHalf] = splitSmallCategory(smallCategories);
    return groups.map(group => {
      let newGroup = [...group];
      firstHalf.forEach((item, index) => {
        if (group.includes(item) && secondHalf[index]) {
          newGroup.push(secondHalf[index]);
        }
      });
      return newGroup;
    });
  };

  useEffect(() => {
    setTimeout(() => {
      splitIntoUnorderedGroups(foodsToEatData);
    }, 3000);
  }, []);


  const NutritionBox = ({ category }) => (
    <Grid
      item
      sm={foodsToEatData[category]?.length <= 2 ? 6 : 12}
      className={`section-foods-to-eat-${category}`}
    >
      <section className="nutrition-box">
        <h4 className="title">{category}</h4>
        <Grid container spacing={2} className="items-list">
          {foodsToEatData[category].map((item, index) => (
            <Grid
              item
              sm={foodsToEatData[category]?.length <= 2 ? 6 : 3}
              key={index}
            >
              <div
                className={`items ${
                  item.count !== undefined ? "count-bg" : ""
                }`}
              >
                <div>
                  <h5
                    className={`icon ${
                      item.count !== undefined ? "count-bg" : ""
                    }`}
                  >
                    {item.icon}
                  </h5>
                  <h5 className="name">{item.name}</h5>
                </div>
                {item.count !== undefined && (
                  <div>
                    <span className="count">{item.count}</span>
                  </div>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </section>
    </Grid>
  );



  return (
    <div style={{ height: "auto" }}>

      <h4 className="graph-header">Nutrition - Foods To Eat</h4>

      {groups?.map((group, index) => (
        <div
          key={index}
          className={`foods-to-eat-pages nutrition-pages ${index === 0 ? 'nutrition-page-first' : ''}`}
          // style={{ height: "700px", marginBottom: "20px" }}
        >
          <Grid container spacing={2}>
            {group?.map((category) => (
              <NutritionBox key={category} category={category} />
            ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default FoodsToEat;
