import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;

const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      client_id: REACT_APP_CLIENT_ID,
      access_token: cookies.get("access_token"),
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};

const trackingApi = createAxiosInstance(REACT_APP_BASE_URL);

//Interceptors
const applyAccessTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (req) => {
      const token = cookies.get("access_token");
      if (token) {
        req.headers["access_token"] = token; // Set Access Token
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  apiInstance.interceptors.response.use(
    (req) => {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

applyAccessTokenInterceptor(trackingApi);

export const updateGBClientUsername = (requestData) =>
  new Promise((resolve, reject) => {
    trackingApi
      .patch(`/v3/diagnostics/gene-box/client-username`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const updateGBGutKitCode = (requestData) =>
  new Promise((resolve, reject) => {
    trackingApi
      .patch(`/v3/diagnostics/gene-box/gut-kit-code`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const createDiKit = (requestData) =>
  new Promise((resolve, reject) => {
    trackingApi
      .post(`/v3/diagnostics/kit`, requestData)
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const updateDiKit = (requestData) =>
  new Promise((resolve, reject) => {
    trackingApi
      .put(`/v3/diagnostics/kit`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const updateDiOrder = (requestData) =>
  new Promise((resolve, reject) => {
    trackingApi
      .put(`/v3/diagnostics/order`, requestData)
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export { trackingApi };
