import {
  faCaretRight,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import React, { memo, useContext } from "react";
import { Handle } from "reactflow";
import QuestionnaireContext from "../../context/QuestionnaireContext";

export default memo(({ data, isConnectable, ...props }) => {
  const { question, label } = data;

  const { handleViewQuestionInfo } = useContext(QuestionnaireContext);

  return (
    <>
      <Handle
        type="source"
        position={props.sourcePosition}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={props.targetPosition}
        isConnectable={isConnectable}
      />
      <div className={`custom-node-item ${label === "End" && "end-node"}`}>
        {label !== "End" && (
          <div
            className={`node-item ${
              question?.required ? "required-node" : "non-required-node"
            } `}
          >
            <p className="question-header">
              <FontAwesomeIcon icon={faCircleQuestion} />
              Question
            </p>
            <span className="question">
              [{question?.id}]-{question?.value || label}
            </span>
            <div className="d-flex justify-content-between my-2">
              <p className="m-0">
                {"Input Type: "} <span>{question?.input_element}</span>
              </p>
              <p className="m-0">
                {"Type: "}
                <span>{question?.type}</span>
              </p>
            </div>
            {question?.tag && (
              <div className="text-start questionnaire-tags">
                <p className="mb-1">{"Tags: "}</p>
                <div className="tag-list">
                  {question?.tag?.map((tag, index) => (
                    <span key={index} className="tag-item">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between my-2">
              <p
                className={`m-0 ${
                  question?.required ? "required" : "not-required"
                }`}
              >
                {question?.required ? "Required *" : "Not Required"}
              </p>
            </div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                handleViewQuestionInfo(question);
              }}
            >
              View Question
            </Button>
            <div className="options hide">
              <span>Options:</span>
              <div className="ScrollDesign listoptions">
                {question?.answers?.map((answer, index) => (
                  <React.Fragment key={index}>
                    {answer?.value.includes("http") ? (
                      <div className="image-container">
                        <img
                          src={answer?.value}
                          alt="image"
                          style={{ maxHeight: "65px" }}
                        />
                      </div>
                    ) : (
                      <p>
                        <FontAwesomeIcon
                          icon={faCaretRight}
                          className="option-icon"
                        />
                        {answer?.value}
                      </p>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        )}
        {label === "End" && <strong>{data?.label}</strong>}
      </div>
    </>
  );
});
