import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import MainContext from "../../../context/MainContext";
import { updateGBGutKitCode } from "../../../utils/TrackingAPI";
import "./Premium.scss";

const GeneBoxGutCodeUpdate = ({
  show,
  setShow,
  userVerificationId,
  getPremiumUserListData,
  setSelectedHTUserID,
  selectedGeneboxGutKitCode,
}) => {
  const handleClose = () => {
    setSelectedHTUserID("");
    setGeneboxGutCode("");
    setShow(false);
  };

  const { ShowNotification, setIsPreLoading } = useContext(MainContext);

  const [geneboxGutCode, setGeneboxGutCode] = useState("");

  useEffect(() => {
    if (show) {
      setGeneboxGutCode(selectedGeneboxGutKitCode);
    } else {
      setGeneboxGutCode("");
    }
  }, [show]);

  const handleRegisterGBKitCode = async () => {
    setIsPreLoading(true);

    try {
      let requestData = {
        ht_user_verification_id: userVerificationId.toString(),
        genebox_gut_kit_code: geneboxGutCode,
      };
      const registerGBGutKitCodeResponse = await updateGBGutKitCode(
        requestData
      );
      getPremiumUserListData();
      handleClose();
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  return (
    <div>
      <Offcanvas
        className="genebox-gutkitcode-view"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Genebox GutKit Code Update</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <TextField
              className="my-0"
              label="Genebox Gut Kit Code"
              size={"small"}
              fullWidth
              placeholder="Genebox Gut Kit Code"
              variant="outlined"
              value={geneboxGutCode}
              onChange={(e) => {
                const inputValue = e.target.value;
                const sanitizedValue = inputValue.replace(/\s/g, "");
                if (sanitizedValue?.length <= 100) {
                  setGeneboxGutCode(sanitizedValue);
                }
              }}
            />
          </div>
          <div className="my-2 text-end">
            <Button variant="secondary" onClick={handleClose} className="mx-2">
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleRegisterGBKitCode();
              }}
            >
              Update
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default GeneBoxGutCodeUpdate;
