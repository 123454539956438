import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { useState } from "react";

export const capitalized=(word) =>{
    return word?.charAt(0).toUpperCase()
    + word?.slice(1)
}

export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();

    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    // const ss = String(date.getSeconds()).padStart(2, "0");

    return (
      <span>
        {`${dd}-${mm}-${yyyy}`} <small>{`${hh}:${min}`}</small>
        {/* ${ss} */}
      </span>
    );
  }

export const formatTimestampWithDoubleLine = (timestamp) => {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();

    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    // const ss = String(date.getSeconds()).padStart(2, "0");

    return (
      <span>
        {`${dd}-${mm}-${yyyy}`} <br/><small>{`${hh}:${min}`}</small>
        {/* ${ss} */}
      </span>
    );
  }

export const calculateRemainingDays = (expiryDate) => {
  if (!expiryDate) return null; // Return null if expiry date is not available

  const expiryDateObj = new Date(expiryDate);
  const now = new Date();
  const timeDifference = expiryDateObj.getTime() - now.getTime();
  const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));

  if (daysRemaining < 0) {
    return `Expired ${Math.abs(daysRemaining)} ${
      Math.abs(daysRemaining) !== 1 ? "days" : "day"
    } ago`;
  } else {
    return `Expiry: ${daysRemaining} ${
      daysRemaining !== 1 ? "days" : "day"
    } remaining`;
  }
};

export const isExpired = (dateValue) => {
  if (!dateValue) return false;
  
  const expiryDateObj = new Date(dateValue);
  const now = new Date();
  
  return expiryDateObj.getTime() < now.getTime();
};

export const ClipboardCopy = ({ value }) => {
  const [copyText, setCopyText] = useState("Click to copy");

  return (
    <Tooltip title={copyText}>
      <FontAwesomeIcon
        icon={faCopy}
        className="mx-2"
        style={{ cursor: "pointer", color: "blue"  }}
        onClick={() => {
          navigator.clipboard.writeText(value);
          setCopyText("Copied!");
          setTimeout(() => {
            setCopyText("Click to copy!");
          }, 2000);
        }}
      />
    </Tooltip>
  );
};

export const formatDateFormat = (timestamp) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = date.getFullYear();

  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  // const ss = String(date.getSeconds()).padStart(2, "0");

  return (
    <span>
      {`${dd}-${mm}-${yyyy}`}
      {/* ${ss} */}
    </span>
  );
}