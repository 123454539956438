import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAppleAlt,
  faClock,
  faClose,
  faEye,
  faFileLines,
  faFilter,
  faMobileAndroid,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import TokenContext from "../../../context/TokenContext";
import { Button } from "react-bootstrap";
import MainContext from "../../../context/MainContext";
import {
  getClaimHumanTokenUsersList,
  getClaimHumanTokenUsersStatus,
  getUserDiOrderData
} from "../../../utils/preTokenApi";
import { formatTimestampWithDoubleLine } from "../../../common/commonFunctions";
import ClaimTokenStatus from "./ClaimTokenStatus";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

const HumanTokenList = () => {
  const { humanTokenUsersList, setHumanTokenUsersList } =
    useContext(TokenContext);
  const { setIsPreLoading, ShowNotification } = useContext(MainContext);

  const [initComp, setInitComp] = useState(true);
  const [selectedTokenData, setSelectedTokenData] = useState([]);
  const [selectedBloodTestData, setSelectedBloodTestData] = useState([]);
  const [selectedUserRow, setSelectedUserRow] = useState([]);

  const [isDeliveryModalShow, setIsDeliveryModalShow] = useState(false);

  /* Based on Param */
  useEffect(() => {
    if (initComp) {
      getHumanTokenUsersListData();
    }
    setTimeout(() => {
      setInitComp(false);
    }, 1000);
  }, []);

  const getHumanTokenUsersListData = async () => {
    setIsPreLoading(true);
    try {
      const getHumanTokenUsersListDataResponse =
        await getClaimHumanTokenUsersList();
      setHumanTokenUsersList(
        getHumanTokenUsersListDataResponse?.data?.ht_users
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const handleGetUserTokenStatus = async (userId) => {
    setIsPreLoading(true);
    try {
      let requestData = {
        user_id: userId,
      };
      const getClaimHumanTokenUsersStatusResponse =
        await getClaimHumanTokenUsersStatus(requestData);
      setSelectedTokenData(
        getClaimHumanTokenUsersStatusResponse?.data?.ht_delivery_status
      );
      setIsDeliveryModalShow(true)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.errorMessage);
    } finally {
      setIsPreLoading(false);
    }
  };

  const humanTokenUsersListColumns = [
    {
      name: "User Id",
      selector: (row) => row?.user_id,
      width: "10%",
      cell: (row) => {
        return <div className="token-user-id">{row?.user_id}</div>;
      },
    },
    {
      name: "Level ID",
      selector: (row) => row?.level_id,
      width: "10%",
      cell: (row) => {
        return <div className="token-level-id">{row?.level_id}</div>;
      },
    },
    {
      name: "Unlocked At",
      selector: (row) => row?.unlocked_at,
      width: "10%",
      cell: (row) => (
        <>
          {formatTimestampWithDoubleLine(row?.unlocked_at)}
        </>
      ),
    },
    {
      name: "Milestone",
      selector: (row) =>
        row?.completed_milestone_count + "/" + row.milestone_count,
      width: "10%",
    },
    {
      name: "App version",
      selector: (row) => row?.app_version_header,
      width: "10%",
    },
    {
      name: "Mobile Type",
      selector: (row) => row?.mobile_type,
      width: "10%",
      cell: (row) =>{
        return (
          row?.mobile_type === "android" ? (
            <div className="mobile-type-icon">
              <AndroidIcon /> <span>Android</span>
            </div>
          ) : (
            <div className="mobile-type-icon">
              <AppleIcon /> <span>iOS</span>
            </div>
          )
        );
  
      }
    },
    {
      name: "CGM Days",
      selector: (row) => row?.cgmdays,
      width: "10%",
      cell: (row) => {
        const cgmdays = row?.cgmdays;

        if (!cgmdays) {
          return null;
        }
        const formatTime = (value, unit) => {
          return value !== null && value !== undefined
            ? `${value} ${unit} `
            : "";
        };

        const timeString =
          formatTime(cgmdays.days, "Days") +
          formatTime(cgmdays.hours, "Hours") +
          formatTime(cgmdays.minutes, "Min") +
          formatTime(cgmdays.seconds, "Sec");

        return <div>{timeString.trim() && timeString.trim() + "."}</div>;
      },
    },
    {
      name: <>Estimated Token<br/> Delivery At</>,
      selector: (row) => {
        return row?.estimated_token_delivery_at
          ? formatTimestampWithDoubleLine(row.estimated_token_delivery_at)
          : "-";
      },
      width: "15%",
    },
    {
      name: "View",
      width: "15%",
      cell: (row) => (
        <div className="text-center">
          <Button
            variant="primary"
            color="primary"
            size="sm"
            disabled={parseInt(row?.level_id) !== 5 }
            style={{
              textTransform: "none",
              borderRadius: "5px",
            }}
            onClick={() => {
              setSelectedUserRow(row);
              handleGetUserTokenStatus(row?.user_id);
              getDIOrder(row?.user_id)
            }}
          >
            <FontAwesomeIcon icon={faEye} className="mx-1" />
            View
          </Button>
        </div>
      ),
    },
  ];

  const getDIOrder = async (userID) => {

    try {
      const requestParam = {
        user_id: userID
      };

      const getOrderDataResponse = await getUserDiOrderData(requestParam);
      const filterBloodTestData = (getOrderDataResponse?.data?.di_order)?.find(
        (order) => order.source === "THYROCARE"
      );
      setSelectedBloodTestData(filterBloodTestData)

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {

    }
  };


  return (
    <div>
      <div className="human-token-section">
        <section className="component-heading mt-1 mb-3">
          <FontAwesomeIcon icon={faFileLines} className="mx-2" /> Human Token
          Users
        </section>
        <Grid container>
          <Grid item md={4} xs={8} className="text-end search-section mb-2 px-0">
            
          </Grid>
          <Grid item md={8} xs={4} className="text-end top-action-button-group">
            <div>
              <Button
                variant="outline-secondary"
                color="secondary"
                className=" mx-1"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  getHumanTokenUsersListData();
                }}
              >
                <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
                <span>Refresh</span>
              </Button>
            </div>
          </Grid>
        </Grid>

        <div className={`dt-table my-2` }>
          <DataTable
            columns={humanTokenUsersListColumns}
            striped
            data={humanTokenUsersList}
            className="dt-user-list dh-table-basic"
            pagination
            // paginationPerPage={cgmUserActionParam?.limit}
            // paginationServer
            // paginationTotalRows={cgmUserActionListCount}
            // onChangePage={handlePageChange}
            // onChangeRowsPerPage={handleRowsPerPageChange}
            searchable
          />
        </div>
      </div>
     
      <ClaimTokenStatus
        show={isDeliveryModalShow}
        setShow={setIsDeliveryModalShow}
        selectedTokenData={selectedTokenData}
        setSelectedTokenData={setSelectedTokenData}
        selectedBloodTestData={selectedBloodTestData}
        setSelectedBloodTestData={setSelectedBloodTestData}
        selectedUserRow={selectedUserRow}
      />
    </div>
  );
};

export default HumanTokenList;
