import { faBan, faBell, faBoxesPacking, faCartShopping, faCircleCheck, faClose, faCubesStacked, faEdit, faFileAlt, faLink, faRefresh, faSave, faSyringe, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Card, Offcanvas } from 'react-bootstrap';
import { getCGMExpiryUserAction, updateCGMExpiryUserActionList } from '../../../utils/preTokenApi';
import MainContext from '../../../context/MainContext';
import { updateDiKit } from '../../../utils/TrackingAPI';
import { calculateRemainingDays, formatTimestamp, isExpired } from '../../../common/commonFunctions';

const CGMExpiryAction = ({ show, setShow, selectedRow, setSelectedRow, getCGMExpiryUserActionListData, hanldeViewUserActionData }) => {

  const handleClose = () => {
    setShow(false);
    setIsCgmEdit(false)
    setExpiryAction("")
    setExpiryRemark("")
    setExpiryError("")
    setDeviceCGMAttachedAt("")
    setDeviceCGMExpiredAt("")
  };

  const [expiryAction, setExpiryAction] = useState("")
  const [expiryRemark, setExpiryRemark] = useState("")
  const [deviceCGMKit, setDeviceCGMKit] = useState("")

  const [deviceCGMAttachedAt, setDeviceCGMAttachedAt] = useState("")
  const [deviceCGMExpiredAt, setDeviceCGMExpiredAt] = useState("")
  
  const [isCgmEdit, setIsCgmEdit] = useState(false)
  const { setIsPreLoading, ShowNotification } = useContext(MainContext);
  const [expiryError, setExpiryError] = useState('');

  const getUserActionData = async (requestID) => {
    try {
      const getCGMExpiryUserActionResponse = await getCGMExpiryUserAction(
        requestID
      );
      setSelectedRow(getCGMExpiryUserActionResponse?.data?.user_action)
      setShow(true);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const handleUpdateCGMExpiryAction = async () =>{

    let requestData = {
      profileID: selectedRow?.user_profiles[0]?.profile?.id,
      user_action: expiryAction,
      remark: expiryRemark
    };
    if (!expiryRemark?.trim() && (expiryAction === "purchase" || expiryAction === "continue" )) {
      setExpiryError('CGM User expiry remark is required');
      return
    }
    setIsPreLoading(true);
    try {
      const updateCGMExpiryUserActionListResponse = await updateCGMExpiryUserActionList(
        requestData
      );
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message);
    } finally {
      getCGMExpiryUserActionListData()
      setIsPreLoading(false);
      handleClose()
    }

    
  }

  useEffect(() => {
    if (selectedRow) {
      setExpiryAction(
        selectedRow?.user_profiles[0]?.profile?.preference
          ?.cgm_expiry_user_action
      );
      setExpiryRemark(
        selectedRow?.user_profiles[0]?.profile?.preference
          ?.cgm_expiry_user_action_remark
      );
      setDeviceCGMKit(selectedRow?.di_kits[0])
      setIsDeviceEdit(false);

      let latestDeviceObject = selectedRow?.user_profiles[0]?.profile?.profile_devices?.reduce((maxObj, current) => {
        return parseInt(current.device_id) > parseInt(maxObj.device_id) ? current : maxObj;
      }, selectedRow.user_profiles[0].profile.profile_devices[0]);

      setDeviceCGMAttachedAt(latestDeviceObject?.device?.attached_at);
      setDeviceCGMExpiredAt(latestDeviceObject?.device?.expiry_date);
    }
  }, [selectedRow]);

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }) : "";
  };

  const [isDeviceEdit, setIsDeviceEdit] = useState(false);
  const [deviceKitTrackingLink, setDeviceKitTrackingLink] = useState(false);
  const [deviceKitOrderID, setDeviceKitOrderID] = useState(false);
  const [deviceKitShipmentStatus, setDeviceKitShipmentStatus] = useState(false);

  const handleDeviceKitEditClick = () => {
    setIsDeviceEdit(true);

    setDeviceKitTrackingLink(deviceCGMKit?.order_tracking_link);
    setDeviceKitOrderID(deviceCGMKit?.order_id);
    setDeviceKitShipmentStatus(deviceCGMKit?.order_status);
  };

  const handleDeviceKitUpdate = async () => {
    setIsPreLoading(true);

    if (
      !deviceKitOrderID?.trim() ||
      !deviceKitShipmentStatus?.trim() ||
      !deviceKitTrackingLink?.trim()
    ) {
      ShowNotification("info", "Please enter all values");
      setIsPreLoading(false); // Set loading state to false
      return;
    }

    let requestData = {
      kits: [
        {
          kit_id: (deviceCGMKit?.id).toString(),
          product: "device_cgm",
          order_id: deviceKitOrderID,
          order_status: deviceKitShipmentStatus,
          order_tracking_link: deviceKitTrackingLink,
        },
      ],
      type: "device_kit",
    };
    try {
      const updateKitResponse = await updateDiKit(requestData);
      getUserActionData(selectedRow?.user_profiles[0]?.user_id);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message);
    } finally {
      setIsDeviceEdit(false);
      setIsPreLoading(false);
      getCGMExpiryUserActionListData();
    }
  };

  return (
    <div>
      <Offcanvas
        className="cgm-expiry-user-action-modal"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>CGM Expiry User Action</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="cgm-action-view">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="info">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <Avatar
                      alt="Login User"
                      src={require("../../../assets/img/avatar-1.png")}
                      className="user-avatar mx-2"
                    />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">
                      Profile ID: {selectedRow?.user_profiles[0]?.profile?.id}
                    </p>
                    <p className="value">
                      User ID: {selectedRow?.user_profiles[0]?.user_id}
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="mx-1"
                        style={{ color: "#4fa3cd" }}
                      />
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} md={6}>
                <div className="text-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "none",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      hanldeViewUserActionData(
                        selectedRow?.user_profiles[0]?.user_id
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faRefresh} className="mx-1" />
                    Refresh
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6} md={6}>
                <div className="info">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <FontAwesomeIcon icon={faCubesStacked} />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">Is Food Criteria Met</p>
                    <p
                      className="value"
                      style={{
                        color: selectedRow?.cc_profile?.is_food_criteria_met
                          ? "green"
                          : "red",
                      }}
                    >
                      {selectedRow?.cc_profile?.is_food_criteria_met === "true"
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} md={6}>
                <div className="info">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <FontAwesomeIcon icon={faBell} />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">Is Notification Send</p>
                    <p
                      className="value"
                      style={{
                        color: selectedRow?.is_notification_send
                          ? "green"
                          : "red",
                      }}
                    >
                      {selectedRow?.is_notification_send === "true"
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>
              </Grid>

              {deviceCGMAttachedAt && (
                <>
                  <Grid item xs={6} md={6}>
                    <div className="info">
                      <IconButton sx={{ p: 0 }} className="icon-button">
                        <FontAwesomeIcon icon={faSyringe} />
                      </IconButton>
                      <div className="content-section">
                        <p className="title">CGM Attached</p>
                        <p className="value">
                          {formatTimestamp(deviceCGMAttachedAt)}
                        </p>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <div className="info">
                      <IconButton sx={{ p: 0 }} className="icon-button">
                        <FontAwesomeIcon icon={faBan} />
                      </IconButton>
                      <div className="content-section">
                        <p className="title">CGM Expiry</p>
                        <p
                          className="value"
                          style={{
                            color: isExpired(deviceCGMExpiredAt)
                              ? "red"
                              : "green",
                          }}
                        >
                          {formatTimestamp(deviceCGMExpiredAt)}
                        </p>
                        <p className="value">
                          <small>
                            ({calculateRemainingDays(deviceCGMExpiredAt)})
                          </small>
                        </p>
                      </div>
                    </div>
                  </Grid>
                </>
              )}

              {selectedRow?.user_profiles[0]?.profile?.preference
                ?.cgm_expiry_user_action && (
                <Grid item xs={6} md={6}>
                  <div className="info">
                    <IconButton sx={{ p: 0 }} className="icon-button">
                      <FontAwesomeIcon icon={faCartShopping} />
                    </IconButton>
                    <div className="content-section">
                      <p className="title">
                        CGM Expiry User Action{" "}
                        <Tooltip title={"Update CGM user action Status"}>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="mx-2"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              setIsCgmEdit(!isCgmEdit);
                            }}
                          />
                        </Tooltip>
                      </p>
                      <p
                        className="value"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {(selectedRow?.user_profiles[0]?.profile?.preference?.cgm_expiry_user_action).replace(
                          /[_\s]/g,
                          " "
                        )}
                      </p>
                    </div>
                  </div>
                </Grid>
              )}

              {/* <Grid item xs={6} md={6}>
                <div className="info justify-content-end align-item-center">
                  <div className="content-section ">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        textTransform: "none",
                        borderRadius: "5px",
                      }}
                      onClick={() => {}}
                    >
                      <FontAwesomeIcon icon={faBell} className="mx-1" />
                      Open Notifications Panel
                    </Button>
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </Card>

          {isCgmEdit && (
            <div className="status-change-section my-3">
              <FormControl fullWidth size="small" className="my-2">
                <InputLabel id="cgm_expiry_action">
                  CGM User expiry action
                </InputLabel>
                <Select
                  labelId="cgm_expiry_action"
                  value={expiryAction}
                  label="CGM User Expiry Action"
                  onChange={(event) => {
                    setExpiryAction(event?.target?.value);
                  }}
                >
                  <MenuItem value={"cst_purchase"}>CST Purchase</MenuItem>
                  <MenuItem value={"cst_continue"}>CST Continue</MenuItem>
                  <MenuItem value={"purchase"}>Purchase</MenuItem>
                  <MenuItem value={"continue"}>Continue</MenuItem>
                </Select>
              </FormControl>
              {(expiryAction === "purchase" || expiryAction === "continue") && (
                <TextField
                  id="remark-cgm-user-action"
                  className="my-2"
                  label="CGM User expiry remark"
                  multiline
                  rows={3}
                  fullWidth
                  size="small"
                  value={expiryRemark}
                  required
                  error={!!expiryError}
                  helperText={expiryError}
                  onChange={(e) => {
                    setExpiryRemark(e.target.value);
                  }}
                />
              )}

              <div className="text-end">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="m-2"
                  style={{
                    textTransform: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                  onClick={() => {
                    setIsCgmEdit(false);
                  }}
                >
                  <FontAwesomeIcon icon={faClose} className="mx-1" />{" "}
                  <span>Cancel</span>
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="my-2"
                  style={{
                    textTransform: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                  onClick={() => {
                    handleUpdateCGMExpiryAction();
                  }}
                >
                  <FontAwesomeIcon icon={faFileAlt} className="mx-1" />{" "}
                  <span>Update Status</span>
                </Button>
              </div>
            </div>
          )}

          {deviceCGMKit &&
            selectedRow?.user_profiles[0]?.profile?.preference
              ?.cgm_expiry_user_action === "purchase" && (
              <section className="cgm-device-kit-view">
                <Card>
                  <Card.Header className="kit-header">
                    <div>
                      <FontAwesomeIcon icon={faBoxesPacking} className="mx-1" />
                      CGM Device Kit Shipment
                    </div>
                    <Chip
                      className="chip-border"
                      color={
                        deviceCGMKit?.order_status === "delivered"
                          ? "success"
                          : deviceCGMKit?.order_status === "processing"
                          ? "warning"
                          : "primary"
                      }
                      size="small"
                      variant="contained"
                      label={deviceCGMKit?.order_status}
                    />
                  </Card.Header>

                  {!isDeviceEdit ? (
                    <Card.Body className="pt-3">
                      <div className="order-info">
                        <p>
                          Order ID:
                          <b>{deviceCGMKit?.order_id || "-"}</b>
                        </p>
                        <p>
                          Tracking link:
                          <a
                            href={deviceCGMKit?.order_tracking_link}
                            target="_blank"
                          >
                            {deviceCGMKit?.order_tracking_link}
                          </a>
                        </p>
                      </div>

                      <div className="order-info">
                        <p>
                          Ordered at:{" "}
                          <b>
                            {deviceCGMKit?.ordered_at
                              ? formatDate(deviceCGMKit?.ordered_at)
                              : ""}
                          </b>
                        </p>

                        {deviceCGMKit?.delivered_at && (
                          <p>
                            Delivered at:{" "}
                            <b>
                              {deviceCGMKit?.delivered_at
                                ? formatDate(deviceCGMKit?.delivered_at)
                                : ""}
                            </b>
                          </p>
                        )}
                      </div>
                      {deviceCGMKit?.order_status !== "delivered" && (
                        <div className="text-end">
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            style={{
                              textTransform: "none",
                              borderRadius: "5px",
                              padding: "5px 10px",
                            }}
                            onClick={() => {
                              handleDeviceKitEditClick();
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} className="mx-1" />
                            Edit
                          </Button>
                        </div>
                      )}
                    </Card.Body>
                  ) : (
                    <Card.Body className="pt-3">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <TextField
                            className="my-0"
                            label="Kit Tracking Link"
                            size={"small"}
                            fullWidth
                            placeholder="Tracking link"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FontAwesomeIcon icon={faLink} />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            value={deviceKitTrackingLink}
                            onChange={(e) => {
                              setDeviceKitTrackingLink(e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            className="my-0"
                            label="Order ID"
                            size={"small"}
                            fullWidth
                            placeholder="Order ID"
                            variant="outlined"
                            value={deviceKitOrderID}
                            onChange={(e) => {
                              setDeviceKitOrderID(e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormControl
                            fullWidth
                            size={"small"}
                            className="my-0"
                          >
                            <InputLabel id="status-select-label">
                              Kit Shipment Status
                            </InputLabel>
                            <Select
                              labelId="status-select-label"
                              label="Kit Shipment Status"
                              value={deviceKitShipmentStatus}
                              onChange={(e) => {
                                setDeviceKitShipmentStatus(e.target.value);
                              }}
                            >
                              <MenuItem value="processing">Processing</MenuItem>
                              <MenuItem value="shipped">Shipped</MenuItem>
                              <MenuItem value="delivered">Delivered</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          className="text-start my-auto"
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                              setIsDeviceEdit(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faClose} className="mx-1" />
                            Close
                          </Button>
                        </Grid>

                        <Grid item xs={12} md={6} className="text-end my-auto">
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                              handleDeviceKitUpdate();
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} className="mx-1" />
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Card.Body>
                  )}
                </Card>
              </section>
            )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CGMExpiryAction