import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Offcanvas } from "react-bootstrap";

const EmailTemplateFullView = ({ show, setShow, codeInput, theme, setTheme }) => {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div>
      <Offcanvas
        className="email-template-fullview"
        show={show}
        onHide={handleClose}
        placement={"top"}
      >
        <Offcanvas.Body>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="preview-close-button"
            style={theme === "dark" ? {color: "white"} : { color: "black"}}
            onClick={() => {
              handleClose(false);
            }}
          />
          <div className={`full-preview`}>
            <iframe
              className={`full-preview-frame`} //
              title="Preview"
              srcDoc={codeInput}
            ></iframe>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default EmailTemplateFullView;
