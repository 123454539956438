import { Grid } from '@mui/material';
import React from 'react'
import "./Home.scss"
import { Card } from 'react-bootstrap';
// import { useDispatch, useSelector } from "react-redux";
// import { addTestUser, editTestUser, setTestUser } from "../../redux/reducers/UserReducer";

const Home = () => {

  document.title = `Dashboard - Deep Holistics`;

  // const dispatch = useDispatch();
  // const { test_user_data } = useSelector((state) => state.UserReducer);

  // useEffect(() => {
  // }, [dispatch]);

  // useEffect(() => {
  // }, [test_user_data]);


  return (
    <div>
      <section>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} sm={3}>
                  <img
                    src={require("../../assets/img/home/img-2.png")}
                    alt=""
                    className="home-image"
                  />
                </Grid>
                <Grid item xs={6} md={3} sm={3}>
                  <img
                    src={require("../../assets/img/home/img-3.png")}
                    alt=""
                    className="home-image"
                  />
                </Grid>
                <Grid item xs={6} md={3} sm={3}>
                  <img
                    src={require("../../assets/img/home/img-4.png")}
                    alt=""
                    className="home-image"
                  />
                </Grid>
                <Grid item xs={6} md={3} sm={3}>
                  <img
                    src={require("../../assets/img/home/img-5.png")}
                    alt=""
                    className="home-image"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={12}>
            <div>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Card className="home-card">
                    <img
                      className="svg-img"
                      src={require("../../assets/img/svg/abstract-1.png")}
                      alt="svg-logo"
                    />

                    <div className="d-flex align-items-center">
                      <img
                        className="card-img"
                        src={require("../../assets/img/dh-logo-dark.png")}
                        alt="dh-logo"
                      />
                      <div className="ms-2">
                        <p className="mb-0">Total Token Users</p>
                        <h5 className="stats-count">-</h5>
                      </div>
                    </div>
                    <Grid
                      container
                      spacing={2}
                      className="g-3 mt-3 text-center"
                    >
                      <Grid item md={6}>
                        <p className="mb-0 text-muted">Current</p>
                        <h5 className="mb-0">-</h5>
                      </Grid>
                      <Grid item md={6}>
                        <p className="mb-0 text-muted">Completed</p>
                        <h5 className="mb-0">-</h5>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card className="home-card">
                    <img
                      className="svg-img"
                      src={require("../../assets/img/svg/abstract-2.png")}
                      alt="svg-logo"
                    />

                    <div className="d-flex align-items-center">
                      <img
                        className="card-img"
                        src={require("../../assets/img/dh-logo-dark.png")}
                        alt="dh-logo"
                      />
                      <div className="ms-2">
                        <p className="mb-0">Upcoming Users</p>
                        <h5 className="stats-count">-</h5>
                      </div>
                    </div>
                    <Grid
                      container
                      spacing={2}
                      className="g-3 mt-3 text-center"
                    >
                      <Grid item md={6}>
                        <p className="mb-0 text-muted">Onboarded</p>
                        <h5 className="mb-0">-</h5>
                      </Grid>
                      <Grid item md={6}>
                        <p className="mb-0 text-muted">Registered</p>
                        <h5 className="mb-0">-</h5>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card className="home-card">
                    <img
                      className="svg-img"
                      src={require("../../assets/img/svg/abstract-3.png")}
                      alt="svg-logo"
                    />

                    <div className="d-flex align-items-center">
                      <img
                        className="card-img"
                        src={require("../../assets/img/dh-logo-dark.png")}
                        alt="dh-logo"
                      />
                      <div className="ms-2">
                        <p className="mb-0">Registered Users</p>
                        <h5 className="stats-count">-</h5>
                      </div>
                    </div>
                    <Grid
                      container
                      spacing={2}
                      className="g-3 mt-3 text-center"
                    >
                      <Grid item md={6}>
                        <p className="mb-0 text-muted">Current</p>
                        <h5 className="mb-0">-</h5>
                      </Grid>
                      <Grid item md={6}>
                        <p className="mb-0 text-muted">Completed</p>
                        <h5 className="mb-0">-</h5>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </section>
    </div>
  );
}

export default Home