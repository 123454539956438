import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useStoreApi, useReactFlow, Panel } from "reactflow";
import MainContext from "../../context/MainContext";

const panelStyle = {
  color: "#777",
  fontSize: 12,
  background: '#ededed',
  padding: '15px',
  margin: '0',
  borderRadius : '5px'
};

export default () => {
  const store = useStoreApi();

  const [searchQuestion, setSearchQuestion] = useState("");
  // const [searchedQuestion, setSearchedQuestion] = useState("")
  // const [currentCount, setCurrentCount] = useState(0)

  let currentCount = 0;
  let previousSearch = "";

  const { setCenter } = useReactFlow();
  const { ShowNotification } = useContext(MainContext);

  const focusNode = () => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    const foundNodes = nodes.filter((node) => {
      return node?.question
        ?.toLowerCase()
        .includes(searchQuestion?.toLowerCase());
    });

    if (foundNodes.length > 0) {
      const searchTerm = searchQuestion.toLowerCase();

      if (searchTerm !== previousSearch) {
        currentCount = 0;
        previousSearch = searchTerm;
      }

      const node = foundNodes[currentCount];

      const x = node.position.x + node.width / 2;
      const y = node.position.y + node.height / 2;
      const zoom = 1.00;

      setCenter(x, y, { zoom, duration: 1000 });
      currentCount = (currentCount + 1) % foundNodes.length;
    }else{
      ShowNotification('warning', 'Not Found')
    }
  };

  return (
    <Panel position="top-right" style={panelStyle}>
      <div className="d-flex">
        <TextField
          label="Search Question"
          variant="outlined"
          value={searchQuestion}
          onChange={(e) => {
            setSearchQuestion(e.target.value);
          }}
          size="small"
        />
        <Button
          variant="contained"
          disabled={!searchQuestion}
          className="assessment-button"
          onClick={() => {
            focusNode();
          }}
        >          
          Find
        </Button>
      </div>
    </Panel>
  );
};
