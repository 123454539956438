import React, { useContext, useEffect, useState } from "react";
import { Card, Offcanvas, Button } from "react-bootstrap";
import "./CustomizeTheme.scss";
import { Grid } from "@mui/material";
import MainContext from "../../context/MainContext";

const CustomizeTheme = ({ show, setShow }) => {

  const handleClose = () => {
    setShow(false);
  };

  const {themeColorCode, setThemeColorCode} = useContext(MainContext)

  const themes = [
    { id: 1, color: '#2f7aaf'},
    { id: 2, color: '#04A9F5'},
    { id: 3, color: '#6610f2'},
    { id: 4, color: '#673ab7'},
    { id: 5, color: '#e83e8c'},
    { id: 6, color: '#F44236'},
    { id: 7, color: '#fd7e14'},
    { id: 8, color: '#F4C22B'},
    { id: 9, color: '#1DE9B6'},
    { id: 10, color: '#008080'},
    { id: 11, color: '#000'},
  ];  

  const handleThemeColorChange = (theme) => {
    setThemeColorCode(theme);
  };
  

  return (
    <div>
      <Offcanvas
        className="customize-theme-section"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Customize Dashboard</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <div className="theme-color">
                <div className="theme-selector">
                  {themes.map((theme) => (
                    <div
                      key={theme.id}
                      className={`color-item ${
                        themeColorCode === theme.color ? "selected" : ""
                      }`}
                      
                      onClick={() => handleThemeColorChange(theme.color)}
                    >
                      <div className={`preset-${theme.id}`} style={{backgroundColor : theme?.color }}></div>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CustomizeTheme;
