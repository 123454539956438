import { faClose, faFileAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Offcanvas, Row } from "react-bootstrap";
import axios from "axios";
import "./NutritionView.scss";
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel, InputAdornment } from "@mui/material";
import { addFood, getCCFoodNutirentsData, updateFood } from "../../../utils/nutritionApi";
import MainContext from "../../../context/MainContext";
import NutritionContext from "../../../context/NutritionContext";

const CreateFood = ({
  show,
  setAddFoodShow,
  setNutrientShow,
  isManualFoodEdit,
  setIsManualFoodEdit,
  fetchFoodListData
}) => {

  const { setIsPreLoading, foodProfileId } = useContext(MainContext);

  const {
    setSelectedFoodDetails,
    setSelectedFoodServing,
    setSelectedFoodMicros,
    transferCCFoodData
  } = useContext(NutritionContext);

  //Close Food Modal
  const handleClose = () => {
    setIsManualFoodEdit (false);
    setAddFoodShow(false);
  };

  // Serving input Fields
  const servingFields = [
    {
      label: "Metric Serving Amount *",
      key: "metric_serving_amount",
      type: "number",
      unit: "",
    },
    {
      label: "Metric Serving Unit *",
      key: "metric_serving_unit",
      type: "text",
      unit: "",
    },
    {
      label: "Number of Units *",
      key: "number_of_units",
      type: "number",
      unit: "",
    },
    { label: "Description", key: "description", type: "text", unit: "" },
    {
      label: "Measurement Description *",
      key: "measurement_description",
      type: "text",
      unit: "",
    },
    { label: "Calories *", key: "calories", type: "number", unit: "Cals" },
    { label: "Carbohydrate *", key: "carbohydrate", type: "number", unit: "g" },
    { label: "Protein *", key: "protein", type: "number", unit: "g" },
    { label: "Fat *", key: "fat", type: "number", unit: "g" },
    { label: "Fiber", key: "fiber", type: "number", unit: "g" },
    { label: "Trans Fat", key: "trans_fat", type: "number", unit: "g" },
    { label: "Cholesterol", key: "cholesterol", type: "number", unit: "mg" },
    { label: "Sodium", key: "sodium", type: "number", unit: "g" },
    { label: "Potassium", key: "potassium", type: "number", unit: "g" },
    { label: "Sugar", key: "sugar", type: "number", unit: "g" },
    { label: "Saturated Fat", key: "saturated_fat", type: "number", unit: "g" },
    {
      label: "Polyunsaturated Fat",
      key: "polyunsaturated_fat",
      type: "number",
      unit: "g",
    },
    {
      label: "Monounsaturated Fat",
      key: "monounsaturated_fat",
      type: "number",
      unit: "g",
    },
    { label: "Added Sugars", key: "added_sugars", type: "number", unit: "g" },
    { label: "Vitamin D", key: "vitamin_d", type: "number", unit: "mg" },
    { label: "Vitamin A", key: "vitamin_a", type: "number", unit: "mcg" },
    { label: "Vitamin C", key: "vitamin_c", type: "number", unit: "mcg" },
    { label: "Calcium", key: "calcium", type: "number", unit: "g" },
    { label: "Iron", key: "iron", type: "number", unit: "mg" },
  ];

  //Serving Default Data
  let servingDefaultData = [
    {
      calories: "",
      carbohydrate: "",
      protein: "",
      fat: "",
      fiber: "",
      saturated_fat: "",
      polyunsaturated_fat: "",
      monounsaturated_fat: "",
      trans_fat: "",
      cholesterol: "",
      sodium: "",
      potassium: "",
      sugar: "",
      added_sugars: "",
      vitamin_d: "",
      vitamin_a: "",
      vitamin_c: "",
      calcium: "",
      iron: "",
      metric_serving_amount: "",
      metric_serving_unit: "",
      number_of_units: "",
      description: "",
      measurement_description: "",
      is_default: true,
    },
  ];

  //Default Food Data
  let foodDefaultData = {
    name: "",
    glycemic_index: "",
    glycemic_index_multiplier: "",
    glycemic_index_ai: "",
  };

  const [foodObject, setFoodObject] = useState([]);
  const [servingFoodData, setServingFoodData] = useState([]);

  //Add - Serving Default Data
  const handleAddServing = () => {
    setServingFoodData((prevData) => [
      ...prevData,
      ...servingDefaultData?.map((serving) => ({
        ...serving,
        is_default: false,
      })),
    ]);
  };

  useEffect(() => {
    setServingFoodData(servingDefaultData);
    setFoodObject(foodDefaultData);
  }, [show]);

  //Serving Input Change
  const handleServingInputChange = (event, dataIndex, fieldLabel) => {
    const newValue = event.target.value;

    setServingFoodData((prevData) => {
      const newData = [...prevData];
      newData[dataIndex] = { ...newData[dataIndex], [fieldLabel]: newValue };
      return newData;
    });
  };

  //Serving Default Checkbox Change
  const handleisDefaultChange = (event, dataIndex, fieldLabel) => {
    const newValue = !event;
    if (newValue) {
      setServingFoodData((prevData) => {
        const newData = prevData.map((item, index) => {
          if (index === dataIndex) {
            return { ...item, [fieldLabel]: newValue };
          } else {
            return { ...item, is_default: false };
          }
        });
        return newData;
      });
    } else {
      setServingFoodData((prevData) => {
        const newData = prevData.map((item, index) => {
          if (index === 0) {
            return { ...item, is_default: true };
          } else {
            return { ...item, is_default: false };
          }
        });
        return newData;
      });
    }
  };

  //Food Object input Onchange
  const handleFoodInputChange = (e) => {
    const { name, value } = e.target;
    setFoodObject((prevFoodObject) => ({
      ...prevFoodObject,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isManualFoodEdit) {
      setFoodObject(transferCCFoodData);
      setServingFoodData(transferCCFoodData?.cc_servings);
    } else {
      setServingFoodData(servingDefaultData);
      setFoodObject(foodDefaultData);
    }
  }, [isManualFoodEdit]);

  const { headers, ShowNotification } = useContext(MainContext);
  // const { REACT_APP_BASE__URL } = process.env;

  //Save Food
  const handleSaveManualFood = async () => {
    setIsPreLoading(true);
  
    const requestData = {
      cc_food: foodObject,
      cc_servings: servingFoodData,
    };

    try {
      const addFoodResponse = await addFood(requestData);
      fetchFoodListData();
      handleClose();
      const addResponse = addFoodResponse?.data?.data?.cc_food;

      const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
        addResponse?.food_id,
        foodProfileId
      );

      let responseData = CCFoodNutrientsResponse?.data;

      setSelectedFoodDetails(responseData?.cc_food);
      setSelectedFoodServing(
        responseData?.cc_food?.cc_servings?.find((item) => item.is_default) ||
          responseData?.cc_food?.cc_servings[0]
      );
      setSelectedFoodMicros(responseData?.micros);
      setIsPreLoading(false);
      // setNutrientShow(true);

      ShowNotification("success", "Food Added Successfully");

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsPreLoading(false);
    }
  
    //Old
    // const apiUrl = `${REACT_APP_BASE__URL}/v2/calorie-counter/food/add`;
  
    // try {
    //   const response = await axios.post(apiUrl, requestData, { headers });
    //   fetchFoodListData();
    //   handleClose();
  
    //   const addResponse = response?.data?.data?.cc_food;
  
    //   const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
    //     addResponse?.food_id,
    //     foodProfileId
    //   );
  
    //   let responseData = CCFoodNutrientsResponse?.data;
  
    //   setSelectedFoodDetails(responseData?.cc_food);
    //   setSelectedFoodServing(
    //     responseData?.cc_food?.cc_servings?.find(
    //       (item) => item.is_default
    //     ) || responseData?.cc_food?.cc_servings[0]
    //   );
    //   setSelectedFoodMicros(responseData?.micros);
    //   setIsPreLoading(false);
    //   setNutrientShow(true);
  
    //   ShowNotification("success", "Food Added Successfully");
    // } catch (error) {
    //   console.error(
    //     'Error fetching data:',
    //     error.response ? error.response.data : error.message
    //   );
    //   setIsPreLoading(false);
    //   ShowNotification("error", "Please Enter Mandatory fields");
    // } finally {
    //   setIsPreLoading(false);
    // }
  };

  //Update Food
  const handleUpdateManualFood = async () => {
    const requestData = {
      cc_food: foodObject,
      cc_servings: servingFoodData,
    };

    const requestId = foodObject?.id;

    try {
      const updateFoodResponse = await updateFood(requestData, requestId);

      fetchFoodListData();
      handleClose();
  
      const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
        foodObject?.food_id,
        foodProfileId
      );

      let responseData = CCFoodNutrientsResponse?.data;
  
      setSelectedFoodDetails(responseData?.cc_food);
      setSelectedFoodServing(
        responseData?.cc_food?.cc_servings?.find(
          (item) => item.is_default
        ) || responseData?.cc_food?.cc_servings[0]
      );
      setSelectedFoodMicros(responseData?.micros);
      setIsPreLoading(false);
      setNutrientShow(true);
  
      ShowNotification("success", "Food & Serving Updated Successfully");
     

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsPreLoading(false);
    }
  
    // const apiUrl = `${REACT_APP_BASE__URL}/v2/calorie-counter/food/${foodObject?.id}`;
  
    // try {
    //   const updateResponse = await axios.put(apiUrl, requestData, { headers });
    //   fetchFoodListData();
    //   handleClose();
  
    //   const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
    //     foodObject?.food_id,
    //     foodProfileId
    //   );
  
    //   let responseData = CCFoodNutrientsResponse?.data;
  
    //   setSelectedFoodDetails(responseData?.cc_food);
    //   setSelectedFoodServing(
    //     responseData?.cc_food?.cc_servings?.find(
    //       (item) => item.is_default
    //     ) || responseData?.cc_food?.cc_servings[0]
    //   );
    //   setSelectedFoodMicros(responseData?.micros);
    //   setIsPreLoading(false);
    //   setNutrientShow(true);
  
    //   ShowNotification("success", "Food & Serving Updated Successfully");
    // } catch (error) {
    //   console.error(
    //     'Error updating data:',
    //     error.response ? error.response.data : error.message
    //   );
    //   setIsPreLoading(false);
    //   ShowNotification("error", "Please Enter Mandatory fields");
    // }
  };

  // Remove Serving
  const handleRemoveServing = (dataIndex) => {
    if (servingFoodData?.length > 1) {
      const updatedData = servingFoodData.filter(
        (_, index) => index !== dataIndex
      );
      if (updatedData?.length === 1) {
        updatedData[0].is_default = true;
      }
      setServingFoodData(updatedData);
    }
  };

  return (
    <div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="mb-0">
              {isManualFoodEdit ? "Update" : "Add"} Food Manual
            </h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="ScrollDesign Manual-food-add">
          <Card className="mb-2">
            <Card.Header> Food info </Card.Header>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <TextField
                    label="Food Name"
                    className="mb-3"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="name"
                    value={foodObject["name"]}
                    onChange={handleFoodInputChange}
                  />
                </Col>
                <Col md={4}>
                  <TextField
                    label="GI"
                    className="mb-1"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    name="glycemic_index"
                    value={foodObject["glycemic_index"]}
                    onChange={handleFoodInputChange}
                  />
                </Col>
                <Col md={4}>
                  <TextField
                    label="GI Multiplier "
                    className="mb-1"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    name="glycemic_index_multiplier"
                    value={foodObject["glycemic_index_multiplier"]}
                    onChange={handleFoodInputChange}
                  />
                </Col>
                <Col md={4}>
                  <TextField
                    label="GI AI"
                    className="mb-1"
                    variant="outlined"
                    size="small"
                    type="number"
                    name="glycemic_index_ai"
                    fullWidth
                    value={foodObject["glycemic_index_ai"]}
                    onChange={handleFoodInputChange}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <section>
            <Row>
              <Col md={6}>
                <div className="text-left">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="mx-2 viewBtn"
                    onClick={() => {
                      handleAddServing();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Serving
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <div className="text-end">
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="mx-2 viewBtn"
                    onClick={() => {
                      if (isManualFoodEdit) {
                        handleUpdateManualFood();
                      } else {
                        handleSaveManualFood();
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faFileAlt} />{" "}
                    {isManualFoodEdit ? "Update" : "Save"} Food
                  </Button>
                </div>
              </Col>
            </Row>

            {servingFoodData?.map((data, dataIndex) => (
              <Card key={dataIndex} className="my-2">
                <Card.Header className="add-food-serving-header">
                  <span>Serving - {dataIndex + 1}</span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data["is_default"]}
                        className="p-0"
                        onChange={(event) => {
                          handleisDefaultChange(
                            !event.target.checked,
                            dataIndex,
                            "is_default"
                          );
                        }}
                      />
                    }
                    className="float-end"
                    label="Is Default"
                  />
                  <span>
                    {servingFoodData?.length > 1 && (
                      <FontAwesomeIcon
                        icon={faClose}
                        className="btn btn-danger p-1"
                        onClick={() => {
                          handleRemoveServing(dataIndex);
                        }}
                      />
                    )}
                  </span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {servingFields.map((field, index) => (
                      <Col key={index} md={4} className="px-1">
                        <TextField
                          label={field.label}
                          className="mb-2"
                          variant="outlined"
                          size="small"
                          type={field.type}
                          inputProps={field.type === "number" ? { min: 0 } : {}}
                          fullWidth
                          value={data[field.key]}
                          onChange={(event) =>
                            handleServingInputChange(event, dataIndex, field.key)
                          }
                          InputProps={{
                            endAdornment: field?.unit && (
                              <InputAdornment
                                position="end"
                                className="inputGroup-Unit"
                              >
                                {field.unit}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CreateFood;
