import React, { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Form, Offcanvas, Row, Tab, Tabs } from "react-bootstrap";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Tooltip,
} from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faClock,
  faClose,
  faSave,
  faSearch,
  faUser,
  faUtensils,
  faBreadSlice,
  faBacon,
  faPuzzlePiece,
  faWheatAwn,
  faMaximize,
  faExpand,
  faFile,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import NutritionContext from "../../../context/NutritionContext";
import axios from "axios";
import {
  getCCFoodList,
  getCCFoodNutirentsData,
  getCGMGraphData,
  suggestFoodSearch,
  updateFoodIntake,
} from "../../../utils/nutritionApi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/material/styles";
import Icon1 from "../../../assets/img/icon/icon-5.png";
import Icon2 from "../../../assets/img/icon/icon-6.png";
import MainContext from "../../../context/MainContext";
import Swal from "sweetalert2";
import CGMChart from "./CGMChart";

const FoodIntakeReview = ({
  show,
  setShow,
  selectedFoodIntake,
  getFoodIntakeList,
}) => {
  const handleClose = () => {
    setShow(false);
    setLightboxShow(false);
    setLightboxImgUrl("");
  };

  const divRef = useRef();
  const [suggestionsShown, setSuggestionsShown] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [intakeFoodByTool, setIntakeFoodByTool] = useState([]);
  const [intakeFoodByApp, setIntakeFoodByApp] = useState([]);
  const [intakeFoodIsVerified, setIntakeFoodIsVerified] = useState(false);

  const [isSuggestedFood, setIsSuggestedFood] = useState(false);
  const [isOutsideFood, setIsOutsideFood] = useState(false);
  const [isCannotIdentify, setIsCannotIdentify] = useState(false);
  const [isPartialCaloricValUpdated, setIsPartialCaloricValUpdated] = useState(false);

  const {
    headers,
    accessToken,
    foodProfileId,
    setIsPreLoading,
    ShowNotification,
  } = useContext(MainContext);

  const { foodServingUnitArray, setInitialRenderUCFood, setInitialRender } =
    useContext(NutritionContext);
  const { REACT_APP_BASE__URL } = process.env;

  const [inputLoading, setInputLoading] = useState(false);

  /* Food Search - API */
  const handleInputChange = async (e) => {
    setInputLoading(true);
    const inputValue = e.target.value;
    setSelectedInput(inputValue);

    //Updated one - from FATSECRET
    const data = {
      search_expression: inputValue,
      food_profile_id: foodProfileId,
      is_admin: true,
    };

    /*  */
    try {
      if (inputValue?.length > 0) {
        // const CCFoodSearchResponse = await suggestFoodSearch(data);
        // let responseData =
        //   CCFoodSearchResponse?.data?.search_result?.food?.foods;
        // setSuggestions(responseData);

        const CCFoodSearchResponse = await getCCFoodList(
          `?limit=20&offset=0&search=${inputValue}&order=ASC&orderBy=name`,
          accessToken
        );
        let responseData = CCFoodSearchResponse?.data?.cc_food;
        setSuggestions(responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setInputLoading(false);
    } finally {
      setInputLoading(false);
    }

    if (!suggestionsShown && inputValue.trim() !== "") {
      setSuggestionsShown(true);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setIsPreLoading(true);
    let foodSuggestion = [];

    //Updated Code from get FatSecret
    try {
      const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
        suggestion?.food_id,
        foodProfileId
      );

      foodSuggestion = CCFoodNutrientsResponse?.data?.cc_food;

      let findSuggestion = intakeFoodByTool?.filter(
        (item) =>
          item?.id === foodSuggestion?.id && item?.is_added_by_smitch === true
      );

      if (
        findSuggestion?.length === 0 ||
        findSuggestion?.length === undefined
      ) {
        setSuggestionsShown(false);
        setSelectedInput("");

        let initDefault = foodSuggestion?.cc_servings?.find(
          (item) => item.is_default
        );

        const updatedFoodSuggestion = {
          ...foodSuggestion,
          selected_serving: initDefault,
          is_edited: true,
          unit_count: 1,
          number_of_units: initDefault["number_of_units"],
          is_added_by_smitch: true,
        };

        setIntakeFoodByTool((prevIntakeFoods) => [
          ...(prevIntakeFoods || []),
          updatedFoodSuggestion,
        ]);
      } else {
        ShowNotification("info", "Food already Added...");
        setSuggestionsShown(false);
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      setIsPreLoading(false);
    } finally {
      setIsPreLoading(false);
    }
  };

  /* Meal Tracker */
  const handleRemoveFood = (dataIndex) => {
    const updatedData = intakeFoodByTool.map((food, index) => {
      if (index === dataIndex) {
        return {
          ...food,
          to_be_deleted: true,
        };
      }
      return food;
    });

    setIntakeFoodByTool(updatedData);
  };

  const handleAddRemovedFood = (dataIndex) => {
    const updatedData = intakeFoodByTool.map((food, index) => {
      if (index === dataIndex) {
        return {
          ...food,
          to_be_deleted: false,
        };
      }
      return food;
    });

    setIntakeFoodByTool(updatedData);
  };

  const handleServingUnitChange = (unitValue, foodId) => {
    setIntakeFoodByTool(
      intakeFoodByTool.map((food) => {
        if (food.id === foodId) {
          return {
            ...food,
            number_of_units: unitValue,
            unit_count: unitValue / food.selected_serving.number_of_units,
          };
        }
        return food;
      })
    );
  };

  const handleServingOptionChange = (servingId, foodId) => {
    let requestData = intakeFoodByTool.map((food) => {
      if (food?.id === foodId) {
        let selectedServing = food?.cc_servings?.find(
          (item) => item.id === servingId
        );
        return {
          ...food,
          selected_serving: selectedServing,
          number_of_units: selectedServing?.number_of_units,
          unit_count: 1,
        };
      } else {
        return food;
      }
    });
    setIntakeFoodByTool(requestData);
  };

  const handleGetNutrients = async (foodId) => {
    setIsPreLoading(true);
    try {
      const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
        foodId,
        foodProfileId
      );

      let responseData = CCFoodNutrientsResponse?.data;

      const updatedData = intakeFoodByTool.map((food) => {
        if (food.food_id === foodId) {
          return {
            ...food,
            cc_servings: responseData?.cc_food?.cc_servings,
            is_edited: true,
          };
        }
        return food;
      });

      setIntakeFoodByTool(updatedData);
      setIsPreLoading(false);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      setIsPreLoading(false);
    } finally {
    }
  };

  //Update Food Intake log - Image
  const handleUpdateFoodIntake = async (verifiedStatus) => {
    setIsPreLoading(true);

    let foodObj = intakeFoodByTool?.map((food) => {
      return {
        serving_id: food?.selected_serving?.id,
        serving_id_meta: food?.selected_serving?.serving_id,
        number_of_units: food?.number_of_units,
        unit_count: food?.unit_count, //Optional
        food_id: food?.id,
        food_id_meta: food?.food_id,
        to_be_deleted: !!food?.to_be_deleted,
        food_intake_id: food?.food_intake_id || null,
      };
    });

    /*  */

    let toolIntakeFood = selectedFoodIntake?.cc_food_intakes?.filter(
      (item) => item?.is_added_by_smitch
    );
    const foodRemObj = toolIntakeFood
      ?.map((serving) => {
        const selectedServing = foodObj?.find(
          (item) => item?.serving_id_meta === serving?.cc_serving?.serving_id
        );

        return selectedServing
          ? null
          : {
              serving_id: serving?.serving_id,
              serving_id_meta: serving?.cc_serving?.serving_id,
              number_of_units: serving?.number_of_units,
              food_intake_id: serving?.id,
              food_id: serving?.cc_serving?.cc_food?.id,
              food_id_meta: serving?.cc_serving?.cc_food?.food_id,
              to_be_deleted: true,
            };
      })
      .filter(Boolean);

    let combinedServingArray = [...(foodRemObj || []), ...foodObj];

    /*  */

    let requestData = {
      food_intake_summary_id: selectedFoodIntake?.id,
      intake_at: selectedFoodIntake?.intake_at,
      meal_type: selectedFoodIntake?.meal_type,
      is_completed: verifiedStatus, //intakeFoodIsVerified,
      servings: combinedServingArray,
      food_profile_id: selectedFoodIntake?.food_profile_id, //selectedFoodIntake?.food_profile_id,
      is_outside_food: isOutsideFood,
      is_suggested_food: isSuggestedFood,
      is_unidentified_food: isCannotIdentify,
      is_partial_caloric_value_updated: isPartialCaloricValUpdated,
      // is_admin: true,
    };

    try {
      const updateFoodIntakeResponse = await updateFoodIntake(requestData);

      setShow(false);

      ShowNotification("success", "Food intake Updated SuccessFully");
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message);
    } finally {
      setInitialRenderUCFood(true);
      setInitialRender(true);
      getFoodIntakeList();
      // setIsPreLoading(false);
    }

    //old One
    // const apiUrl = `${REACT_APP_BASE__URL}/v3/int/human-token/nutrition/food-intake`;

    // axios
    //   .put(apiUrl, requestData, { headers })
    //   .then((response) => {
    //     // const responseData = response?.data?.data?.cc_food_intakes;
    //     getFoodIntakeList();
    //     setShow(false);

    //     ShowNotification("success", "Food intake Updated SuccessFully");
    //   })
    //   .catch((error) => {
    //     console.error(error.response.data.message);
    //     setIsPreLoading(false);
    //     ShowNotification("error", "Please Check Reviewed Foods");
    //   });
  };

  const [cgmGraphData, setCgmGraphData] = useState([])

  const handleGetCGMGraphAPI = async () => {

    let requestParam = {
      start_time: selectedFoodIntake?.intake_at,
      meal_type: selectedFoodIntake?.meal_type,
      food_profile_id: selectedFoodIntake?.food_profile_id
    }

    try {
      const FoodIntakeCGMDataResponse = await getCGMGraphData(requestParam);
      setCgmGraphData(FoodIntakeCGMDataResponse?.data?.cgm_graph)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
      // setIsPreLoading(false);
    }
  }

  //Initial Convert on intakeFood
  useEffect(() => {
    if (show) {
      setIntakeFoodIsVerified(
        selectedFoodIntake?.validated_by_user_id ? true : false
      );
      setIsSuggestedFood(selectedFoodIntake?.is_suggested_food ? true : false)
      setIsOutsideFood(selectedFoodIntake?.is_outside_food ? true : false)
      setIsCannotIdentify(
        selectedFoodIntake?.is_unidentified_food ? true : false
      )
      setIsPartialCaloricValUpdated(
        selectedFoodIntake?.is_partial_caloric_value_updated ? true : false
      )

      let convertData = selectedFoodIntake?.cc_food_intakes?.map(
        ({ cc_serving, number_of_units, ...cc_food_intakes }) => {
          const { cc_food, ...selectedServing } = cc_serving || {};
          const foodIntake = cc_food_intakes || {};

          return {
            ...cc_food,
            selected_serving: selectedServing,
            cc_servings: [selectedServing],
            number_of_units: number_of_units,
            unit_count: number_of_units / selectedServing?.number_of_units,
            is_edited: false,
            //Extra Param
            to_be_deleted: false,
            food_intake_id: foodIntake?.id || null,
            is_added_by_smitch: foodIntake?.is_added_by_smitch,
          };
        }
      );

      setIntakeFoodByTool(
        convertData?.filter((item) => item?.is_added_by_smitch)
      );
      setIntakeFoodByApp(
        convertData?.filter((item) => !item?.is_added_by_smitch)
      );

      handleGetCGMGraphAPI();
    }
  }, [show]);

  //Suggestion close on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setSuggestionsShown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  //Slider Settings
  const settings = {
    customPaging: function (i) {
      const food = selectedFoodIntake?.cc_intake_images?.slice(0, 5)?.[i];
      return (
        // <a href={food?.thumbnail_url}>
        <img src={food?.thumbnail_url} alt={`Image`} />
        // </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  /* Switch */
  const VerifySwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${Icon1})`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${Icon2})`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  //Format Time Stamp
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();

    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");

    return (
      <div>
        {`${dd}-${mm}-${yyyy}`} <small>{`${hh}:${min}:${ss}`}</small>
      </div>
    );
  }

  //Verification Toggle Switch Change
  const handleVerificationChange = (event) => {
    setIntakeFoodIsVerified(event.target.checked);
  };

  /* Overall Nutrients */

  const [allMicroNutrient, setAllMicroNutrient] = useState([]);

  useEffect(() => {
    const transformedFoodByToolData = intakeFoodByTool.map((food) => {
      const {
        selected_serving: selectedServing,
        number_of_units: numberOfUnits,
      } = food;

      const unit_count =
        numberOfUnits && selectedServing?.number_of_units
          ? numberOfUnits / selectedServing.number_of_units
          : 0;

      const multiplier = unit_count || 0;

      return {
        fat: multiplier * parseFloat(selectedServing?.fat || 0),
        calories: multiplier * parseFloat(selectedServing?.calories || 0),
        saturated_fat:
          multiplier * parseFloat(selectedServing?.saturated_fat || 0),
        trans_fat: multiplier * parseFloat(selectedServing?.trans_fat || 0),
        polyunsaturated_fat:
          multiplier * parseFloat(selectedServing?.polyunsaturated_fat || 0),
        monounsaturated_fat:
          multiplier * parseFloat(selectedServing?.monounsaturated_fat || 0),
        cholesterol: multiplier * parseFloat(selectedServing?.cholesterol || 0),
        carbohydrate:
          multiplier * parseFloat(selectedServing?.carbohydrate || 0),
        fiber: multiplier * parseFloat(selectedServing?.fiber || 0),
        sugar: multiplier * parseFloat(selectedServing?.sugar || 0),
        sodium: multiplier * parseFloat(selectedServing?.sodium || 0),
        protein: multiplier * parseFloat(selectedServing?.protein || 0),
        vitamin_d: multiplier * parseFloat(selectedServing?.vitamin_d || 0),
        calcium: multiplier * parseFloat(selectedServing?.calcium || 0),
        iron: multiplier * parseFloat(selectedServing?.iron || 0),
        potassium: multiplier * parseFloat(selectedServing?.potassium || 0),
        vitamin_a: multiplier * parseFloat(selectedServing?.vitamin_a || 0),
        vitamin_c: multiplier * parseFloat(selectedServing?.vitamin_c || 0),
      };
    });

    const transformedFoodByAppData = intakeFoodByApp.map((food) => {
      const {
        selected_serving: selectedServing,
        number_of_units: numberOfUnits,
      } = food;

      const unit_count =
        numberOfUnits && selectedServing?.number_of_units
          ? numberOfUnits / selectedServing.number_of_units
          : 0;

      const multiplier = unit_count || 0;

      return {
        fat: multiplier * parseFloat(selectedServing?.fat || 0),
        calories: multiplier * parseFloat(selectedServing?.calories || 0),
        saturated_fat:
          multiplier * parseFloat(selectedServing?.saturated_fat || 0),
        trans_fat: multiplier * parseFloat(selectedServing?.trans_fat || 0),
        polyunsaturated_fat:
          multiplier * parseFloat(selectedServing?.polyunsaturated_fat || 0),
        monounsaturated_fat:
          multiplier * parseFloat(selectedServing?.monounsaturated_fat || 0),
        cholesterol: multiplier * parseFloat(selectedServing?.cholesterol || 0),
        carbohydrate:
          multiplier * parseFloat(selectedServing?.carbohydrate || 0),
        fiber: multiplier * parseFloat(selectedServing?.fiber || 0),
        sugar: multiplier * parseFloat(selectedServing?.sugar || 0),
        sodium: multiplier * parseFloat(selectedServing?.sodium || 0),
        protein: multiplier * parseFloat(selectedServing?.protein || 0),
        vitamin_d: multiplier * parseFloat(selectedServing?.vitamin_d || 0),
        calcium: multiplier * parseFloat(selectedServing?.calcium || 0),
        iron: multiplier * parseFloat(selectedServing?.iron || 0),
        potassium: multiplier * parseFloat(selectedServing?.potassium || 0),
        vitamin_a: multiplier * parseFloat(selectedServing?.vitamin_a || 0),
        vitamin_c: multiplier * parseFloat(selectedServing?.vitamin_c || 0),
      };
    });

    const transformedData = [
      ...transformedFoodByToolData,
      ...transformedFoodByAppData,
    ];

    const calculateSum = () => {
      return transformedData.reduce((sumObject, obj) => {
        for (let key in obj) {
          sumObject[key] = (sumObject[key] || 0) + obj[key];
        }
        return sumObject;
      }, {});
    };

    setAllMicroNutrient(calculateSum());
  }, [intakeFoodByTool]);

  const nutrients = [
    {
      label: "Carbs",
      icon: faBreadSlice,
      color: "Green",
      property: "carbohydrate",
    },
    { label: "Protein", icon: faBacon, color: "Yellow", property: "protein" },
    { label: "Fat", icon: faPuzzlePiece, color: "Red", property: "fat" },
    {
      label: "Fiber",
      icon: faWheatAwn,
      color: "lightGreen",
      property: "fiber",
    },
  ];

  // Nutrient component to display each individual nutrient
  const MicroNutrient = ({ label, value, icon, color }) => (
    <Grid item md={3} xs={6} className="text-center">
      <div className="item">
        <h4>
          {value === 0 || value ? parseFloat(value).toFixed(1) : "--"}
          <span>{allMicroNutrient?.metric_serving_unit}</span>
        </h4>
        <FontAwesomeIcon icon={icon} style={{ color }} /> {label}
      </div>
    </Grid>
  );

  const NutrientComponent = ({ servingData }) => {
    const nutrientsLeft = [
      { name: "Total Fat", key: "fat", unit: "g" },
      { name: "Saturated Fat", key: "saturated_fat", sub: true, unit: "g" },
      { name: "Trans Fat", key: "trans_fat", sub: true, unit: "g" },
      {
        name: "Polyunsaturated Fat",
        key: "polyunsaturated_fat",
        sub: true,
        unit: "g",
      },
      {
        name: "Monounsaturated Fat",
        key: "monounsaturated_fat",
        sub: true,
        unit: "g",
      },
      { name: "Cholesterol", key: "cholesterol", unit: "mg" },
      { name: "Total Carbohydrate", key: "carbohydrate", unit: "g" },
      { name: "Dietary Fiber", key: "fiber", unit: "g", sub: true },
      { name: "Sugars", key: "sugar", unit: "g", sub: true },
    ];

    const nutrientsRight = [
      { name: "Sodium", key: "sodium", unit: "mg" },
      { name: "Protein", key: "protein", unit: "g" },
      { name: "Vitamin D", key: "vitamin_d", unit: "mcg" },
      { name: "Calcium", key: "calcium", unit: "mg" },
      { name: "Iron", key: "iron", unit: "mg" },
      { name: "Potassium", key: "potassium", unit: "mg" },
      { name: "Vitamin A", key: "vitamin_a", unit: "mcg" },
      { name: "Vitamin C", key: "vitamin_c", unit: "mg" },
    ];
    return (
      <Row className="m-0">
        <NutrientColumn nutrients={nutrientsLeft} servingData={servingData} />
        <NutrientColumn nutrients={nutrientsRight} servingData={servingData} />
      </Row>
    );
  };

  const Nutrient = ({ name, value, percentage, unit = "mg", sub = false }) => (
    <div className={`flex-nutrient${sub ? " sub" : ""}`}>
      <span>{name} &emsp; </span>
      <span>
        {value !== null && value !== undefined
          ? `${parseFloat(value).toFixed(1)} ${unit}`
          : `-- ${unit}`}
      </span>
    </div>
  );

  const NutrientColumn = ({ nutrients, servingData }) => {
    const { number_of_units, cc_serving } = servingData;

    return (
      <Col md={6} className="m-0 px-1">
        {nutrients.map((nutrient) => (
          <React.Fragment key={nutrient.key}>
            <Nutrient
              name={nutrient?.name}
              value={servingData?.[nutrient.key]}
              unit={nutrient.unit}
              sub={nutrient.sub}
            />
            {nutrient.hr && <hr className="hr-1" />}
          </React.Fragment>
        ))}
      </Col>
    );
  };

  /*  */

  const [lightboxShow, setLightboxShow] = useState(false);
  const [lightboxImgUrl, setLightboxImgUrl] = useState("");

  return (
    <div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="top"
        className="food-intake-view"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="Intake-info-header">
            <div className="user-name">
              <FontAwesomeIcon icon={faUser} className="mx-1" />
              Food Profile ID: {selectedFoodIntake?.food_profile_id}
            </div>
            <div className="meal-type">
              <FontAwesomeIcon icon={faUtensils} className="mx-1" />
              {selectedFoodIntake.meal_type}
            </div>
            <div className="log-time d-flex align-items-center">
              <FontAwesomeIcon icon={faClock} className="mx-1" />{" "}
              {formatTimestamp(selectedFoodIntake.intake_at)}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="ScrollDesign">
          <div className={`lightbox ${lightboxShow ? "show" : ""} `}>
            <FontAwesomeIcon
              icon={faClose}
              className="close"
              onClick={() => {
                setLightboxShow(false);
              }}
            />
            <img src={lightboxImgUrl} alt="whatsapp-image" loading="lazy" />
          </div>

          <Tabs defaultActiveKey="review" className="mb-3" fill>
            <Tab eventKey="review" title="Intake Review">
              <Row>
                {selectedFoodIntake?.n_image !== 0 && (
                  <Col md={5}>
                    <div className="slider-container">
                      <Slider {...settings}>
                        {selectedFoodIntake?.cc_intake_images
                          ?.slice(0, 5)
                          ?.map((food, index) => (
                            <div
                              className={`meal-log-image grid-item`}
                              key={index}
                            >
                              <Tooltip title={"Maximize image"}>
                                <FontAwesomeIcon
                                  icon={faExpand}
                                  className="maximize-icon"
                                  onClick={() => {
                                    setLightboxImgUrl(food?.url);
                                    setLightboxShow(true);
                                  }}
                                />
                              </Tooltip>
                              <img
                                src={food?.thumbnail_url}
                                alt=""
                                className="grid-image"
                              />
                            </div>
                          ))}
                      </Slider>
                    </div>
                  </Col>
                )}
                <Col
                  md={selectedFoodIntake?.n_image !== 0 ? "7" : "12"}
                  className="food-intake-add"
                >
                  <div
                    className={`food-intake-search-section ${
                      selectedFoodIntake?.n_image !== 0 ? "" : "hide"
                    }`}
                    ref={divRef}
                  >
                    <Paper
                      className="border border-secondary"
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <IconButton sx={{ p: "5px" }} aria-label="menu">
                        <FontAwesomeIcon icon={faSearch} />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Food Name"
                        inputProps={{ "aria-label": "Search Food Name" }}
                        value={selectedInput}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleInputChange(e);
                          }
                        }}
                      />
                      {inputLoading && (
                        <CircularProgress
                          size={24}
                          className="mx-1"
                          thickness="5"
                        />
                      )}
                      <Divider
                        sx={{ height: 28, m: 0.5 }}
                        orientation="vertical"
                      />
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        className="py-2 food-search-clear"
                        onClick={() => {
                          setSelectedInput("");
                        }}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </IconButton>
                    </Paper>
                    <Dropdown
                      show={
                        suggestionsShown &&
                        selectedInput?.length > 0 &&
                        suggestions?.length > 0
                      }
                    >
                      <Dropdown.Menu className="ScrollDesign">
                        {suggestions?.map((suggestion, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {/* <div className="search-dropdown">
                          <span className="food-name">
                            {suggestion?.food_name} <br />{" "}
                            <small>
                              {suggestion?.servings[0]?.number_of_units}{" "}
                              {suggestion?.servings[0]?.measurement_description}
                            </small>
                          </span>
                          <span>{suggestion?.servings[0]?.calories} Cals</span>
                        </div> */}
                            <div className="search-dropdown">
                              <span className="food-name">
                                {suggestion?.name} <br />{" "}
                                <small>
                                  {suggestion?.cc_servings[0]?.number_of_units}{" "}
                                  {
                                    suggestion?.cc_servings[0]
                                      ?.measurement_description
                                  }
                                </small>
                              </span>
                              <span>
                                {suggestion?.cc_servings[0]?.calories} Cals
                              </span>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Box sx={{ flexGrow: 1 }} className="m-0">
                    <Grid container spacing={2} className="micro-nutrient-card">
                      {nutrients.map((nutrient) => (
                        <MicroNutrient
                          key={nutrient.label}
                          label={nutrient.label}
                          value={allMicroNutrient?.[nutrient.property]}
                          icon={nutrient.icon}
                          color={nutrient.color}
                        />
                      ))}
                    </Grid>
                  </Box>

                  <div className="looged-foods-nutrients">
                    <Accordion
                    // defaultActiveKey={0}
                    // onSelect={handleAccordionClick}
                    >
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header>
                          View All Micronutrients Value
                        </Accordion.Header>
                        <Accordion.Body>
                          <section className="d-flex justify-content-between align-items-center px-2">
                            <div style={{ fontSize: "14px" }}>
                              Amount per Serving
                              <br />
                              <span style={{ fontSize: "22px" }}>Cals</span>
                            </div>
                            <div style={{ fontSize: "30px" }}>
                              {allMicroNutrient?.calories || "-"}
                            </div>
                          </section>
                          <section className="d-flex justify-content-between align-items-center px-2">
                            <span></span>
                            <span style={{ fontSize: "10px" }}>% RDI*</span>
                          </section>
                          <NutrientComponent servingData={allMicroNutrient} />
                          <div
                            className="text-end border-none"
                            style={{ fontSize: "10px" }}
                          >
                            * RDI value based on 2000 Cal diet for adult male
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="careteam-looged-foods">
                    <Row>
                      {intakeFoodByTool.map((meal, index) => (
                        <Col md={6} key={index} className="mt-1">
                          <Paper
                            component="form"
                            className={`mb-3 p-2 w-100 food-item ${
                              meal?.to_be_deleted ? "deleted-meal" : ""
                            } `}
                          >
                            {meal?.to_be_deleted ? (
                              <Fab
                                size="small"
                                color="success"
                                aria-label="add"
                                className="remove-food"
                                onClick={() => {
                                  handleAddRemovedFood(index);
                                }}
                              >
                                <FontAwesomeIcon icon={faAdd} />
                              </Fab>
                            ) : (
                              <Fab
                                size="small"
                                color="error"
                                aria-label="add"
                                className="remove-food"
                                onClick={() => {
                                  handleRemoveFood(index);
                                }}
                              >
                                <FontAwesomeIcon icon={faClose} />
                              </Fab>
                            )}
                            <Row>
                              <Col md={6}>
                                <h4>{meal?.name}</h4>
                                <p>
                                  {parseFloat(
                                    meal?.unit_count *
                                      meal?.selected_serving
                                        ?.metric_serving_amount
                                  ).toFixed(1)}{" "}
                                  {meal?.selected_serving?.metric_serving_unit}{" "}
                                  -{" "}
                                  {parseFloat(
                                    meal?.unit_count *
                                      meal?.selected_serving?.calories
                                  ).toFixed(0)}{" "}
                                  Cals
                                </p>
                              </Col>
                              <Col md={6} className="d-flex serving-section">
                                <div className="w-50 text-center">
                                  <p>Unit</p>
                                  <Form.Select
                                    size="sm"
                                    className=""
                                    disabled={meal?.to_be_deleted}
                                    onChange={(e) => {
                                      handleServingUnitChange(
                                        e.target.value,
                                        meal?.id
                                      );
                                    }}
                                    value={
                                      meal?.unit_count *
                                      meal?.selected_serving?.[
                                        "number_of_units"
                                      ]
                                    }
                                  >
                                    {foodServingUnitArray?.map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {parseFloat(option)}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                </div>
                                <div className="w-50 text-center">
                                  <p>Serving</p>
                                  <Form.Select
                                    size="sm"
                                    disabled={meal?.to_be_deleted}
                                    onClick={(e) => {
                                      if (!meal?.is_edited) {
                                        handleGetNutrients(meal?.food_id);
                                      }
                                    }}
                                    onChange={(e) => {
                                      handleServingOptionChange(
                                        e.target.value,
                                        meal?.id
                                      );
                                    }}
                                    value={meal?.selected_serving?.["id"]}
                                  >
                                    {meal?.cc_servings?.map((option, index) => (
                                      <option key={index} value={option?.id}>
                                        {option?.measurement_description}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </Col>
                            </Row>
                          </Paper>
                        </Col>
                      ))}
                    </Row>
                  </div>

                  <div className="user-looged-foods">
                    <Accordion
                      defaultActiveKey={intakeFoodByApp?.length ? "0" : ""}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          User Logged Foods{" "}
                          {!intakeFoodByApp?.length ? " - No Foods Logged" : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            {intakeFoodByApp.map((meal, index) => (
                              <Col md={6} key={index} className="mt-1">
                                <Paper
                                  component="form"
                                  className={`mb-3 p-2 w-100 food-item non-editable`}
                                >
                                  <Row>
                                    <Col md={6}>
                                      <h4>{meal?.name}</h4>
                                      <p>
                                        {parseFloat(
                                          meal?.unit_count *
                                            meal?.selected_serving
                                              ?.metric_serving_amount
                                        ).toFixed(1)}{" "}
                                        {
                                          meal?.selected_serving
                                            ?.metric_serving_unit
                                        }{" "}
                                        -{" "}
                                        {parseFloat(
                                          meal?.unit_count *
                                            meal?.selected_serving?.calories
                                        ).toFixed(0)}{" "}
                                        Cals
                                      </p>
                                    </Col>
                                    <Col
                                      md={6}
                                      className="d-flex serving-section"
                                    >
                                      <div className="w-50 text-center">
                                        <p>Unit</p>
                                        {meal?.unit_count *
                                          meal?.selected_serving?.[
                                            "number_of_units"
                                          ]}
                                      </div>
                                      <div className="w-50 text-center">
                                        <p>Serving</p>
                                        {
                                          meal?.selected_serving?.[
                                            "measurement_description"
                                          ]
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                </Paper>
                              </Col>
                            ))}
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  <div className="intake-footer">
                    <div className="inner-div hide">
                      <FormGroup className="verify-switch">
                        <FormControlLabel
                          control={
                            <VerifySwitch
                              sx={{ m: 1 }}
                              defaultChecked
                              checked={intakeFoodIsVerified}
                              onChange={handleVerificationChange}
                            />
                          }
                          labelPlacement="end"
                          label={
                            intakeFoodIsVerified ? "Verified" : "Processing"
                          }
                        />
                      </FormGroup>
                      <Fab
                        color="success"
                        aria-label="add"
                        onClick={handleUpdateFoodIntake}
                        className="save-btn"
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </Fab>
                    </div>

                    <div className="cgm-present">
                      <FormGroup style={{ fontWeight: "bold" }}>
                        <FormControlLabel
                          checked={
                            selectedFoodIntake?.is_cgm_data_present
                              ? false
                              : true
                          }
                          control={<Checkbox color="secondary" />}
                          readOnly
                          label="Is CGM Data Present (Read-only)"
                        />
                      </FormGroup>
                    </div>
                    <Divider orientation="horizontal" flexItem />

                    <div className="mb-2">
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <FormGroup>
                            <FormControlLabel
                              checked={isOutsideFood}
                              control={<Checkbox />}
                              disabled={
                                selectedFoodIntake?.n_image !== 0 ? false : true
                              }
                              label="Outside Food"
                              onChange={(e) => {
                                setIsOutsideFood(e.target.checked);
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              checked={isSuggestedFood}
                              control={<Checkbox />}
                              disabled={
                                selectedFoodIntake?.n_image !== 0 ? false : true
                              }
                              onChange={(e) => {
                                setIsSuggestedFood(e.target.checked);
                              }}
                              label="Suggested foods"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                          <FormGroup>
                            <FormControlLabel
                              checked={isCannotIdentify}
                              control={<Checkbox />}
                              disabled={
                                selectedFoodIntake?.n_image !== 0 ? false : true
                              }
                              label="Cannot identify"
                              onChange={(e) => {
                                setIsCannotIdentify(e.target.checked);
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              checked={isPartialCaloricValUpdated}
                              control={<Checkbox />}
                              disabled={
                                selectedFoodIntake?.n_image !== 0 ? false : true
                              }
                              label="Partial caloric value updated"
                              onChange={(e) => {
                                setIsPartialCaloricValUpdated(e.target.checked);
                              }}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </div>

                    <div className="button-section">
                      <Tooltip title={"Close"}>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="my-2"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <FontAwesomeIcon icon={faClose} /> Cancel
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          intakeFoodIsVerified
                            ? "Change to Draft"
                            : "Save as Draft"
                        }
                      >
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="my-2"
                          onClick={() => {
                            setIntakeFoodIsVerified(false);
                            let status = false;
                            handleUpdateFoodIntake(status);
                          }}
                        >
                          <FontAwesomeIcon icon={faFile} />{" "}
                          {intakeFoodIsVerified
                            ? "Change to Draft"
                            : "Save as Draft"}
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={
                          intakeFoodIsVerified
                            ? "Already Verified"
                            : "Log Food (Verified)"
                        }
                      >
                        <Button
                          variant="outline-success"
                          size="sm"
                          disabled={intakeFoodIsVerified}
                          className="my-2"
                          onClick={() => {
                            setIntakeFoodIsVerified(true);
                            let status = true;
                            handleUpdateFoodIntake(status);
                          }}
                        >
                          <FontAwesomeIcon icon={faCircleCheck} /> Log Food
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="cgm_graph" title="CGM Graph">
              <CGMChart cgmGraphData={cgmGraphData} />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default FoodIntakeReview;
