import React from 'react'
import Chart from 'react-apexcharts';
import MealIcon from "../../../assets/img/icon/meal-graph.png";

const CGMChart = ({cgmGraphData}) => {

  const values = cgmGraphData.map(data => parseFloat(data.value).toFixed(2));
  const recordedAt = cgmGraphData.map(data => new Date(data.recorded_at).getTime());

  const options = {
    chart: {
      id: "basic-line",
    },
    tooltip: {
      x: {
        formatter: (val) => {
          return new Date(val).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
        },
      },
      y: {
        formatter: (val) => {
          // Custom y-axis tooltip formatter function
          return `${parseFloat(val).toFixed(2)}`;
        },
      },
    },

    stroke: {
      width: 5,
      curve: "smooth",
    },

    xaxis: {
      type: "datetime",
      tickAmount: 5,
      labels: {
        datetimeUTC:false,
        datetimeFormatter: {
          hour: "hh:mm TT",
        },
      },
      categories: recordedAt, //['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    annotations: {
      points: [
        {
          x: recordedAt[0],
          y: values[0],
          marker: {
            size: 0,
          },
          image: {
            path: MealIcon,
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    title: {
      text: "CGM Graph",
      align: "left",
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#FDD835"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    toolbar: {
      show: false,
    },
  
  };

  const series = [{
    name: 'CGM',
    data: values, //[30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 115, 120]
  }];

  return (
    <div>
      <Chart options={options} series={series} type="line" width={"100%"} height={400}/>
    </div>
  );
}

export default CGMChart