import {
  faClose,
  faEdit,
  faEnvelope,
  faExpand,
  faFile,
  faFileAlt,
  faMoon,
  faPlusSquare,
  faRetweet,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Card, ToggleButton } from "react-bootstrap";
import EmailTemplateFullView from "./EmailTemplateFullView";
import "./HtmlEditor.scss";
import SendManualEmail from "./SendManualEmail";
import SaveEmailTemplate from "./SaveEmailTemplate";
// import ejs from 'ejs';
// import fs from 'fs';


const EmailViewer = () => {
  const [codeInputs, setCodeInputs] = useState("");
  const [codeInput, setCodeInput] = useState("");
  const [isExpandView, setIsExpandView] = useState(false);

  const [isEmailSendView, setIsEmailSendView] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaveViewMode, setIsSaveViewMode] = useState(false);

  useEffect(() => {
    const savedCode = localStorage.getItem("savedCode");
    if (savedCode) {
      setCodeInputs(savedCode);
    }
  }, []);


  /* EJS */
  const [emailHtml, setEmailHtml] = useState('');

  useEffect(() => {
    // const template = fs.readFileSync('path/to/emailTemplate.ejs', 'utf8');
    // const data = { name: 'John Doe' };
    // const html = ejs.render(template, data);
    // setEmailHtml(html);
  }, []);
  /* End EJS */

  const updatePreview = (newCode) => {
    setCodeInput(newCode);
  };

  const resetCode = () => {
    setCodeInput("");
  };

  const saveCode = () => {
    if (codeInput) {
      localStorage.setItem("savedCode", codeInput);
      setCodeInput("");
    }
  };

  const loadCode = () => {
    const savedCode = localStorage.getItem("savedCode");
    if (savedCode) {
      setCodeInput(savedCode);
    }
  };

  const EditCodeInput = (editCode) => {
    if (editCode) {
      setCodeInput(editCode);
    }
  };

  const getIframeContent = () => {
    let convertTheme = codeInput;
    if (theme === "dark") {
      convertTheme = codeInput.replace(
        /@media \(prefers-color-scheme:dark\)/g,
        "@media (prefers-color-scheme:light)"
      );
    } else {
      convertTheme = codeInput.replace(
        /@media \(prefers-color-scheme:light\)/g,
        "@media (prefers-color-scheme:dark)"
      );
    }

    return `
      ${convertTheme}
    `;
  };

  const [theme, setTheme] = useState("light"); // State to manage theme
  const [emailTemplatesList, setEmailTemplatesList] = useState([]);

  const items = Array.from({ length: 6 }, (_, index) => index + 1);


  return (
    <div id="container">
      <Grid container>
        <Grid item md={4}>
          {!isEditMode && (
            <Autocomplete
              options={emailTemplatesList}
              getOptionLabel={(option) => `${option?.template_name}`}
              // value={selectedEmailTemplate}
              // onChange={hanldleEmailTemplateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Email Template"
                  variant="outlined"
                  size="small"
                />
              )}
              className="email-template-search"
            />
          )}
        </Grid>
        <Grid item md={8} className="d-flex justify-content-end ">
          {isEditMode && (
            <div className="my-auto">
              <ButtonGroup className="mx-2" size={"sm"}>
                <ToggleButton
                  id="toggle-check-light"
                  type="radio"
                  variant="primary"
                  name="theme"
                  value="light"
                  checked={theme === "light"}
                  onChange={(e) => setTheme(e.currentTarget.value)}
                >
                  <FontAwesomeIcon icon={faSun} className="mx-1" /> Light
                </ToggleButton>
                <ToggleButton
                  id="toggle-check-dark"
                  type="radio"
                  variant="primary"
                  name="theme"
                  value="dark"
                  checked={theme === "dark"}
                  onChange={(e) => setTheme(e.currentTarget.value)}
                >
                  <FontAwesomeIcon icon={faMoon} className="mx-1" /> Dark
                </ToggleButton>
              </ButtonGroup>

              <Button
                variant="danger"
                className="me-2"
                onClick={resetCode}
                size="sm"
              >
                <FontAwesomeIcon icon={faRetweet} className="mx-1" /> Reset
              </Button>

              <Button variant="secondary" onClick={loadCode} size="sm">
                <FontAwesomeIcon icon={faFileAlt} className="mx-1" /> Load Draft
              </Button>

              <Button
                variant="success"
                className="mx-2"
                onClick={saveCode}
                disabled={!codeInput}
                size="sm"
              >
                <FontAwesomeIcon icon={faFileAlt} className="mx-1" /> Save Draft
              </Button>

              <Button
                variant="danger"
                className="me-1"
                disabled={!codeInput}
                onClick={() => {
                  setIsSaveViewMode(true);
                }}
                size="sm"
              >
                <FontAwesomeIcon icon={faFile} className="mx-1" /> Save
              </Button>

              <Button
                variant="warning"
                className="me-2"
                onClick={() => {
                  setIsExpandView(true);
                }}
                size="sm"
              >
                <FontAwesomeIcon icon={faExpand} className="mx-1" />
              </Button>
              <Button
                variant="danger"
                className=""
                onClick={() => {
                  setIsEditMode(false);
                  setCodeInput("");
                }}
                size="sm"
              >
                <FontAwesomeIcon icon={faClose} className="mx-1" />
              </Button>
            </div>
          )}
          {!isEditMode && (
            <div className="my-auto">
              <Button
                variant="primary"
                className="me-1"
                size="sm"
                onClick={() => {
                  setIsEditMode(true);
                }}
              >
                <FontAwesomeIcon icon={faPlusSquare} className="mx-1" /> New
                Template
              </Button>
              <Button
                variant="secondary"
                className=""
                size="sm"
                onClick={() => {
                  setIsEmailSendView(true);
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} className="mx-1" /> Send
                E-Mail
              </Button>
            </div>
          )}
        </Grid>
      </Grid>

      {isEditMode && (
        <section className="email-viewer-section">
          <Grid container spacing={0}>
            <Grid item md={6} id="editor">
              <div>
                {/* <div
                  className="html-editor-input"
                  contentEditable
                  onInput={(e) => updatePreview(e.target.innerHTML)}
                  dangerouslySetInnerHTML={{ __html: codeInput }}
                ></div> */}
                <textarea
                  className="html-editor-input"
                  placeholder="Enter your HTML/CSS code here"
                  value={codeInput}
                  onChange={(e) => updatePreview(e.target.value)}
                ></textarea>
              </div>
            </Grid>
            <Grid item md={6} id="preview">
              <div className={`html-preview`}>
                <iframe
                  className={`preview-frame`} //
                  title="Preview"
                  srcDoc={getIframeContent()}
                ></iframe>
              </div>
            </Grid>
          </Grid>
        </section>
      )}

      {!isEditMode && (
        <Grid container spacing={2}>
          {items.map((item) => (
            <Grid item md={4} sm={4} key={item}>
              <Card className="email-templates-list-home">
                <Card.Header>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0">Iframe - {item}</p>{" "}
                    <Button
                      variant="danger"
                      className=""
                      size="sm"
                      onClick={() => {
                        setIsEditMode(true);
                        EditCodeInput(codeInputs);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} className="mx-1" />
                      Edit
                    </Button>
                  </div>
                </Card.Header>
                <div className="thumbnail">
                  <iframe srcDoc={codeInputs}></iframe>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <EmailTemplateFullView
        show={isExpandView}
        setShow={setIsExpandView}
        codeInput={getIframeContent()}
        theme={theme}
        setTheme={setTheme}
      />

      <SendManualEmail show={isEmailSendView} setShow={setIsEmailSendView} />
      <SaveEmailTemplate
        show={isSaveViewMode}
        setShow={setIsSaveViewMode}
        codeInput={getIframeContent()}
      />
    </div>
  );
};

export default EmailViewer;
