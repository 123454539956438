import React, { useContext, useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import "./CustomizeTheme.scss";
import { InputAdornment, TextField } from "@mui/material";
import MainContext from "../../context/MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { changePassword } from "../../utils/LoginAPI";
import Swal from "sweetalert2";

const ChangePassword = ({ show, setShow }) => {
  const { ShowNotification, setIsPreLoading } = useContext(MainContext);

  const handleClose = () => {
    setShow(false);
  };
  const handleChangePassword = async () => {
    let requestData = {
      old_password: oldPassword,
      password: newPassword,
    };
    setIsPreLoading(true);
    try {
      const changePasswordResponse = await changePassword(requestData);
      console.log(changePasswordResponse);
      ShowNotification("success", changePasswordResponse?.data?.message);
      handleClose();
      window.location.reload()
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [retypeNewPasswordShow, setRetypeNewPasswordShow] = useState(false);
  const [isErrorShow, setIsErrorShow] = useState(false);

  return (
    <div>
      <Offcanvas
        className="change-password-section"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Change Password</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <TextField
              className="my-2"
              label="Old Password"
              name="old-password"
              size={"small"}
              fullWidth
              value={oldPassword}
              type={oldPasswordShow ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={oldPasswordShow ? faEyeSlash : faEye}
                      onClick={() => {
                        setOldPasswordShow(!oldPasswordShow);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={(e) => {
                const inputValue = e.target.value;
                const sanitizedValue = inputValue.replace(/\s/g, "");
                if (sanitizedValue.length <= 16) {
                  setOldPassword(e.target?.value);
                }
              }}
            />
            <TextField
              className="my-2"
              label="New Password"
              name="password"
              size={"small"}
              fullWidth
              type={newPasswordShow ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={newPasswordShow ? faEyeSlash : faEye}
                      onClick={() => {
                        setNewPasswordShow(!newPasswordShow);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={newPassword}
              variant="outlined"
              onChange={(e) => {
                const inputValue = e.target.value.trim();
                const sanitizedValue = inputValue.replace(/\s/g, "");
                const isMatch = retypeNewPassword === inputValue;
                const isError = retypeNewPassword.length > 0 && !isMatch;

                setNewPassword(sanitizedValue.substring(0, 16));

                setIsErrorShow(isError);
              }}
            />
            <TextField
              className="my-2"
              label="Re-Type New Password"
              name="re-password"
              size={"small"}
              fullWidth
              type={retypeNewPasswordShow ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={retypeNewPasswordShow ? faEyeSlash : faEye}
                      onClick={() => {
                        setRetypeNewPasswordShow(!retypeNewPasswordShow);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              value={retypeNewPassword}
              error={isErrorShow}
              helperText={isErrorShow ? "Passwords are not Same" : ""}
              onChange={(e) => {
                const inputValue = e.target.value.trim();
                const sanitizedValue = inputValue.replace(/\s/g, "");
                const isMatch = newPassword === inputValue;
                const isError = inputValue.length > 0 && !isMatch;

                setRetypeNewPassword(sanitizedValue.substring(0, 16));

                setIsErrorShow(isError);
              }}
            />
            <div className="text-end mt-2">
              <Button
                disabled={
                  oldPassword.length === 0 || newPassword !== retypeNewPassword
                }
                onClick={() => {
                  Swal.fire({
                    title: "Change Password",
                    text: "Are you sure you want to change the password?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleChangePassword();
                    }
                  });
                }}
              >
                Change Password
              </Button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ChangePassword;
