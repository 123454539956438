import {
  EdgeLabelRenderer,
  BaseEdge,
  getStraightPath
} from "reactflow";

const CustomEdgeLabel = ({ id, label, data, ...props }) => {

  const [edgePath, labelX, labelY] = getStraightPath(props);
  
  return (
    <>
      <BaseEdge id={id} path={edgePath}/>
      <EdgeLabelRenderer>
        {label && (
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              background: "#ffefdd",
              color: "#ec8000",
              padding: 10,
              borderRadius: 5,
              fontSize: 10,
              fontWeight: 500,
              wordWrap: "break-word",
              maxWidth: "200px",
            }}
            className="nodrag nopan"
          >
            {label}
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdgeLabel;