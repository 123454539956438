export const foodsToAvoidData = [
  {
    "Milk & Milk Products": [
      { name: "Paneer", count: 3, advice: "It would be best to avoid it." },
      { name: "Buttermilk", count: 0, advice: "" },
      { name: "Kefir", count: 0, advice: "" },
      {
        name: "Milk",
        count: 11,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Legumes: [
      {
        name: "Kidney Beans",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Pigeon Pea", count: 0, advice: "" },
    ],
    Sugars: [
      {
        name: "Table Sugar",
        count: 3,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fructose Corn Syrup", count: 0, advice: "" },
      { name: "Glucose Syrup", count: 0, advice: "" },
      { name: "Brown Sugar", count: 0, advice: "" },
    ],
    Beverages: [
      { name: "Kombucha", count: 0, advice: "" },
      { name: "Energy Drinks", count: 0, advice: "" },
      { name: "Packaged Fruit Drinks", count: 0, advice: "" },
      { name: "Carbonated Beverages", count: 0, advice: "" },
      { name: "Sugarcane Juice", count: 0, advice: "" },
    ],
    Poultry: [
      { name: "Chicken", count: 4, advice: "You're doing great! Keep it up." },
      { name: "Goose", count: 0, advice: "" },
      { name: "Guinea", count: 0, advice: "" },
      { name: "Turkey", count: 0, advice: "" },
    ],
    "Cereals & Millets": [
      {
        name: "Wheat",
        count: 16,
        advice: "This is above the limit, so please consider decreasing it.",
      },
      { name: "Crude Wheat Bran", count: 0, advice: "" },
      { name: "Wheat Germ Crude", count: 0, advice: "" },
      { name: "Whole Wheat Bread", count: 0, advice: "" },
      { name: "Barley", count: 0, advice: "" },
    ],
    "Sweets & Desserts": [
      {
        name: "Processed And Packaged Foods",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Garlic Bread", count: 0, advice: "" },
      { name: "Candies", count: 0, advice: "" },
    ],
    "Edible Oil & Fat": [
      {
        name: "Fried Fast Food",
        count: 1,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fat Dressings", count: 0, advice: "" },
      { name: "Butter", count: 0, advice: "" },
      { name: "Margarine", count: 0, advice: "" },
      { name: "Milk Cream", count: 0, advice: "" },
      { name: "Hydrogenated Oils", count: 0, advice: "" },
      { name: "Coconut Oil", count: 0, advice: "" },
      { name: "Palm Oil", count: 0, advice: "" },
      { name: "Vegetable Shortening", count: 0, advice: "" },
      { name: "Full Fat Dairy Products", count: 0, advice: "" },
    ],
    Beverages: [
      {
        name: "alchohol",
        count: 3,
        advice:
          "You've had this 3 times in 2 weeks. It would be best to avoid it.",
      },
    ],
  },
  {
    "Milk & Milk Products": [
      { name: "Paneer", count: 3, advice: "It would be best to avoid it." },
      { name: "Buttermilk", count: 0, advice: "" },
      { name: "Kefir", count: 0, advice: "" },
      {
        name: "Milk",
        count: 11,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Legumes: [
      {
        name: "Kidney Beans",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Pigeon Pea", count: 0, advice: "" },
    ],
    Sugars: [
      {
        name: "Table Sugar",
        count: 3,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fructose Corn Syrup", count: 0, advice: "" },
      { name: "Glucose Syrup", count: 0, advice: "" },
      { name: "Brown Sugar", count: 0, advice: "" },
    ],
    Beverages: [
      { name: "Kombucha", count: 0, advice: "" },
      { name: "Energy Drinks", count: 0, advice: "" },
      { name: "Packaged Fruit Drinks", count: 0, advice: "" },
      { name: "Carbonated Beverages", count: 0, advice: "" },
      { name: "Sugarcane Juice", count: 0, advice: "" },
    ],
    Poultry: [
      { name: "Chicken", count: 4, advice: "You're doing great! Keep it up." },
      { name: "Goose", count: 0, advice: "" },
      { name: "Guinea", count: 0, advice: "" },
      { name: "Turkey", count: 0, advice: "" },
    ],
    "Cereals & Millets": [
      {
        name: "Wheat",
        count: 16,
        advice: "This is above the limit, so please consider decreasing it.",
      },
      { name: "Crude Wheat Bran", count: 0, advice: "" },
      { name: "Wheat Germ Crude", count: 0, advice: "" },
      { name: "Whole Wheat Bread", count: 0, advice: "" },
      { name: "Barley", count: 0, advice: "" },
    ],
    "Sweets & Desserts": [
      {
        name: "Processed And Packaged Foods",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Garlic Bread", count: 0, advice: "" },
      { name: "Candies", count: 0, advice: "" },
    ],
    "Edible Oil & Fat": [
      {
        name: "Fried Fast Food",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fat Dressings", count: 0, advice: "" },
      { name: "Butter", count: 0, advice: "" },
      { name: "Margarine", count: 0, advice: "" },
      { name: "Milk Cream", count: 0, advice: "" },
      { name: "Hydrogenated Oils", count: 0, advice: "" },
      { name: "Coconut Oil", count: 0, advice: "" },
      { name: "Palm Oil", count: 0, advice: "" },
      { name: "Vegetable Shortening", count: 0, advice: "" },
      { name: "Full Fat Dairy Products", count: 0, advice: "" },
    ],
    Beverages: [
      {
        name: "alchohol",
        count: 3,
        advice:
          "You've had this 3 times in 2 weeks. It would be best to avoid it.",
      },
    ],
  },
  {
    "Milk & Milk Products": [
      { name: "Paneer", count: 3, advice: "It would be best to avoid it." },
      { name: "Buttermilk", count: 0, advice: "" },
      { name: "Kefir", count: 0, advice: "" },
      {
        name: "Milk",
        count: 11,
        advice: "This is above the limit, so please consider decreasing it.",
      },
    ],
    Legumes: [
      {
        name: "Kidney Beans",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Pigeon Pea", count: 0, advice: "" },
    ],
    Sugars: [
      {
        name: "Table Sugar",
        count: 3,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fructose Corn Syrup", count: 0, advice: "" },
      { name: "Glucose Syrup", count: 0, advice: "" },
      { name: "Brown Sugar", count: 0, advice: "" },
    ],
    Beverages: [
      { name: "Kombucha", count: 0, advice: "" },
      { name: "Energy Drinks", count: 0, advice: "" },
      { name: "Packaged Fruit Drinks", count: 0, advice: "" },
      { name: "Carbonated Beverages", count: 0, advice: "" },
      { name: "Sugarcane Juice", count: 0, advice: "" },
    ],
    Poultry: [
      { name: "Chicken", count: 4, advice: "You're doing great! Keep it up." },
      { name: "Goose", count: 0, advice: "" },
      { name: "Guinea", count: 0, advice: "" },
      { name: "Turkey", count: 0, advice: "" },
    ],
    "Cereals & Millets": [
      {
        name: "Wheat",
        count: 16,
        advice: "This is above the limit, so please consider decreasing it.",
      },
      { name: "Crude Wheat Bran", count: 0, advice: "" },
      { name: "Wheat Germ Crude", count: 0, advice: "" },
      { name: "Whole Wheat Bread", count: 0, advice: "" },
      { name: "Barley", count: 0, advice: "" },
    ],
    "Sweets & Desserts": [
      {
        name: "Processed And Packaged Foods",
        count: 2,
        advice: "It would be best to avoid it.",
      },
      { name: "Garlic Bread", count: 0, advice: "" },
      { name: "Candies", count: 0, advice: "" },
    ],
    "Edible Oil & Fat": [
      {
        name: "Fried Fast Food",
        count: 3,
        advice: "It would be best to avoid it.",
      },
      { name: "High Fat Dressings", count: 0, advice: "" },
      { name: "Butter", count: 0, advice: "" },
      { name: "Margarine", count: 0, advice: "" },
      { name: "Milk Cream", count: 0, advice: "" },
      { name: "Hydrogenated Oils", count: 0, advice: "" },
      { name: "Coconut Oil", count: 0, advice: "" },
      { name: "Palm Oil", count: 0, advice: "" },
      { name: "Vegetable Shortening", count: 0, advice: "" },
      { name: "Full Fat Dairy Products", count: 0, advice: "" },
    ],
    Beverages: [
      {
        name: "alchohol",
        count: 3,
        advice:
          "You've had this 3 times in 2 weeks. It would be best to avoid it.",
      },
    ],
  },
];

export const foodsToAvoidAdivce = {
  Beverages: {
    warning:
      "Your genes indicate a high risk for alcohol addiction. Additionally, alcohol seems to be impacting your sleep.",
    standard_drink_info:
      "A standard drink in India typically contains about 1 unit (10 grams) of pure alcohol.",
    facts: [
      { name: "Gin", description: "30 ml of gin = 1 unit of pure alcohol" },
      {
        name: "Beer",
        description: "330 ml of beer (~5% alcohol) = 1 unit of pure alcohol",
      },
      {
        name: "Vodka",
        description: "30 ml of vodka = 1 unit of pure alcohol",
      },
    ],
    health_advice:
      "World Health Organisation says drinking alcohol is associated with risks of developing non-communicable diseases such as liver diseases, heart diseases, and different types of cancers, as well as mental health and behavioural conditions such as depression, anxiety and alcohol use disorders. It is always advisable to drink responsibly and be aware of how alcohol affects your body and overall health.",
  },
};
