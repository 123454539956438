import { createContext, useState } from "react";
// import MainContext from "./MainContext";

const NotificationContext = createContext({});

export const NotificationDataProvider = ({ children }) => {

  // const { setIsPreLoading } = useContext(MainContext);

  const [unreadCount, setUnreadCount] = useState(5)

  const [initRenderManualList, setInitRenderManualList] = useState(true);  
  const [manualNotficationList, setManualNotficationList] = useState([]);
  const [manualNotficationListCount, setManualNotficationListCount] = useState("");

  const [manualNotficationParam, setManualNotficationListParam] = useState({
    limit: 50,
    offset: 0,
  });

  const [initRenderManualNotificationList, setInitRenderManualNotificationList] = useState(true);  
  const [recentSendNotficationList, setRecentSendNotificationList] = useState([]);
  const [recentSendNotficationUsersList, setRecentSendNotficationUsersList] = useState([]);

  const [sendNotficationByUser, setSendNotficationByUser] = useState([]);
  const [selectedNotificationUser, setSelectedNotificationUser] = useState("");
  const [sendNotificationListCount, setSendNotificationListCount] = useState(0);

  const [manualNotficationRecentParam, setManualNotficationRecentParam] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const [manualNotficationUserParam, setManualNotficationUserParam] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const [manualNotficationUserListParam, setManualNotficationUserListParam] = useState({
    limit: 100,
    offset: 0,
  });


  return (
    <NotificationContext.Provider
      value={{
        unreadCount, setUnreadCount,
        initRenderManualList, setInitRenderManualList,
        manualNotficationList, setManualNotficationList,
        manualNotficationListCount, setManualNotficationListCount,
        manualNotficationParam, setManualNotficationListParam,
        initRenderManualNotificationList, setInitRenderManualNotificationList,
        recentSendNotficationList, setRecentSendNotificationList,
        recentSendNotficationUsersList, setRecentSendNotficationUsersList,
        sendNotficationByUser, setSendNotficationByUser,
        selectedNotificationUser, setSelectedNotificationUser,
        manualNotficationRecentParam, setManualNotficationRecentParam,
        sendNotificationListCount, setSendNotificationListCount,
        manualNotficationUserParam, setManualNotficationUserParam,
        manualNotficationUserListParam, setManualNotficationUserListParam
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
