import React, { useContext, useEffect, useState } from "react";
import { Card, Offcanvas, Button } from "react-bootstrap";
import { TextField, Grid, Autocomplete } from "@mui/material";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainContext from "../../context/MainContext";
import {
  getManualNotificationList,
  sendManualNotifications,
} from "../../utils/NotificationAPI";
import Swal from 'sweetalert2';

const SendManualEmail = ({ show, setShow }) => {
  const handleClose = () => {
    setShow(false);
    setSelectedNotification(null);
  };
  const { setIsPreLoading, ShowNotification } = useContext(MainContext);

  useEffect(() => {
    if (show) {
      getEmailTemplateListData();
    }
  }, [show]);

  const [userID, setUserID] = useState("");
  const [manualNotificationsList, setManualNotficationList] = useState([]);

  const handleSendEmail = async () => {
  };

  const getEmailTemplateListData = async () => {
    // setIsPreLoading(true);
  };

  const [selectedNotification, setSelectedNotification] = useState(null);

  const hanldleSubtypeChange = (event, newValue) => {
    setSelectedNotification(newValue);
  };

  return (
    <div>
      <Offcanvas
        className="send-email-template"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Send E-mail</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <TextField
            className="my-2"
            label="User UUID"
            // type="number"
            name="user_uuid"
            size={"small"}
            placeholder="Enter user UUID"
            fullWidth
            variant="outlined"
            value={userID}
            onChange={(e) => {
              const inputValue = e.target.value;    
              const sanitizedValue = inputValue.replace(/\s/g, '');
              if (sanitizedValue?.length <= 100) {
                setUserID(sanitizedValue);
              }
            }}
          />

          <Autocomplete
            options={manualNotificationsList}
            getOptionLabel={(option) =>
              `${option?.sub_type} - [Title: ${option?.title}]`
            }
            value={selectedNotification}
            disabled={!userID}
            onChange={hanldleSubtypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Email Template"
                variant="outlined"
                size="small"
              />
            )}
            className="email-template-search my-2"
          />

          <div className="text-end">
            <Button
              variant="outline-success"
              color="secondary"
              className="my-2"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {
                if (userID && selectedNotification) {
                  Swal.fire({
                    title: "Are you sure you want to send the notification?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Send",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleSendEmail();
                    }
                  })
                  }else{
                    ShowNotification("warning", "Please Check all Values")
                  }
                             
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} className="mx-1" />{" "}
              <span>Send Notification</span>
            </Button>
          </div>

         
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SendManualEmail;
