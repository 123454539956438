import React, { useContext, useEffect, useState } from "react";
import { Card, Offcanvas, Button } from "react-bootstrap";
import { TextField, Grid, Autocomplete, Fab } from "@mui/material";
import { faFileAlt, faPaperPlane, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainContext from "../../context/MainContext";

const SaveEmailTemplate = ({ show, setShow, codeInput }) => {
  const handleClose = () => {
    setShow(false);
  };
  const { setIsPreLoading, ShowNotification } = useContext(MainContext);

  const [inputs, setInputs] = useState({});
  const [inputCount, setInputCount] = useState(0);
  const [isShowErrorField, setIsShowErrorField] = useState(false);

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setInputs(prevInputs => ({
      ...prevInputs,
      [name]: value.toLowerCase(),
    }));
  };

  // Function to add a new textbox
  const addTextbox = () => {
    const newInputName = `field_${inputCount + 1}`;
    setInputs(prevInputs => ({
      ...prevInputs,
      [newInputName]: '',
    }));
    setInputCount(prevCount => prevCount + 1);
  };

  const removeTextbox = (name) => {
    setInputs(prevInputs => {
      const newInputs = { ...prevInputs };
      delete newInputs[name];
      return newInputs;
    });
  };


  const saveValues = () => {
    console.log(inputs);
  };


  return (
    <div>
      <Offcanvas
        className="send-email-template"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Save Email Template</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <TextField
            className="my-2"
            label="Template Name"
            name="user_uuid"
            size={"small"}
            fullWidth
            variant="outlined"
            onChange={(e) => {}}
          />

          <div>
            {Object.keys(inputs).map((name) => (
              <Grid container key={name} className="d-flex">
                <Grid item md={10}>
                  <TextField
                    className="my-2"
                    label="Field Name"
                    name={name}
                    size={"small"}
                    value={inputs[name]}
                    fullWidth
                    variant="outlined"
                    error={(isShowErrorField && !inputs[name]) ? true : false}
                    onChange={(e) => {
                      handleInputChange(e, name);
                    }}
                  />
                </Grid>
                <Grid item md={2} className="d-flex align-items-center justify-content-center">
                  <Fab
                    size="small"
                    color="error"
                    aria-label="add"
                    onClick={() => removeTextbox(name)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Fab>
                </Grid>
              </Grid>
            ))}
          </div>

          <div className="text-end">
            <Button
              variant="outline-primary"
              color="secondary"
              className="mx-2"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {
                setIsShowErrorField(false)
                addTextbox();
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="mx-1" />{" "}
              <span>Add Field</span>
            </Button>
            <Button
              variant="outline-success"
              color="secondary"
              className="my-2"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {
                setIsShowErrorField(true)
                saveValues();
              }}
            >
              <FontAwesomeIcon icon={faFileAlt} className="mx-1" />{" "}
              <span>Save Email Template</span>
            </Button>

            <div className="save-preview-block">
              <div className={`sample-preview`}>
                <iframe title="Preview" srcDoc={codeInput}></iframe>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SaveEmailTemplate;
